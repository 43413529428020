// Firebase App (the core Firebase SDK)
// is always required and must be listed first
import firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import "firebase/analytics"

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ||
    "claimback-development",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS_ID,
}

if (!config.apiKey) throw new Error('Missing value for "apiKey"')
if (!config.authDomain) throw new Error('Missing value for "authDomain"')
if (!config.databaseURL) throw new Error('Missing value for "databaseURL"')
if (!config.projectId) throw new Error('Missing value for "projectId"')
if (!config.storageBucket) throw new Error('Missing value for "storageBucket"')
if (!config.messagingSenderId) {
  throw new Error('Missing value for "messagingSenderId"')
}
if (!config.appId) throw new Error('Missing value for "appId"')
if (!config.measurementId) throw new Error('Missing value for "measurementId"')

firebase.initializeApp(config)

const analytics = firebase.analytics()
const auth = firebase.auth()
const db = firebase.firestore()
db.settings({ ignoreUndefinedProperties: true })
const storage = firebase.storage()
const functions = firebase.functions()

if (window.location.hostname === "localhost") {
  console.info("Use emulators")
  auth.useEmulator("http://localhost:9099")
  db.useEmulator("localhost", 8045)
  storage.useEmulator("localhost", 9199)
  functions.useEmulator("localhost", 5001)
}

export default firebase
export { db, storage, functions, analytics, auth }
