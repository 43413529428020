import { animated, useSpring } from "@react-spring/web"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { COLOURS } from "../../../lib/src/constants"
import Container from "../../../components/Container"
import { H1, Subline } from "../../../lib/src/components/Typography"
import { Flex } from "../../../lib/src/components"
import media from "../../../utils/media"
import ConfettiIcon from "../../../components/icons/Confetti"

const Heading = styled(H1)`
  text-align: center;
`

const Subtitle = styled(Subline)`
  text-align: center;
  font-weight: bold;
  color: ${COLOURS.NORMAL_BLUE};
`

const ClippedContainer = styled.div `
  ${(media as any).from.xs `
    margin: 0 105px;
  `}
`

let animatedIn = false


export default function CommonHeader () {
  const [headerIn, setHeaderIn] = useState(animatedIn)
  const [subtitleIn, setSubtitleIn] = useState(animatedIn)

  useEffect(() => {
    const headerTimer = setTimeout(() => {
      setHeaderIn(true)
      animatedIn = true
    }, 10)
    const subtitleTimer = setTimeout(() => {
      setSubtitleIn(true)
      animatedIn = true
    }, 750)
    return () => {
      clearTimeout(headerTimer)
      clearTimeout(subtitleTimer)
      animatedIn = true
    }
  }, [])

  const topHeader = useSpring({
    config: { tension: 1000, friction: 50 },
    opacity: headerIn ? 1 : 0,
    transform: headerIn
      ? "translate3d(0%, 0, 0)"
      : "translate3d(0%, 100%, 0)",
  })
  const bottomHeader = useSpring({
    config: { tension: 1000, friction: 50 },
    opacity: subtitleIn ? 1 : 0,
    transform: subtitleIn
      ? "translate3d(0%, 0, 0)"
      : "translate3d(0%, 100%, 0)",
  })
  return (
    <>
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Flex justify='end' fullWidth>
        <ConfettiIcon style={{ position: "absolute" }} />
      </Flex>
      <ClippedContainer>
        <Container>
          <animated.div style={topHeader}>
            <Heading>Sie haben voraussichtlich Anspruch!</Heading>
          </animated.div>
          <animated.div style={bottomHeader}>
            <Subtitle>Jetzt Entschädigungssumme berechnen.</Subtitle>
          </animated.div>
        </Container>
      </ClippedContainer>
    </>
  )
}
