import React, { useContext, useState } from "react"
import { get } from "lodash"
import { useHistory, Redirect } from "react-router-dom"
import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import SelectField from "../../../components/SelectField"
import { ClaimContext, FeatureFlagContext } from "../../../contexts"
import PageContentText from "../PageContentText"
import Trip from "../../../components/icons/Trip"
import routes from "../../../routes"


function getOptions () {
  return [
    { value: 10000, label: "0 km – 10\u202F000 km" },
    { value: 20000, label: "10\u202F000 km – 20\u202F000 km" },
    { value: 30000, label: "20\u202F000 km – 30\u202F000 km" },
    { value: 40000, label: "30\u202F000 km – 40\u202F000 km" },
    { value: 50000, label: "40\u202F000 km – 50\u202F000 km" },
    { value: 60000, label: "50\u202F000 km – 60\u202F000 km" },
    { value: 70000, label: "60\u202F000 km – 70\u202F000 km" },
    { value: 80000, label: "70\u202F000 km – 80\u202F000 km" },
    { value: 90000, label: "80\u202F000 km – 90\u202F000 km" },
    { value: 100000, label: "90\u202F000 km – 100\u202F000 km" },
    { value: 110000, label: "100\u202F000 km – 110\u202F000 km" },
    { value: 120000, label: "110\u202F000 km – 120\u202F000 km" },
    { value: 130000, label: "120\u202F000 km – 130\u202F000 km" },
    { value: 140000, label: "130\u202F000 km – 140\u202F000 km" },
    { value: 150000, label: "140\u202F000 km – 150\u202F000 km" },
    { value: 160000, label: "150\u202F000 km – 160\u202F000 km" },
    { value: 170000, label: "160\u202F000 km – 170\u202F000 km" },
    { value: 180000, label: "170\u202F000 km – 180\u202F000 km" },
    { value: 190000, label: "180\u202F000 km – 190\u202F000 km" },
    { value: 200000, label: "190\u202F000 km – 200\u202F000 km" },
    { value: 210000, label: "200\u202F000 km – 210\u202F000 km" },
    { value: 220000, label: "210\u202F000 km – 220\u202F000 km" },
    { value: 230000, label: "220\u202F000 km – 230\u202F000 km" },
    { value: 240000, label: "230\u202F000 km – 240\u202F000 km" },
    { value: 250000, label: "240\u202F000 km – 250\u202F000 km" },
    { value: 260000, label: "250\u202F000 km – 260\u202F000 km" },
    { value: 270000, label: "260\u202F000 km – 270\u202F000 km" },
    { value: 280000, label: "270\u202F000 km – 280\u202F000 km" },
    { value: 290000, label: "280\u202F000 km – 290\u202F000 km" },
    { value: 300000, label: "290\u202F000 km – 300\u202F000 km" },
  ]

  // return takeWhile(initial, opt => opt.value <= currentMileage)
}


export default function PurchaseMileagePage (
  {
    nextPage,
    openNotEligibleModal,
  }: any,
) {
  const history = useHistory()
  const claim = useContext(ClaimContext) as Claim
  const flags = useContext(FeatureFlagContext)
  const [purchaseMileage, setPurchaseMileage] =
    useState(null)

  if (!get(flags, "show_purchase_mileage_in_funnel.value")) {
    // return <Redirect to={nextPage} />
  }

  return (
    <FlexContainer direction='ttb' center>
      <Trip />

      <PageContentText>
        Wie hoch war der Kilometerstand ungefähr beim Kauf des PKW?
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        <SelectField
          label='Km-Stand bei Kauf'
          value={purchaseMileage}
          onChange={(event: any) => {
            if (event.target.value > 120000) {
              openNotEligibleModal({ message: "secondary" })
              return
            }

            const value = event.target.value
            setPurchaseMileage(value)
            claim.update({ mileageAtPurchase: value })
            history.push(routes.REGISTRATION_HAS_CAR_BEEN_SOLD)
          }}
          options={getOptions()}
        />
      </FormSection>
    </FlexContainer>
  )
}
