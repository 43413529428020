export default async function sendMagicLinkEmail ({
  email,
  userId,
  claimId,
  template = "sign-in",
}: any) {
  if (!email) {
    throw new Error("Missing email or template")
  }

  if (
    window.location.hostname === "localhost" &&
    window.location.port !== "5000"
  ) {
    console.warn(
      "The app must be hosted by the Firebase hosting emulator " +
      "(http://localhost:5000) to allow URL rewrites to cloud functions " +
      "and thereby trigger sending the email.",
    )
    return
  }

  try {
    console.info("Sending link to email", email, `template: ${template}`)

    const response = await fetch("/signInLink", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        baseUrl: window.location.origin,
        email,
        template,
        userId,
        claimId,
      }),
    })

    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    if (response.ok) {
      const resData = await response.json()
      console.info("Email with sign in link was sent", resData)

      window.localStorage.setItem("emailForSignIn", email)
    }
    else {
      console.error(response)
      throw new Error("Email with sign in link could not be sent")
    }
  }
  catch (error) {
    console.error(error)
  }
}
