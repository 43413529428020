const routes = {
  HOME: "/",

  REGISTRATION: "/registration", // entry part for the form

  // new registration routes
  REGISTRATION_BRAND_MODEL_YEAR: "/registration/brand-model-year",
  REGISTRATION_PURCHASE_YEAR: "/registration/purchase-year",
  REGISTRATION_PURCHASE_PRICE: "/registration/purchase-price",
  REGISTRATION_CURRENT_MILEAGE: "/registration/current-mileage",
  REGISTRATION_HAS_CAR_BEEN_SOLD: "/registration/has-car-been-sold",
  REGISTRATION_PURCHASE_MILEAGE: "/registration/purchase-mileage",
  REGISTRATION_INSURANCE_CHECK: "/registration/insurance-check",
  REGISTRATION_CALCULATING_AWARD: "/registration/calculating-award",
  REGISTRATION_KAP_HAS_INSURANCE: "/registration/kap-has-insurance",
  REGISTRATION_KAP_INSURANCE_SELECT: "/registration/kap-insurance-select",
  REGISTRATION_SIGN_UP: "/registration/sign-up",

  APPLICATION: "/application",
  APPLICATION_CAR_INFO: "/application/car-info",
  APPLICATION_REPRESENTATION: "/application/representation",
  APPLICATION_PERSONAL_DATA: "/application/personal-data",
  APPLICATION_REVIEW: "/application/review",
  APPLICATION_SUCCESS: "/application/success",
  APPLITCATION_UPLOAD_KV_PAGE: "/application/kv-upload",

  SIGN_IN: "/sign-in", // this is the route that magic link emails are given
  VERIFY_EMAIL: "/verify-email",
  PROMPT_FOR_EMAIL: "/prompt-for-email",
  RESEND_MAGIC_LINK_EMAIL: "/resend-magic-link-email",
  SIGN_IN_LINK_HAS_EXPIRED: "/sign-in-link-expired",
  ERROR: "/error",
}

export default routes
