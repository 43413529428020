const colours = {
  WHITE: "#ffffff",
  DARK_BLUE: "#161466",
  CLOUDY_BLUE: "#b9b8d1",
  NORMAL_BLUE: "#2460d8",
  PALE_GREY: "#f8f9fe",
  VERY_LIGHT_BLUE: "#e2e9fb",
}

export default colours
