import React, {useContext, useState} from "react"
import { Redirect, useHistory } from "react-router-dom"
import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import ClaimContext from "../../../contexts/claim.context"
import PageContentText from "../PageContentText"
import SelectField from "../../../components/SelectField"
import NoOptionListedButton from "../NoOptionListedButton"
import { INSURANCE_OPTIONS } from "../../../lib/src/constants"
import routes from "../../../routes"
import TextField from "../../../components/TextField"
import { Body } from "../../../lib/src/components/Typography"
import StyledButton from "../../../components/YesNoUnsureInput/SelectButton"


const highValueInsuranceProviders = [
  { label: "Allianz Rechtsschutz-Service GmbH", value: "876726473670" },
  { label: "Debeka Rechtsschutz-Schadenabwicklung GmbH",
    value: "876727129030" },
  { label: "HUK-COBURG-Rechtsschutzversicherung AG", value: "876727587782" },
  { label: "ÖRAG Rechtsschutzversicherungs-AG", value: "876728112070" },
  { label: "ROLAND Rechtsschutz-Versicherungs-AG", value: "876726277062" },
  { label: "ADVOCARD Rechtsschutzversicherung AG", value: "876726408134" },
  { label: "Zurich Rechtsschutz-Schadenservice GmbH", value: "876726080454" },
  { label: "ERGO Versicherung AG", value: "876725294022" },
]


function KapInsuranceSelectPage (
  {
    nextPage,
    prevPage,
    openNotEligibleModal,
  }: {
    nextPage: string,
    prevPage: string,
    openNotEligibleModal: (obj: { message: string }) => void,
  },
) {
  const claim = useContext(ClaimContext) as Claim
  const [customInsurance, setCustomInsurance] = useState("")
  const [hasCustomInsurance, setHasCustomInsurance] = useState(false)
  const history = useHistory()
  if (claim.lawFirm !== "KAP") {
    // return <Redirect to={nextPage} />
  }

  return (
    <FlexContainer direction='ttb' center>
      <PageContentText>
        Name des Versicherers
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        {!hasCustomInsurance &&
          <SelectField
            options={INSURANCE_OPTIONS}
            onChange={(e: any) => {
              const { value } = e.target
              if (highValueInsuranceProviders.map(
                v => v.value,
              ).includes(value)) {
                claim.update({
                  insuranceProvider: value,
                  highValueInsuranceProvider: true,
                })
              }
              else {
                claim.update({
                  insuranceProvider: value,
                  highValueInsuranceProvider: false,
                })
              }

              history.push(routes.REGISTRATION_CALCULATING_AWARD)
            }}
          />
        }
        {hasCustomInsurance &&
          <>
            {/* @ts-expect-error */}
            <Body.Bold>Andere Versicherung</Body.Bold>
            <TextField
              value={customInsurance}
              fullWidth
              onChange={(e: any) => {
                const { value } = e.target
                setCustomInsurance(value)

                claim.update({
                  insuranceProvider: "",
                  highValueInsuranceProvider: false,
                  customInsuranceProvider: value,
                })
              }}
            />
            <StyledButton
              variant='contained'
              color='primary'
              type='submit'
              inactive={!customInsurance}
              style={{ marginTop: 20, width: "100%" }}
              onClick={() => {
                if (!customInsurance) {
                  return
                }

                history.push(routes.REGISTRATION_CALCULATING_AWARD)
              }}
            >
              Weiter
            </StyledButton>
          </>
        }
      </FormSection>
      <NoOptionListedButton onClick={() => {
        setHasCustomInsurance(!hasCustomInsurance)
      }}
      >{
          !hasCustomInsurance
            ? "Mein Versicherer ist nicht aufgeführt"
            : "Zeige mir die Liste mit Versicherungen an"
        }
      </NoOptionListedButton>
    </FlexContainer>
  )
}


export default KapInsuranceSelectPage
