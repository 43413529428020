/* global gtag */
import React from "react"
import FormContainer from "../../containers/FormContainer"
import RepresentationForm from "../../components/RepresentationForm"


export default function FormRepresentationPage (props: any) {
  if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "claimback-production") {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'gtag'.
    gtag(
      "event",
      "conversion",
      { send_to: "AW-750853564/eF23CIOs7KcBELy7hOYC" },
    )
  }

  return (
    <FormContainer isTest={props.isTest} >
      {({
        user,
        ...props
      }: any) => {
        return <RepresentationForm user={user} {...props} />
      }}
    </FormContainer>
  )
}
