import { useState, useEffect } from "react"
import { db } from "../../../firebase"

/**
 * Returns the entire set of a collection
 */

function useCollection (
  path: string,  // path to the collection (from root reference)
): [
  Record<string, any>[] | null,
  Record<string, any> | null,
  boolean,
  Record<string, any> | null,
  Record<string, any> | null,
] {
  const [loading, setLoading] = useState(true)
  const [ref, setRef] = useState<Record<string, any> | null>(null)
  const [querySnapshot, setQuerySnapshot] =
    useState<Record<string, any> | null>(null)
  const [collectionDocuments, setCollectionDocuments] =
    useState<Record<string, any>[] | null>([])
  const [error, setError] = useState<Record<string, any> | null>(null)

  const query = db.collection(path)

  useEffect(() => {
    if (!path) {
      return
    }
    setRef(db.collection(path))
    const unlisten = query.onSnapshot(querySnapshot => {
      const docs: Record<string, any>[] = []
      querySnapshot.forEach(value => {
        if (value.exists) {
          docs.push(value.data())
        }
      })
      setCollectionDocuments(docs)
      setQuerySnapshot(querySnapshot)
      setLoading(false)
    }, err => {
      setLoading(false)
      setError(err)
    })
    return unlisten
  }, [path])

  return [collectionDocuments, querySnapshot, loading, error, ref]
}

export default useCollection
