import firebase from "../../firebase"
import React, { useContext, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import FullScreenLoader from "../../components/FullScreenLoader"
import { AuthUserContext, UserContext } from "../../contexts"
import userIsRegistered from "../../helpers/userIsRegistered"
import routes from "../../routes"
import usePrevious from "../../hooks/usePrevious"


function isRegistrationPage (currentPath: any) {
  return currentPath && `${currentPath}`.startsWith(routes.REGISTRATION)
}


function isApplicationRoute (currentPath: any) {
  return currentPath && `${currentPath}`.startsWith(routes.APPLICATION)
}


async function signInAnonymously (setWait: any, setError: any) {
  try {
    console.info("Signing in anon")
    // eslint-disable-next-line
    await firebase.auth().signInAnonymously()
    setWait(false)
  }
  catch (error) {
    if (typeof error === "object" && error && "code" in error) {
      console.error("error during signInAnonymously", error.code)
    }
    setError(error)
    setWait(false)
  }
}


export default function Auth (
  {
    children,
  }: any,
) {
  const authUser = useContext(AuthUserContext)
  const user = useContext(UserContext)

  const [wait, setWait] = useState(false)
  const [error, setError] = useState(null)
  const location = useLocation()
  const prevPathname = usePrevious(location.pathname)

  if (location.pathname !== prevPathname) {
    console.info(`Current path: ${location.pathname}`)
  }

  if (error) {
    throw error
  }
  if (wait) {
    return <FullScreenLoader />
  }

  if (
    isRegistrationPage(location.pathname) &&
    userIsRegistered(authUser, user)
  ) {
    console.info("Auth redirect to application success")
    return <Redirect to={routes.APPLICATION_SUCCESS} push />
  }

  if (!authUser) {
    // user is not logged in
    if (isRegistrationPage(location.pathname)) {
      // here we need to create an anon user
      signInAnonymously(setWait, setError)
    }
  }

  // cannot be doing an application
  // without having completed the registration process
  if (
    isApplicationRoute(location.pathname) &&
    !userIsRegistered(authUser, user)
  ) {
    console.info("Auth redirect to registration")
    return <Redirect to={routes.REGISTRATION} push />
  }
  return children
}
