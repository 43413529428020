import React, { useState, useContext } from "react"
import FormPageLayout from "../../components/FormPageLayout/FormPageLayout"
import { Jumbo, Body } from "../../lib/src/components/Typography"
import Container, { FlexContainer } from "../../components/Container"
import FourOhFourIcon from "../../assets/404.svg"
import SubmitButton from "../../components/Button/SubmitButton"
// import AuthUserContext from '../../contexts/authUser.context'
// import UserContext from '../../contexts/user.context'
import ClaimContext from "../../contexts/claim.context"
// import FeatureFlagContext from '../../contexts/featureFlags.context'


export default function NotFoundPage (props: any) {
  const [calcOpen, setCalcOpen] = useState(false)
  const [calculatedAward, setCalculatedAward] = useState(null)
  // const authUser = useContext(AuthUserContext)
  // const user = useContext(UserContext)
  const claim = useContext(ClaimContext) as Claim
  // console.log("authUser", authUser)
  // console.log("user", user)
  console.info("Claim", claim)

  return (
    <FormPageLayout
      calcOpen={calcOpen}
      setCalcOpen={setCalcOpen}
      calculatedAward={calculatedAward}
      setCalculatedAward={setCalculatedAward}
    >
      {/* @ts-expect-error */}
      <Container p={1} pb={0}>
        <Jumbo>Seite nicht gefunden</Jumbo>
        <Body>Da sind Sie wohl falsch abgebogen!</Body>
        <a href='/' style={{ textDecoration: "none" }}>
          <SubmitButton icon={null} style={{ marginTop: 14 }}>
            Zurück nach Hause
          </SubmitButton>
        </a>
        <FlexContainer center>
          <img src={FourOhFourIcon} alt='Page Not Found' />
        </FlexContainer>
      </Container>
    </FormPageLayout>
  )
}
