import styled from "styled-components"
import media from "../../utils/media"
import React from "react"
import {
  Button,
  Card,
  CardContent,
  Popover,
  Typography,
} from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import Show from "../Show"


const StyledButton = styled(Button) `
  && {
    font-family: Heebo;
    text-transform: initial;
    border-radius: 8px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    padding: 0 30px;
    box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
    color: white;
    background: ${(props: any) => props.disabled ? "#b9b8d1" : "#2460d8"};
    &:hover {
      background: ${(props: any) => props.disabled ? "#b9b8d1" : "#161466"};
    }
    text-decoration: none;
    margin-right: 12px;

    ${(media as any).only.xs `
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    `}
  }
`


function PhoneInfo () {
  return (
    <Card>
      <CardContent>
        <Typography variant='body1'>
          Kontaktieren Sie uns kostenlos:<br />
          Tel. <a href='tel:08002524625'>0800 252 4625</a><br />
          (Mo bis Fr: 10 – 17 Uhr)
        </Typography>
      </CardContent>
    </Card>
  )
}


export default function PhoneButton () {
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick (event: any) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <StyledButton onClick={handleClick}>
        <PhoneIcon />
        {/* @ts-expect-error ts-migrate(2322) Remove to see full error */}
        <Show above='sm'>&nbsp;&nbsp;Call</Show>
      </StyledButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PhoneInfo />
      </Popover>
    </>
  )
}
