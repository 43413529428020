import { LinearProgress } from "@material-ui/core"
import React from "react"
import { animated } from "@react-spring/web"
import styled from "styled-components"
import { PALE_GREY } from "../../colours"
import { Flex } from "../../lib"
import Container from "../../components/Container"
import Appbar from "../../components/Appbar"
import BulletPointHeader from "./BulletPointHeader"
import BulletPointFooter from "./BulletPointFooter"

const FullHeightContainer = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

const SlideContainer = styled(animated.div)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${(props: any) => props.progress === null && "border-top-left-radius: 10px;"}
  ${(props: any) => props.progress === null && "border-top-right-radius: 10px;"}
  background-color: ${PALE_GREY};
`

const ProgressBar = styled(LinearProgress)`
  && {
    height: 7px;
  }

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const AppbarPadding = styled.div`
  height: 64px;

  @media (min-width: 992px){
    height: 80px;
  }
`


export default function PageLayout (
  {
    children,
    subtitle,
    Header,
    progress,
    animationProps,
  }: any,
) {
  return (
    <FullHeightContainer>
      <Appbar />
      <AppbarPadding />
      <Container>
        {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
        <Flex direction='ttb' centerstyle={{ minWidth: 300 }}>
          {Header && <Header />}
          {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
          <Flex
            direction='ttb'
            style={{ maxWidth: 576, width: "100%", margin: "0 auto" }}
          >
            {progress !== null &&
              <ProgressBar variant='determinate' value={progress} />
            }
            <SlideContainer
              style={animationProps}
              // @ts-expect-error
              progress={progress}
            >
              <BulletPointHeader />
              {children}
              <BulletPointFooter />
            </SlideContainer>
          </Flex>
        </Flex>
      </Container>
    </FullHeightContainer>
  )
}
