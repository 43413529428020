import React from "react"
import { FormHelper } from "../../lib/src/components/Typography"
import { FlexContainer } from "../../components/Container"
import { COLOURS } from "../../lib/src/constants"
const { VERY_LIGHT_BLUE, NORMAL_BLUE } = COLOURS


export default function BulletPointFooter () {
  return (
    <FlexContainer center style={{ backgroundColor: VERY_LIGHT_BLUE }} p={0.5}>
      <FormHelper
        style={{ color: NORMAL_BLUE, fontWeight: "bold", fontSize: 12 }}
      >
        100% Kostenübernahme & 0% Prozesskostenrisiko
      </FormHelper>
    </FlexContainer>
  )
}
