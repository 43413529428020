import firebase from "../../firebase"
import Flex from "../../lib/src/components/Flex"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import React, { useContext, useState } from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import Appbar from "../../components/Appbar"
import SubmitButton from "../../components/Button/SubmitButton"
import OAuthButton from "../../components/Button/OAuthButton"
import Container, { FlexContainer } from "../../components/Container"
import FieldError from "../../components/FieldError"
import TextField from "../../components/TextField"
import { Body, H1, Subline } from "../../lib/src/components/Typography"
import Wait from "../../components/Wait"
import { AuthUserContext, UserContext } from "../../contexts"
import routes from "../../routes"
import FacebookLogo from "../../assets/facebook-logo.svg"
import GoogleLogo from "../../assets/google-logo.svg"
import { PALE_GREY, VERY_LIGHT_BLUE } from "../../colours"
import media from "../../utils/media"
import { parse } from "querystring"


const Divider = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  height: 1px;
  width: 100%;
  background-color: ${VERY_LIGHT_BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
`

const DividerLabel = styled(Body.Small)`
  font-style: italic;
  font-size: 12px !important;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${PALE_GREY};
`
const TopMargin = styled.div `
  ${(media as any).to(992) `
    margin-top: 64px;
  `}
  ${(media as any).from(993) `
    margin-top: 80px;
  `}
`


export default function PromptForEmailPage (
  {
    isTest,
  }: any,
) {
  const emailForSignIn = window.localStorage.getItem("emailForSignIn")
  const authUser = useContext(AuthUserContext) as Record<string, any>
  const user = useContext(UserContext) as User
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [showHelp, setShowHelp] = useState(false)
  const [linkHasExpired, setLinkHasExpired] = useState(false)
  const [
    currentlySigningInEmail,
    setCurrentlySigningInEmail,
  ] = useState(Boolean(emailForSignIn))
  const location = useLocation()
  const { redirect } = parse(location.search)

  // if there is an authed & registered user - continue to the application
  if (authUser && user && user.hasCompletedRegistration) {
    console.info("User is already signed in, redirecting to success")
    console.info("Successful sign in for email", authUser.email)
    if (redirect) {
      return <Redirect
        // @ts-expect-error
        to={redirect}
      />
    }
    return <Redirect to={routes.APPLICATION_SUCCESS} />
  }

  // if the link has expired redirect the user to a better page
  if (linkHasExpired) {
    console.info("Redirecting for expired link")
    return <Redirect to={routes.SIGN_IN_LINK_HAS_EXPIRED} />
  }

  // eslint-disable-next-line
  if (!isTest && !firebase.auth().isSignInWithEmailLink(window.location.href)) {
    throw new Error("Sign in attempted with a incorrect url params")
  }


  function handleEmailChange (e: any) {
    setEmail(e.target.value.trim())
  }


  function handleConfirmEmail () {
    if (!email) {
      // @ts-expect-error ts-migrate(2345) Remove to see error
      return setError("Diese Angabe wird benötigt.")
    }
    attemptSignIn(email)
  }


  async function handleFacebookSignIn () {
    // eslint-disable-next-line
    const provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope("email")
    console.info("Signing in with Facebook")
    // eslint-disable-next-line
    firebase.auth()
      ?.currentUser
      ?.linkWithRedirect(provider)
  }


  async function handleGoogleSignIn () {
    // eslint-disable-next-line
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope("email")
    console.info("Signing in with Google")
    // eslint-disable-next-line
    firebase.auth()
      ?.currentUser
      ?.linkWithRedirect(provider)
  }


  function attemptSignIn (email: string) {
    console.info("Signing in with email")
    // eslint-disable-next-line
    firebase.auth()
      .signInWithEmailLink(email, window.location.href)
      .then(() => {
        console.info("Successful sign in for email", email)
        window.localStorage.setItem("emailForSignIn", email)
        // Auth user should be picked up via context now
        setCurrentlySigningInEmail(false)
      })
      .catch(async error => {
        if (error.code === "auth/invalid-action-code") {
          // do not clear localStorage in this case
          window.localStorage.setItem("emailForSignIn", email)
          setLinkHasExpired(true)
          return
        }
        else if (error.code === "auth/invalid-email") {
          setError(
            // @ts-expect-error ts-migrate(2345) Remove to see error
            "Die angegebene E-Mail-Adresse " +
            "stimmt nicht mit der Anmelde-E-Mail-Adresse überein.",
          )
          window.localStorage.removeItem("emailForSignIn")
        }
        else {
          console.error("Unexpected error occurred during sign in")
          console.error(error)
          window.localStorage.removeItem("emailForSignIn")
          history.push(routes.ERROR)
          return
        }
        setCurrentlySigningInEmail(false)
      })
  }


  if (emailForSignIn) {
    // lets attempt to sign in
    attemptSignIn(emailForSignIn)
  }

  return (
    <>
      <Wait on={!currentlySigningInEmail}>
        <Appbar />
        <TopMargin />
        {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
        <Flex center>
          <FlexContainer p={3} center style={{ maxWidth: 720 }}>
            {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
            <Flex fullWidth direction='ttb'>

              <H1>
                Sicherheitskontrolle
              </H1>
              <Subline>
                Aus Sicherheitsgründen bitten wir Sie,
                die E-Mail-Adresse (mit der Sie sich registriert haben)
                einzugeben, um Ihre Identität zu bestätigen.
              </Subline>
              {/* @ts-expect-error */}
              <Container px={0} py={1}>
                <TextField
                  style={{ width: "94.5%" }}
                  placeholder='Ihre Email-Addresse'
                  fullWidth
                  onChange={handleEmailChange}
                  value={email}
                />
                {error &&
                  // @ts-expect-error
                  <FieldError>{error}</FieldError>
                }
                {/* @ts-expect-error */}
                <Container p={0} pb={1}>
                  <SubmitButton
                    style={{ width: "94.5%" }} onClick={handleConfirmEmail}
                  >Bestätigen
                  </SubmitButton>

                  <Divider style={{ width: "94.5%" }}>
                    {/* @ts-expect-error */}
                    <DividerLabel>oder Login mit</DividerLabel>
                  </Divider>
                  <ButtonGroup orientation='horizontal'>
                    <OAuthButton onClick={handleFacebookSignIn}>
                      <img
                        height='100%'
                        alt='facebook-logo'
                        src={FacebookLogo}
                      />
                      &nbsp;&nbsp;
                      Facebook
                    </OAuthButton>
                    <OAuthButton onClick={handleGoogleSignIn}>
                      <img
                        width='25px'
                        height='25px'
                        alt='google-logo'
                        src={GoogleLogo}
                      />&nbsp;&nbsp;
                      Google
                    </OAuthButton>
                  </ButtonGroup>
                </Container>
              </Container>

              {/* @ts-expect-error */}
              <Body.Bold
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setShowHelp(!showHelp)}
              >
                Mehr erfahren...
              </Body.Bold>

              {showHelp && (
                // @ts-expect-error
                <Body.Small>
                  Wir bitten um Ihr Verständnis für diesen zusätzlichen Schritt,
                  denn die Sicherheit Ihrer Daten ist für uns sehr wichtig.
                  <br />
                  <br />
                  Durch diese Prüfung verhindern wir,
                  dass Angreifer auf Ihre Informationen zugreifen können.
                  Bitte beachten Sie, dass Anmeldelinks,
                  die ohne diese Prüfung per E-Mail gesendet werden,
                  unsicher sind und als gefährlich eingestuft werden sollten.
                </Body.Small>
              )}

            </Flex>
          </FlexContainer>
        </Flex>
      </Wait>
    </>
  )
}
