import {
  filter,
  map,
  range,
  sortBy,
  toLower,
  toNumber,
  uniq,
  flatten,
} from "lodash"

// Schema example:
//   make: 'volkswagen'
//   model: 'Beetle'
//   year: 2011
//   purchaseStart: ''
//   purchaseEnd: ''
//   altTrack: ''
//   top: '✔'
//   kap: ''
//   is3Liter: ''
//   isEa189: '✔'
import modelsArray from "./models-from-csv.json"



export function getModelsByBrand () {
  const modelsByBrand = {}

  // Normalize all cars
  // and group them by car brand
  modelsArray.forEach(car => {
    // @ts-expect-error ts-migrate(2322) Remove to see error
    car.purchaseStart = car.purchaseStart || null
    // @ts-expect-error ts-migrate(2322) Remove to see error
    car.purchaseEnd = car.purchaseEnd || null
    // @ts-expect-error ts-migrate(2551) Remove to see error
    car.alt_track = car.alt_track || false
    // @ts-expect-error ts-migrate(2322) Remove to see error
    car.top = car.top || false
    // @ts-expect-error ts-migrate(2322) Remove to see error
    car.kap = car.kap || false
    // @ts-expect-error ts-migrate(2322) Remove to see error
    car.is3Liter = car.is3Liter !== "" && Boolean(car.is3Liter)
    // @ts-expect-error ts-migrate(2322) Remove to see error
    car.isEa189 = car.isEa189 !== "" && Boolean(car.isEa189)
    // TODO: Replace after VW name is fixed everywhere
    car.make = car.make.replace("volkswagen", "volkswagon")

    // @ts-expect-error ts-migrate(7053) Remove to see error
    modelsByBrand[car.make] = modelsByBrand[car.make] || []
    // @ts-expect-error ts-migrate(7053) Remove to see error
    modelsByBrand[car.make].push(car)
  })

  return modelsByBrand
}


export function getModelOptions (brand: any) {
  const modelOptionsMap = {}

  modelsArray.forEach(car => {
    if (car.make === brand) {
      // @ts-expect-error ts-migrate(7053) Remove to see error
      modelOptionsMap[car.model] = toLower(car.model)
    }
  })

  return Object
    .entries(modelOptionsMap)
    .map(([key, value]) => ({label: key, value}))
}


export function getYearOptions (brand: any, model: any) {
  const models = getModelsByBrand()
  const modelYears = uniq(
    map(
      // @ts-expect-error ts-migrate(7053) Remove to see error
      filter(models[brand], (c: any) => toLower(c.model) === toLower(model)),
      "year",
    ),
  ).map((year: any) => ({
    label: String(year),
    value: toNumber(year),
  }))

  if (modelYears.length === 0) {
    return range(2003, new Date().getFullYear())
      .map((y: any) => ({
        label: String(y),
        value: y,
      }))
  }
  return sortBy(modelYears, "value")
}


export function getAltTrack ({
  carModel,
}: any) {
  const models = getModelsByBrand()
  const altModels = filter(
    // @ts-expect-error ts-migrate(7053) Remove to see error
    flatten(map(models, (v: any, k: any) => models[k])),
    "alt_track",
  )
  const matchModels = filter(
    altModels,
    (t: any) => toLower(t.model) === toLower(carModel),
  )
  return Boolean(matchModels.length)
}


export function getIsTop ({
  carModel,
}: any) {
  const models = getModelsByBrand()
  const topModels = filter(
    // @ts-expect-error ts-migrate(7053) Remove to see error
    flatten(map(models, (v: any, k: any) => models[k])),
    "top",
  )
  const matchModels = filter(
    topModels,
    (t: any) => toLower(t.model) === toLower(carModel),
  )
  return Boolean(matchModels.length)
}


export function getIsKap ({
  carModel,
}: any) {
  const models = getModelsByBrand()
  const kapModels = filter(
    // @ts-expect-error ts-migrate(7053) Remove to see error
    flatten(map(models, (v: any, k: any) => models[k])),
    "kap",
  )
  const matchModels = filter(
    kapModels,
    (t: any) => toLower(t.model) === toLower(carModel),
  )
  return Boolean(matchModels.length)
}
