import React from "react"
import { useHistory } from "react-router-dom"
import Button from "../../components/Button"
import Container, { FlexContainer } from "../../components/Container"
import { H1, Subline, Body } from "../../lib/src/components/Typography"
import UploadFileInput from "../../lib/src/components/UploadFileInput"
import Wait from "../../components/Wait"
import FormContainer from "../../containers/FormContainer"
import useFiles from "../../hooks/useFiles"
import routes from "../../routes"


export default function UploadKvPage (
  {
    isTest,
  }: any,
) {
  let [files, filesLoading] = useFiles()
  const history = useHistory()

  if (isTest) {
    // @ts-expect-error ts-migrate(2322)
    // FIXME: Type '{ purchaseContracts: never[]; }' is not assigned
    files = { purchaseContracts: [] }
    filesLoading = false
  }

  return (<FormContainer isTest={isTest}>
    {({ user, claim }: any) => (<Wait on={!filesLoading}>
      <FlexContainer direction='ttb' fullWidth>
        {/* @ts-expect-error */}
        <Body.Bold
          style={{ marginBottom: 12 }}
        >
              Ihre Claim-ID:{" "}
          <span style={{ color: "#2460d8" }}>
            {claim.id}
          </span>
        </Body.Bold>
        <H1>
              Upload Kaufvertrag
        </H1>
        <Subline>
              Ihr Kaufvertrag wird benötigt,
              um Ihren Anspruch festzustellen und durchzusetzen
        </Subline>
        {/* @ts-expect-error */}
        <Container p={0.5}/>
        { !isTest &&
            <UploadFileInput
              // @ts-expect-error TODO: buttonLabel does not exist
              buttonLabel='Kaufvertrag hochladen'
              subdir='purchaseContract'
              claimId={claim.id}
              userId={user.uid}
              initialUploadedFiles={(files as any).purchaseContracts}
            />
        }
        {/* @ts-expect-error */}
        <Container p={0.5}/>
        <Button onClick={() => history.push(routes.APPLICATION_SUCCESS)}>
              Zurück zu Ihrem Formular
        </Button>
      </FlexContainer>
    </Wait>)}
  </FormContainer>)
}
