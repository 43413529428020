import React, { useContext } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import SubmitButton from "../Button/SubmitButton"
import Show from "../Show"
import TextField from "../TextField"
import SelectField from "../SelectField"
import Container, { FlexContainer } from "../Container"
import { H4, Body, Jumbo } from "../../lib/src/components/Typography"
import FormSection from "../FormSection/FormSection"
import FieldError from "../FieldError/FieldError"
// import { RadioGroup } from '@material-ui/core'
// import { Flex } from '../lib'
// import SelectButton from '../YesNoUnsureInput/SelectButton'
import { withRouter } from "react-router-dom"
import routes from "../../routes"
import { ClaimContext } from "../../contexts"


function initialValues (
  {
    carModel,
    carYear,
    vinNumber,

    // carPurchaseEntity,
    companyName,

    firstName,
    lastName,
    address,
    postcode,
    city,
  }: any = {},
) {
  return {
    carModel: carModel || "",
    carYear: carYear || "",
    vinNumber: vinNumber || "",
    // carPurchaseEntity: carPurchaseEntity || '',
    companyName: companyName || "",
    firstName: firstName || "",
    lastName: lastName || "",
    address: address || "",
    postcode: postcode || "",
    city: city || "",
  }
}


const REQUIRED = [
  "carModel",
  "carYear",
  "vinNumber",
  // 'carPurchaseEntity',
  "address",
  "postcode",
  "city",
]

const customRequiredErrors = {
  vinNumber: "Bitte geben Sie Ihre FIN Nummer an.",
}


// TODO: these messages need translating
function validator (values: any) {
  const errors: Record<string, any> = {}

  const { vinNumber } = values
  if (vinNumber.length !== 17) {
    errors.vinNumber = "Eine FIN Nummer besteht aus 17 Buchstaben und Ziffern."
  }

  const required = [...REQUIRED]

  // if (values.carPurchaseEntity === "firma") {
  //   required = [...required, "companyName"]
  // }

  // if (values.carPurchaseEntity === "privat") {
  //   required = [...required, "firstName", "lastName"]
  // }

  required.forEach(key => {
    if (values[key] === "") {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
      errors[key] = customRequiredErrors[key] || "Diese Angabe wird benötigt."
    }
  })
  return errors
}


function FormikTextField (
  {
    label,
    field,
    form,
    ...props
  }: any,
) {
  return (
    <TextField
      label={label}
      onChange={(e: any) =>
        form.setFieldValue(field.name, e.target.value)
      }
      {...props}
      {...field}
    />
  )
}


function CarYearField (
  {
    form,
    field,
    ...props
  }: any,
) {
  return (
    <SelectField
      label='Baujahr des Fahrzeugs'
      name='carYear'
      options={[
        { label: "2017", value: 2017 },
        { label: "2016", value: 2016 },
        { label: "2015", value: 2015 },
        { label: "2014", value: 2014 },
        { label: "2013", value: 2013 },
        { label: "2012", value: 2012 },
        { label: "2011", value: 2011 },
        { label: "2010", value: 2010 },
        { label: "2009", value: 2009 },
        { label: "2008", value: 2008 },
        { label: "2007", value: 2007 },
        { label: "2006", value: 2006 },
        { label: "2005", value: 2005 },
      ]}
      onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
      value={field.value || ""}
      onBlur={field.onBlur}
      {...field}
      {...props}
    />
  )
}


// const PrivatFirmaButtons = ({ form, field, ...props }) => {
//   const hasValue = field.value !== ''
//   const isPrivat = field.value === 'privat'
//   const isFirma = field.value === 'firma'
//   return (
//     <RadioGroup {...field} {...props}>
//       <Flex>
//         <SelectButton
//           inactive={hasValue && !isPrivat}
//           name={`${field.name}-privat`}
//           active={isPrivat}
//           onBlur={field.onBlur}
//           onClick={() => {
//             form.setFieldValue(field.name, 'privat')
//           }}
//         >
//           Privat
//         </SelectButton>
//         <SelectButton
//           inactive={hasValue && !isFirma}
//           name={`${field.name}-firma`}
//           active={isFirma}
//           onBlur={field.onBlur}
//           onClick={() => form.setFieldValue(field.name, 'firma')}
//         >
//           Firma
//         </SelectButton>
//       </Flex>
//     </RadioGroup>
//   )
// }


function PersonalDataForm (
  {
    user,
    history,
  }: any,
) {
  const claim = useContext(ClaimContext) as Claim

  return (
    <Formik
      initialValues={initialValues(claim)}
      validate={validator}
      onSubmit={async (values) => {
        delete (values as any).purchaseContract
        await claim.update(values)
        history.push(routes.APPLICATION_REVIEW)
      }}
    >
      {/* @ts-expect-error ts-migrate(2322) Remove to see full error */}
      {({
        handleSubmit,
        isSubmitting,
        values,
        errors,
        status,
        ...args
      } = {},
      ) => {
        return (
          <div style={{ maxWidth: 600 }}>
            <Form>
              {/* @ts-expect-error */}
              <Body.Bold>
                Ihre Claim-ID:{" "}
                <span style={{ color: "#2460d8" }}>{claim.id}</span>
              </Body.Bold>
              <Jumbo>
                Claim vervollständigen!
              </Jumbo>
              <Body>
                Bitte kontrollieren Sie,
                ob alle eingegebenen Daten korrekt sind.
              </Body>

              {/* @ts-expect-error */}
              <Container p={0} py={1} mt={1}>
                <H4>Daten zum Fahrzeug</H4>
              </Container>

              {/* carModel */}
              <FormSection
                heading='Modell des Fahrzeugs'
              >
                <Field
                  name='carModel'
                  label='Modell des Fahrzeugs'
                  inputProps={{
                    maxLength: 50,
                  }}
                  fullWidth
                  component={FormikTextField}
                />
                <ErrorMessage name='carModel' component={FieldError} />
              </FormSection>

              {/* carYear */}
              <FormSection
                heading='Baujahr des Fahrzeugs'
              >
                <Field
                  name='carYear'
                  component={CarYearField}
                />
                <ErrorMessage name='carYear' component={FieldError} />
              </FormSection>

              {/* vinNumber */}
              <FormSection
                heading='FIN Nummer'
                tooltip={
                  "Die FIN finden Sie im Fahrzeugschein, " +
                  "Kaufvertag oder unter der Windschutzscheibe des Fahrzeugs."
                }
              >
                <Field
                  label='FIN Nummer'
                  name='vinNumber'
                  inputProps={{
                    maxLength: 17,
                    minLength: 17,
                  }}
                  fullWidth
                  component={FormikTextField}
                />
                <ErrorMessage name='vinNumber' component={FieldError} />
              </FormSection>

              {/* carPurchaseEntity */}
              {/* <FormSection
                heading='Wurde das Fahrzeug privat oder geschäftlich erworben?'
              >
                <Field
                  name='carPurchaseEntity'
                  component={PrivatFirmaButtons}
                />
                <ErrorMessage name='carPurchaseEntity' component={FieldError} />
              </FormSection> */}

              <FormSection
                heading='Adressdaten zum Autokäufer'
              >

                {/* companyName */}
                {/* {values.carPurchaseEntity === 'firma' && (
                  <>
                    <Field
                      fullWidth
                      label='Firmenname'
                      name='companyName'
                      component={FormikTextField}
                    />
                    <ErrorMessage name='companyName' component={FieldError} />
                  </>
                )} */}

                <>
                  {/* firstName */}
                  <Field
                    fullWidth
                    label='Vorname'
                    name='firstName'
                    component={FormikTextField}
                  />
                  <ErrorMessage name='firstName' component={FieldError} />

                  {/* lastName */}
                  <Field
                    fullWidth
                    label='Nachname'
                    name='lastName'
                    component={FormikTextField}
                  />
                  <ErrorMessage name='lastName' component={FieldError} />
                </>

                {/* address */}
                <Field
                  fullWidth
                  name='address'
                  label='Adresse'
                  component={FormikTextField}
                />
                <ErrorMessage name='address' component={FieldError} />

                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show from='md'>
                  <FlexContainer p={0} mt={0.5} alignItems='stretch'>
                    {/* postcode */}
                    <div style={{ width: "35%" }}>
                      <Field
                        fullWidth
                        label='Postleitzahl'
                        name='postcode'
                        inputProps={{
                          maxLength: 5,
                        }}
                        component={FormikTextField}
                      />
                      <ErrorMessage name='postcode' component={FieldError} />
                    </div>

                    {/* city */}
                    <div style={{ width: "65%", marginLeft: 10 }}>
                      <Field
                        fullWidth
                        label='Stadt'
                        name='city'
                        component={FormikTextField}
                      />
                      <ErrorMessage name='city' component={FieldError} />
                    </div>
                  </FlexContainer>
                </Show>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show below='md'>
                  <Field
                    fullWidth
                    label='Postleitzahl'
                    name='postcode'
                    inputProps={{
                      maxLength: 5,
                    }}
                    component={FormikTextField}
                  />
                  <ErrorMessage name='postcode' component={FieldError} />
                  <Field
                    fullWidth
                    label='Stadt'
                    name='city'
                    component={FormikTextField}
                  />
                  <ErrorMessage name='city' component={FieldError} />
                </Show>

              </FormSection>
              <SubmitButton disabled={isSubmitting} onClick={handleSubmit}>
                Claim Daten vervollständigen
              </SubmitButton>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}


export default withRouter(PersonalDataForm)
