export default function userIsRegistered (user: any, userData: any) {
  if (!user) {
    return false
  }
  if (!user.email) {
    return false
  }
  if (!userData) {
    return false
  }
  if (!userData.hasCompletedRegistration) {
    return false
  }
  return true
}
