import useCollection from "./useCollection"

/**
 * Takes the feature flag collection
 * and transforms all the flags into a useful data structure.
 * Eventually the get call will have to come from a firebase function
 * to get the randomly determined rollout percentage.
 */
export default function useFeatureFlag (): [
  Record<string, any>,
  boolean,
  Record<string, any> | null,
  ] {
  const [flagData, _unused_, loading, error] = useCollection("featureFlags")

  const output = {} as Record<string, any>

  (flagData as Flag[] || []).forEach((flag: Flag) => {
    if (flag) {
      const { name, value, data } = flag
      output[name as string] = {
        value: Boolean(value),
        data,
      }
    }
  })

  return [output, loading, error]
}
