import React from "react"
import ErrorPage from "./pages/ErrorPage"
import { get } from "lodash"
import firebase from "./firebase"

class ErrorBoundary extends React.Component {
  constructor (props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error: any, info: any) {
    // You can also log the error to an error reporting service
    console.error(
      "Error thrown to App Boundary.",
      error,
      info,
    )

    // Display fallback UI
    this.setState({ hasError: true, error })
  }

  render () {
    // FIXME: Property 'error' does not exist on type
    // @ts-expect-error ts-migrate(2339)
    const { error, hasError } = this.state
    if (hasError) {
      // handle custom errors here.
      if (get(error, "code") === "auth/invalid-action-code") {
        return <ErrorPage
          message={
            "Entschuldigung, der angegebene Link ist nicht mehr gültig. " +
            "Ein neuer Link um sich einzuloggen " +
            "wurde Ihnen per E-Mail zugesandt."
          }
        />
      }
      return (
        <ErrorPage />
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
