import { useState, useEffect, useContext } from "react"
import firebase from "../firebase"
import AuthUserContext from "../contexts/authUser.context"
import ClaimContext from "../contexts/claim.context"
import { get, toString } from "lodash"

// eslint-disable-next-line
const storageRef = firebase.storage().ref()

const INITAL_VALUE = {
  purchaseContracts: [],
  financeContracts: [],
  salesContracts: [],
  vehicleRegistrationDocuments: [],
  currentMileageDocuments: [],
}


export default function useFiles () {
  const authUser = useContext(AuthUserContext)
  const claim = useContext(ClaimContext) as Claim
  const [fileObject, setFileObject] = useState(INITAL_VALUE)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const authUserId = toString(get(authUser, "uid"))
    const claimId = get(claim, "id")
    if (!authUserId || !claimId) {
      return
    }
    async function fetchClaim () {
      try {
        const claimRef = storageRef
          .child(authUserId)
          .child("claimFiles")
          .child(claimId)
        const purchaseRef = claimRef.child("purchaseContract")
        const financeRef = claimRef.child("financeContract")
        const salesRef = claimRef.child("salesContract")
        const currentMileageRef = claimRef.child("currentMileageDocument")
        const vehicleRegistrationRef =
          claimRef.child("vehicleRegistrationDocument")

        const purchaseList = await purchaseRef.listAll()
        const salesList = await salesRef.listAll()
        const financeList = await financeRef.listAll()
        const currentMileageList = await currentMileageRef.listAll()
        const vehicleRegistrationList = await vehicleRegistrationRef.listAll()

        setFileObject({
          // @ts-expect-error ts-migrate(2322) Remove to see error
          purchaseContracts: purchaseList.items,
          // @ts-expect-error ts-migrate(2322) Remove to see error
          financeContracts: financeList.items,
          // @ts-expect-error ts-migrate(2322) Remove to see error
          salesContracts: salesList.items,
          // @ts-expect-error ts-migrate(2322) Remove to see error
          currentMileageDocuments: currentMileageList.items,
          // @ts-expect-error ts-migrate(2322) Remove to see error
          vehicleRegistrationDocuments: vehicleRegistrationList.items,
        })
      }
      catch (error) {
        // TODO: handle this error
        console.warn("An exception was thrown in useFiles")
        console.info("useFiles:authUser", authUser)
        console.error(error)
        setFileObject(INITAL_VALUE)
        throw error
      }
      setLoading(false)
    }
    fetchClaim()
  }, [authUser, claim])

  return [fileObject, loading]
}
