import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { Flex } from "../../lib"
import Loader from "../Loader"
import { PALE_GREY } from "../../colours"

const portalRoot = document.getElementById("portal-root")

const Wrapper = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  background-color: ${PALE_GREY};
`

class Portal extends React.Component {
  constructor (props: any) {
    super(props)
    // @ts-expect-error
    this.el = document.createElement("div")
  }

  componentDidMount () {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    if (portalRoot) {
      portalRoot.appendChild(
        // @ts-expect-error
        this.el,
      )
    }
  }

  componentWillUnmount () {
    if (portalRoot) {
      portalRoot.removeChild(
        // @ts-expect-error
        this.el,
      )
    }
  }

  render () {
    return ReactDOM.createPortal(
      this.props.children,
      // @ts-expect-error
      this.el,
    )
  }
}


export default function FullScreenLoader () {
  return (
    <Portal>
      <Wrapper center>
        <Loader />
      </Wrapper>
    </Portal>
  )
}
