import React from "react"
import { oneLine } from "common-tags"


export default function Calendar (props: any) {
  return (
    <svg viewBox='0 0 448 512' {...props}>
      <path
        d={oneLine`
          M0 192v272c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0z
          m384 144c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64
          c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64zm-80-208h32
          c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v96
          c0 8.8 7.2 16 16 16zm-192 0h32c8.8 0 16-7.2 16-16V16
          c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16z
        `}
        fill='#2561d9'
      />
      <path
        d={oneLine`
          M448 112v80H0v-80c0-26.5 21.5-48 48-48h48v48c0 8.8 7.2 16 16 16h32
          c8.8 0 16-7.2 16-16V64h128v48c0 8.8 7.2 16 16 16h32
          c8.8 0 16-7.2 16-16V64h48c26.5 0 48 21.5 48 48z
        `}
        fill='#151465'
      />
    </svg>
  )
}
