import { get } from "lodash"
import React, { useContext } from "react"
import styled from "styled-components"

import { ClaimContext } from "../../../contexts"
import Container, { FlexContainer } from "../../../components/Container"
import { Body, H1, Jumbo } from "../../../lib/src/components/Typography"
import Checkmark from "../../../components/icons/Checkmark"
import { COLOURS } from "../../../lib/src/constants"
import { calculateAllRewards } from "../../../lib/src/helpers/calculateAward"

const Heading = styled(H1)`
  text-align: center;
`

function ValidClaimHeader () {
  const claim = useContext(ClaimContext) as Claim
  const rewards = calculateAllRewards(claim)
  const maxReward = get(rewards, "maxReward", -1)

  if (maxReward && maxReward < 1) {
    return (
      <Container>
        <Heading>Sie haben voraussichtlich Anspruch!</Heading>
      </Container>
    )
  }

  return (
    <FlexContainer center direction='ttb'>
      <Checkmark width={60} height={60} />
      {/* @ts-expect-error */}
      <Body.Bold style={{ textAlign: "center", marginTop: 12 }}>
        Anspruch auf bis zu
      </Body.Bold>
      <Jumbo style={{ color: COLOURS.NORMAL_BLUE }}>
        {Number(maxReward).toLocaleString("de-DE")}&nbsp;€
      </Jumbo>
    </FlexContainer>
  )
}

export default ValidClaimHeader
