import { Collapse } from "@material-ui/core"
import { parse } from "query-string"
import React, { useContext, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import brands from "../../../brands"
import Container, { FlexContainer } from "../../../components/Container"
import SelectField from "../../../components/SelectField"
import { Body } from "../../../lib/src/components/Typography"
import {
  getIsTop,
  getAltTrack,
  getModelOptions,
  getYearOptions,
} from "../../../constants/models"
import { ClaimContext } from "../../../contexts"
import NoOptionListedButton from "../NoOptionListedButton"
import BrandGrid from "./BrandGrid"
import routes from "../../../routes"

const allowedBrands = brands.filter(
  brand => !brand.disallowReg,
)
const brandVals = allowedBrands.map(b => b.value)

function brandFromQS (qs: any) {
  // @ts-expect-error ts-migrate(2345) Remove to see error
  return brandVals.includes(parse(qs).carBrand)
    ? parse(qs).carBrand
    : ""
}

function displayOthersFromQs (qs: any) {
  return parse(qs).carBrand === "others"
}

function BrandModelYearPage ({
  nextPage,
  openNotEligibleModal,
}: any) {
  const location = useLocation()
  const claim = useContext(ClaimContext) as Claim
  const [carBrand, setCarBrand] = useState(brandFromQS(location.search))
  const [carModel, setCarModel] = useState("")
  const [carYear, setCarYear] = useState("")
  const [isNewVehicle, setIsNewVehicle] = useState()
  const [isBoughtAtDealer, setIsBoughtAtDealer] = useState()
  const [isDemoCar, setIsDemoCar] = useState()
  const [isEa189, setIsEa189] = useState(false)
  const displayOthers = displayOthersFromQs(location.search)
  const utmParamQuery = parse(location.search)
  const utmParams = []
  let cnt = 0

  for (const entry in utmParamQuery) {
    if (!entry || !entry.includes("utm_")) {
      continue
    }

    utmParams[cnt] = {
      source: entry,
      value: utmParamQuery[entry],
    }

    cnt++
  }

  if (utmParams.length !== 0) {
    localStorage.setItem("UTM_PARAMS", JSON.stringify(utmParams))
  }

  function handleSetBrand (value: any) {
    if (value === null) {
      setCarModel("")
      setCarYear("")
    }

    setCarBrand(value)
  }

  function saveAndRedirect () {
    const carIsTop = getIsTop({ carModel })
    const altTrack = getAltTrack({ carModel })
    const lawFirm = "KAP"
    const utmParamsStorage = localStorage.getItem("UTM_PARAMS")
    let utmParams: any

    if (utmParamsStorage) {
      utmParams = JSON.parse(localStorage.getItem("UTM_PARAMS") as any)
    }

    claim.update({
      carBrand,
      carModel,
      carYear,
      carIsTop,
      lawFirm,
      alt_track: altTrack,
      tags: claim.tags,
      lead: true,
      utmParams: utmParams && 
        utmParams.length !== 0 
        ? utmParams 
        : claim.utmParams,
    })

    if (lawFirm === "KAP") {
      return <Redirect to={routes.REGISTRATION_PURCHASE_YEAR} />
    }

    return <Redirect to={nextPage} />
  }

  if (
    carBrand && carYear && carModel
  ) {
    if (nextPage) {
      return saveAndRedirect()
    }
    else {
      console.error("No next page was specified")
    }
  }

  return (
    <FlexContainer direction='ttb'>
      {/* @ts-expect-error */}
      <Body.Bold>Automarke</Body.Bold>
      <SelectField
        value={carBrand}
        onChange={async (e: any) => {
          handleSetBrand(e.target.value)
        }}
        options={allowedBrands}
      />
      <Container />
      <Collapse in={Boolean(carBrand)}>
        {/* @ts-expect-error */}
        <Body.Bold>Modell</Body.Bold>
        <SelectField
          value={carModel}
          onChange={async (e: any) => {
            setCarModel(e.target.value)
            claim.update(
              { lawFirm: "KAP",
              },
            )
          }}
          options={getModelOptions(carBrand)}
        />
        <NoOptionListedButton onClick={() => {
          openNotEligibleModal()
        }}
        >Mein Modell ist nicht auf der Liste
        </NoOptionListedButton>
      </Collapse>
      <Container />

      <Collapse in={Boolean(carModel)}>
        {/* @ts-expect-error */}
        <Body.Bold>Baujahr</Body.Bold>
        <SelectField
          value={carYear}
          onChange={async (e: any) => {
            // if (e.target.value < 2014) {
            //   openNotEligibleModal()
            //   return
            // }

            setCarYear(e.target.value)
          }}
          options={getYearOptions(carBrand, carModel)}
        />

        <NoOptionListedButton onClick={() => {
          openNotEligibleModal()
        }}
        >Anderes Baujahr
        </NoOptionListedButton>
      </Collapse>

    </FlexContainer>
  )
}

export default BrandModelYearPage
