import React from "react"
import { oneLine } from "common-tags"

export default function BrokenLink () {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      width='100%'
    >
      <>
        <g>
          <g>
            <path
              d={oneLine`
                M500.232,11.766c-15.688-15.687-41.218-15.688-56.909,0
                L340.459,114.63c-41.716-21.472-94.286-14.79-129.196,20.122
                L135.7,210.314c-34.91,34.91-41.594,87.481-20.122,129.196
                L11.767,443.323c-15.69,15.69-15.69,41.22,0,56.909
                c7.846,7.844,18.15,11.766,28.454,11.766
                s20.61-3.922,28.454-11.766
                l103.787-103.787c15.514,8.013,32.824,12.284,50.814,12.284
                c29.648,0,57.493-11.518,78.407-32.432
                c5.238-5.238,5.238-13.731,0-18.969l-37.939-37.939
                c-2.515-2.515-5.927-3.929-9.485-3.929
                c-3.557,0-6.97,1.414-9.485,3.929
                c-1.607,1.606-3.375,2.986-5.25,4.176
                c4.79-14.069,1.591-30.282-9.609-41.482
                c-11.2-11.199-27.413-14.398-41.482-9.608
                c1.188-1.875,2.57-3.644,4.176-5.25l75.562-75.562
                c1.388-1.388,2.883-2.602,4.451-3.663
                c-5.006,14.169-1.855,30.603,9.459,41.918
                c7.846,7.844,18.15,11.766,28.454,11.766
                c4.563,0,9.125-0.773,13.474-2.311
                c-1.073,1.58-2.294,3.074-3.673,4.453
                c-2.515,2.515-3.929,5.927-3.929,9.485
                c0,3.557,1.414,6.97,3.929,9.485l37.94,37.939
                c5.238,5.238,13.731,5.237,18.969,0
                c34.91-34.91,41.594-87.481,20.122-129.196L500.231,68.674
                C515.922,52.986,515.922,27.457,500.232,11.766z
                M253.147,346.729l19.333,19.333
                c-14.212,10.288-31.269,15.841-49.202,15.841
                c-10.638,0-20.967-1.957-30.576-5.695l21.785-21.785
                c2.883,0.444,5.819,0.677,8.793,0.677
                C233.992,355.101,244.251,352.185,253.147,346.729z
                M191.977,301.052c2.614-2.614,6.049-3.922,9.485-3.922
                c3.435,0,6.87,1.308,9.485,3.922c5.23,5.23,5.23,13.739,0,18.969
                L49.706,481.262c-5.229,5.23-13.739,5.23-18.969,0
                c-5.23-5.23-5.23-13.739,0-18.969L191.977,301.052z
                M249.202,172.692l-75.562,75.562
                c-10.796,10.796-16.743,25.168-16.743,40.468
                c0,2.974,0.233,5.909,0.677,8.792l-21.757,21.757
                c-11.711-30.131-5.435-65.699,18.851-89.987
                l75.563-75.562
                c16.387-16.387,37.912-24.58,59.438-24.58
                c10.378,0,20.752,1.913,30.552,5.726l-21.775,21.777
                C281.024,153.957,262.594,159.3,249.202,172.692z
                M367.005,271.526l-19.329-19.327
                c5.455-8.896,8.371-19.154,8.371-29.869
                c0-2.974-0.235-5.91-0.677-8.793l21.746-21.746
                C387.272,217.916,383.918,248.122,367.005,271.526z
                M481.263,49.705
                L320.021,210.947c-5.229,5.23-13.739,5.23-18.969,0
                s-5.23-13.741,0-18.971L462.292,30.737
                c5.231-5.23,13.741-5.231,18.971,0
                C486.493,35.967,486.493,44.476,481.263,49.705z
              `}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d={oneLine`
                M420.56,400.641l-43.63-43.63c-5.238-5.238-13.731-5.238-18.969,0
                c-5.238,5.238-5.239,13.731,0,18.969l43.63,43.63
                c2.62,2.62,6.052,3.929,9.485,3.929
                c3.432,0,6.866-1.31,9.485-3.929
                C425.798,414.373,425.799,405.879,420.56,400.641z
              `}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d={oneLine`
                M476.759,327.83l-66.513-9.604
                c-7.33-1.057-14.135,4.025-15.193,11.359
                c-1.058,7.332,4.027,14.134,11.358,15.193
                l66.513,9.604c0.651,0.094,1.296,0.14,1.934,0.14
                c6.559,0,12.295-4.817,13.259-11.498
                C489.175,335.691,484.09,328.889,476.759,327.83z
              `}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d={oneLine`
                M355.33,471.974l-9.604-66.512
                c-1.058-7.332-7.866-12.414-15.193-11.359
                c-7.332,1.06-12.417,7.86-11.359,15.193
                l9.604,66.512c0.964,6.681,6.7,11.498,13.259,11.498
                c0.638,0,1.285-0.046,1.934-0.139
                C351.304,486.108,356.389,479.307,355.33,471.974z
              `}
            />
          </g>
        </g>
      </>
    </svg>
  )
}
