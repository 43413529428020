import { useState, useEffect } from "react"
import { db } from "../../../firebase"


export default function useDocument (path: any) {
  const [loading, setLoading] = useState(true)
  const [documentData, setDocumentData] = useState(null)
  const [docSnapshot, setDocSnapshot] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!path) {
      return
    }

    const query = db.doc(path)
    setLoading(true)
    const unlisten = query.onSnapshot(docSnapshot => {
      if (docSnapshot.exists) {
        // @ts-expect-error ts-migrate(2345) Remove to see error
        setDocumentData(docSnapshot.data())
      }
      else {
        setDocumentData(null)
      }
      // @ts-expect-error ts-migrate(2345) Remove to see error
      setDocSnapshot(docSnapshot)
      setLoading(false)
    }, err => {
      // @ts-expect-error ts-migrate(2345) Remove to see error
      setError(err)
      setLoading(false)
    })
    return unlisten
  }, [path])

  if (!path) {
    return [null, null, false, null]
  }

  return [documentData, docSnapshot, loading, error]
}
