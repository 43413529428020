import { useState } from "react"
import firebase from "../../../firebase"

import useDocument from "./useDocument"
import useAuthUser from "./useAuthUser"


function updateUser (authUser: any, data = {}) {
  // set some user data
  if (authUser && authUser.uid) {
    return firebase.firestore().doc(`users/${authUser.uid}`).set({
      id: authUser.uid,
      createdAt: firebase.firestore.Timestamp
        .fromDate(new Date(authUser.metadata.creationTime)),
      lastSignInTime: firebase.firestore.Timestamp
        .fromDate(new Date(authUser.metadata.lastSignInTime)),
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      isAnonymous: authUser.isAnonymous,
      phoneNumber: authUser.phoneNumber,
      photoUrl: authUser.photoURL,
      ...data,
    }, { merge: true })
  }
}


function getUser (
  {
    data,
    authUser,
  }: any,
) {
  if (data === null) {
    return null
  }

  return {
    ...data,
    update: (updateFields: any) => updateUser(authUser, updateFields),
  }
}


export default function useUser () {
  const [authUser, authUserLoading] = useAuthUser()
  const [userId, setUserId] = useState(null)
  // now we have the authUser and its id we can fetch that users document
  const [userDocData, , docLoading, error] = useDocument(`users/${userId}`)

  const loading = authUserLoading || docLoading

  if (authUserLoading) {
    // wait until we get an authenticated user
    return [null, loading, null]
  }

  if (authUser === null) {
    // if there is no user (even anon) return a null document value
    return [null, loading, null]
  }

  // @ts-expect-error ts-migrate(2339) Remove to see error
  const { uid } = authUser
  if (userId !== uid) {
    setUserId(uid)
  }

  if (!userId) {
    // if there is no userId yet, lets not fetch to a broken path,
    // keep loading state
    return [null, loading, null]
  }
  return [getUser({ data: userDocData, authUser }), loading, error]
}
