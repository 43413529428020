import firebase from "../firebase"

function updateUserDoc (user: any, data = {}) {
  // set some user data
  if (user && user.uid) {
    return firebase.firestore().collection("users").doc(user.uid).set({
      id: user.uid,
      createdAt: firebase.firestore.Timestamp
        .fromDate(new Date(user.metadata.creationTime)),
      lastSignInTime: firebase.firestore.Timestamp
        .fromDate(new Date(user.metadata.lastSignInTime)),
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      phoneNumber: user.phoneNumber,
      photoUrl: user.photoURL,
      ...data,
    }, { merge: true })
  }
}

export default updateUserDoc
