import React, { useContext } from "react"
import Appbar from "../../components/Appbar"
import styled from "styled-components"
import media from "../../utils/media"
import Flex from "../../lib/src/components/Flex/Flex"
import { FlexContainer } from "../../components/Container"
import { H1, Body } from "../../lib/src/components/Typography"
import BrokenLinkIcon from "../../components/icons/BrokenLink"
import sendMagicLinkEmail from "../../helpers/sendMagicLinkEmail"
import { ClaimContext, UserContext } from "../../contexts"
import SubmitButton from "../../components/Button/SubmitButton"


const TopMargin = styled.div `
  ${(media as any).to(992) `
    margin-top: 64px;
  `}
  ${(media as any).from(993) `
    margin-top: 80px;
  `}
`


export default function LinkHasExpiredPage (props: any) {
  const email = window.localStorage.getItem("emailForSignIn")
  const claim = useContext(ClaimContext) as Claim
  const user = useContext(UserContext) as User

  function sendLinkNow () {
    if (!props.skipEmail) {
      sendMagicLinkEmail({
        email,
        template: "link-expired",
        claimId: claim && claim.id,
        userId: user && user.id,
      })
    }
  }

  return (
    <>
      <Appbar />
      <TopMargin />
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Flex center>
        <FlexContainer p={3} center style={{ maxWidth: 720 }}>
          {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
          <Flex fullWidth direction='ttb'>
            <H1>Neues Gerät autorisieren</H1>
            {/* @ts-expect-error */}
            <Body.Bold style={{ marginTop: 12 }}>
              Sie melden sich mit einem neuen Gerät oder Browser an.
            </Body.Bold>
            <FlexContainer center>
              <div style={{ width: "20%" }}><BrokenLinkIcon /></div>
            </FlexContainer>
            <Body>
              Zur Freischaltung Ihres Brwosers müssen Sie einen neuen
              Link aufrufen.
              Bitte klciken Sie auf den folgenden Button, um einen neuen
              Link anzufordern. Der Link wird Ihnen per E-Mail gesendet.
              Bitte rufen Sie diesen Link mit demselben Gerät ab
              um zu Ihrem Claim zurückzukehren.
              Dies ist ein Vorgang, der Ihr Konto schützt.
            </Body>
            <SubmitButton
              style={{ width: "94.5%" }} onClick={sendLinkNow}
            >Link anfordern
            </SubmitButton>
          </Flex>
        </FlexContainer>
      </Flex>
    </>
  )
}
