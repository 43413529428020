import React from "react"
import SelectField from "../SelectField"


const insuranceOptions = [
  {
    label: "ACE Automobilclub Europa",
    value: "876726014918",
  },
  {
    label: "ADAC-Rechtsschutz Versicherungs-Aktiengesellschaft",
    value: "876726342598",
  },
  {
    label: "ADVOCARD Rechtsschutzversicherung AG",
    value: "876726408134",
  },
  {
    label: "Allianz Rechtsschutz-Service GmbH",
    value: "876726473670",
  },
  {
    label: "ALLRECHT Rechtsschutzversicherung AG",
    value: "876726539206",
  },
  {
    label: "ALTE LEIPZIGER Versicherung AG",
    value: "876725687238",
  },
  {
    label: "ARAG Allgemeine Rechtsschutz-Versicherungs-AG (ARAG SE)",
    value: "876726604742",
  },
  {
    label: "Automobilclub von Deutschland e. V.",
    value: "876724573126",
  },
  {
    label: "AUXILIA Rechtschutz-Versicherungs-AG",
    value: "876726670278",
  },
  {
    label: "BavariaDirekt",
    value: "876724835270",
  },
  {
    label: "BGV - Badische Rechtsschutzversicherung AG",
    value: "876726735814",
  },
  {
    label: "Bruderhilfe Sachversicherung AG im Raum der Kirchen",
    value: "876726801350",
  },
  {
    label: "Bund der Versicherten e. V.",
    value: "876724442054",
  },
  {
    label: "Concordia Rechtsschutz-Leistungs-GmbH",
    value: "876726866886",
  },
  {
    label: "Continentale Sachversicherung Aktiengesellschaft",
    value: "876726932422",
  },
  {
    label: "Coop Rechtschutz",
    value: "876725097414",
  },
  {
    label: "CosmosDirekt Schadenservice",
    value: "1112063300550",
  },
  {
    label: "D.A.S. Rechtsschutz Leistungs-GmbH",
    value: "876726997958",
  },
  {
    label: "DA Deutsche Allgemeine Versicherung Aktiengesellschaft",
    value: "876727063494",
  },
  {
    label: "Debeka Rechtsschutz-Schadenabwicklung GmbH",
    value: "876727129030",
  },
  {
    label: "DEURAG Deutsche Rechtsschutz-Versicherung AG",
    value: "876727194566",
  },
  {
    label: "DEVK Rechtsschutz-Versicherungs-AG",
    value: "876727260102",
  },
  {
    label: "DFV Deutsche Familienversicherung AG",
    value: "876727325638",
  },
  {
    label: "die Bayerische",
    value: "876725031878",
  },
  {
    label: "Direct Line Versicherung AG",
    value: "876727391174",
  },
  {
    label: "DMB Rechtsschutz-Versicherung AG",
    value: "876727456710",
  },
  {
    label: "DOMCURA AG",
    value: "876725556166",
  },
  {
    label: "ERGO Versicherung AG",
    value: "876725294022",
  },
  {
    label: "Generali Versicherung AG",
    value: "28136228017094",
  },
  {
    label: "Gothaer Allgemeine Versicherung AG",
    value: "876724704198",
  },
  {
    label: "Grazer Wechselseitige Versicherung Aktiengesellschaft",
    value: "23864416228294",
  },
  {
    label: "GUV/FAKULTA",
    value: "876724507590",
  },
  {
    label: "GVO Gegenseitigkeit Versicherung Oldenburg VVaG",
    value: "876724376518",
  },
  {
    label: "HDI Versicherung AG",
    value: "876727522246",
  },
  {
    label: "Helvetia Schweizerische Versicherungsgesellschaft AG",
    value: "876724310982",
  },
  {
    label: "HUK-COBURG-Rechtsschutzversicherung AG",
    value: "876727587782",
  },
  {
    label: "HUK24 AG",
    value: "876727653318",
  },
  {
    label: "IDEAL Versicherung AG",
    value: "876725949382",
  },
  {
    label: "Itzehoer Rechtsschutz Union",
    value: "12362181006278",
  },
  {
    label: "Janitos Versicherung AG",
    value: "876725425094",
  },
  {
    label: "Jurpartner Rechtsschutz-Versicherung AG",
    value: "876727784390",
  },
  {
    label: "KRAFTFAHRER-SCHUTZ e.V.",
    value: "876725883846",
  },
  {
    label: "Landesschadenhilfe Versicherung VaG (LSH)",
    value: "876724245446",
  },
  {
    label: "LVM Rechtsschutz-Service GmbH",
    value: "876727849926",
  },
  {
    label: "Mannheimer Versicherung AG",
    value: "876727915462",
  },
  {
    label: "Mecklenburgische Versicherungsgruppe",
    value: "876727980998",
  },
  {
    label: "Medien-Versicherung a.G.",
    value: "876725818310",
  },
  {
    label: "Neue Rechtsschutz-Versicherungsgesellschaft AG",
    value: "876728046534",
  },
  {
    label: "Nürnberger Versicherung (Rechtsschutz)",
    value: "876725162950",
  },
  {
    label: "ÖRAG Rechtsschutzversicherungs-AG",
    value: "876728112070",
  },
  {
    label: "Orion Rechtsschutz-Versicherung AG",
    value: "1717020021702",
  },
  {
    label: "Protekta Rechtsschutzversicherung",
    value: "876724179910",
  },
  {
    label: "Provinzial Rheinland Versicherung AG",
    value: "876725359558",
  },
  {
    label: "R+V Rechtsschutz-Schadenregulierungs-GmbH",
    value: "876728177606",
  },
  {
    label: "RECHTSSCHUTZ UNION Schaden GmbH",
    value: "876728243142",
  },
  {
    label: "ROLAND Rechtsschutz-Versicherungs-AG",
    value: "876726277062",
  },
  {
    label: "TECHNO Versicherungsdienst GmbH",
    value: "876725752774",
  },
  {
    label: "Versicherungskammer Bayern",
    value: "876724638662",
  },
  {
    label: "VGH Versicherungen",
    value: "876726211526",
  },
  {
    label: "VHV Allgemeine Versicherung AG",
    value: "876725621702",
  },
  {
    label: "Westfälische Provinzial Versicherung Aktiengesellschaft",
    value: "876724966342",
  },
  {
    label: "WGV-Württembergische Gemeinde-Versicherung a.G.",
    value: "876726145990",
  },
  {
    label: "Wiener Städtische Versicherung",
    value: "876724900806",
  },
  {
    label: "Württembergische Rechtsschutz Schaden-Service-GmbH",
    value: "876725490630",
  },
  {
    label: "Zurich Rechtsschutz-Schadenservice GmbH",
    value: "876726080454",
  },
]


export default function InsuranceSelectField (
  {
    form,
    field,
    ...props
  }: any,
) {
  return (
    <SelectField
      options={insuranceOptions}
      {...field}
      {...props}
    />
  )
}
