import Flex from "../../lib/src/components/Flex"
import SelectButton from "../YesNoUnsureInput/SelectButton"


export default function YesNoButtons (
  {
    value,
    onChange,
  }: any = {},
) {
  return (
    <Flex>
      <SelectButton
        style={{ width: "100%" }}
        onClick={() =>
          onChange && onChange(typeof value === "string" ? "yes" : true)
        }
        active={value === "yes" || value === true}
      >
        Ja
      </SelectButton>
      <SelectButton
        style={{ width: "100%" }}
        onClick={() =>
          onChange && onChange(typeof value === "string" ? "no" : false)
        }
        active={value === "no" || value === false}
      >
        Nein
      </SelectButton>
    </Flex>
  )
}
