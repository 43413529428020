import React, { memo } from "react"
import { oneLine } from "common-tags"

export default memo(() => (
  <svg width='14' height='14'>
    <g fill='#FFF' fillRule='nonzero'>
      <path
        d={oneLine`
          M13 .029a.686.686 0 0 0-.391 0L.462 4.01A.66.66 0 1 0 .32 5.206
          L4.882 8 13 .029zM6 9.117l2.794 4.566A.661.661 0 0 0 9.36 14h.08
          a.666.666 0 0 0 .55-.452l3.982-12.156a.687.687 0 0 0 0-.392L6 9.117
          z
        `}
      />
    </g>
  </svg>
))
