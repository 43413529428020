import { css } from "styled-components"
import { BREAKPOINTS } from "../lib/src/constants"

// use px in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units

function minCSS (px: any, ...args: any[]) {
  return css`
    @media (min-width: ${px}px) {
      ${
  // @ts-expect-error
  css(...args)
}
    }
  `
}


function maxCss (px: any, ...args: any[]) {
  return css`
    @media (max-width: ${px}px) {
      ${
  // @ts-expect-error
  css(...args)
}
    }
  `
}


function betweenCss (min: any, max: any, ...args: any[]) {
  return css`
    @media (min-width: ${min}px) and (max-width: ${max}px) {
      ${
  // @ts-expect-error
  css(...args)
}
    }
  `
}


function from (fromPx: any) {
  function fromFn (...args: any[]) {
    return minCSS(fromPx, ...args)
  }

  fromFn.to = (toPx: any) => (...css: any[]) =>
    betweenCss(fromPx, toPx, ...css)

  return fromFn
}


function to (px: any) {
  return (...args: any[]) => maxCss(px, ...args)
}


Object
  .keys(BREAKPOINTS)
  // @ts-expect-error
  .forEach((key: BreakpointSize) => {
    const { min, max } = BREAKPOINTS[key]

    function fromFn (...args: any[]) {
      return minCSS(min, ...args)
    }

    fromFn.to = {}
    Object
      .keys(BREAKPOINTS)
      // @ts-expect-error
      .forEach((toKey: BreakpointSize) => {
        // @ts-expect-error ts-migrate(7053)
        // FIXME: Element implicitly has an 'any'
        fromFn.to[toKey] = (...css: any[]) =>
          betweenCss(min, BREAKPOINTS[toKey].max, ...css)
      })
    // @ts-expect-error
    from[key] = fromFn
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any'
    to[key] = (...args: any[]) => maxCss(max, ...args)
  })

// iterate through the sizes and create a media template
const media: Record<string, any> = ["above", "below", "only"]
  .reduce(
    (media, key) => {
      // @ts-expect-error ts-migrate(7053)
      // FIXME: Element implicitly has an 'any'
      media[key] = Object
        .keys(BREAKPOINTS)
        .reduce(
          // @ts-expect-error
          (accumulator: Record<BreakpointSize, any>, label: BreakpointSize) => {
            const { min, max } = BREAKPOINTS[label]
            switch (key) {
              case "above":
                accumulator[label] = (...args: any[]) =>
                  minCSS(max + 1, ...args)
                return accumulator

              case "only":
                accumulator[label] = (...args: any[]) =>
                  betweenCss(min, max, ...args)
                return accumulator

              case "below":
                accumulator[label] = (...args: any[]) =>
                  maxCss(min - 1, ...args)
                return accumulator

              default:
                return accumulator
            }
          },
          {},
        )

      return media
    },
    {},
  )

media.from = from
media.to = to

export default media
