import { css, createGlobalStyle, keyframes } from "styled-components"

const reset = css`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
`

const shake = keyframes`
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
`

const line = keyframes`
0% {
    stroke-dashoffset: 22;
  }
  
  25% {
    stroke-dashoffset: 22;
  }
  
  50% {
    stroke-dashoffset: 0;
  }
  
  51% {
    stroke-dashoffset: 0;
  }
  
  80% {
    stroke-dashoffset: -22;
  }
  
  100% {
    stroke-dashoffset: -22;
  }
`

const animation = css`
.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car {
  &__body {
    animation: ${shake} 0.2s ease-in-out infinite alternate;
  }
  
  &__line {
    transform-origin: center right;
    stroke-dasharray: 22;
    animation: ${line} 0.8s ease-in-out infinite;
    animation-fill-mode: both;
    
    &--top {
      animation-delay: 0s;
    }
    
    &--middle {
      animation-delay: 0.2s;
    }
    
    &--bottom {
      animation-delay: 0.4s;
    }
  }
}
}
`

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${animation}
  body {
    font-family: Heebo, system, sans-serif;
    font-size: 14px;
    background: #3828b00d;
  }
`

export default GlobalStyle
