import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import routes from "../../routes"
import FormContainer from "../../containers/FormContainer"
import ApplicationReview from "../../components/ApplicationReview"
import { ClaimContext } from "../../contexts"


function ApplicationReviewPage (
  {
    history,
    isTest,
  }: any,
) {
  const claim = useContext(ClaimContext) as Claim

  return (
    <FormContainer isTest={isTest} >
      {(props: any) => <ApplicationReview
        onApprove={async () => {
          await claim.update({
            applicationApproved: true,
          })
          history.push(routes.APPLICATION_SUCCESS)
        }} {...props}
      />}
    </FormContainer>
  )
}


export default withRouter(ApplicationReviewPage)
