import React from "react"
import { every, isArray } from "lodash"
import FullScreenLoader from "../FullScreenLoader"


export default function Wait (
  {
    on,
    children = null,
    fallback = null,
  }: any,
) {
  if (!isArray(on)) {
    on = [on]
  }

  // all values of 'on' should be resolved
  // and then Wait will render its children
  if (every(on)) {
    return children
  }

  if (fallback) {
    return fallback
  }

  return (
    <FullScreenLoader />
  )
}
