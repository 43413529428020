import React from "react"
import styled from "styled-components"
import { RadioGroup } from "@material-ui/core"
import { Flex } from "../../lib"
import SelectButton from "./SelectButton"
import InfoBox from "../InfoBox"
import media from "../../utils/media"

const OPTS = {
  YES: "yes",
  NO: "no",
  DONTKNOW: "dont-know",
}

const FlexButton = styled(SelectButton) `
  ${(media as any).below.sm `
    flex-grow: 1;
  `}
`


export default function YesNoUnsureButtons (
  {
    label,
    tooltip,

    // { name, value, onChange, onBlur }
    field,

    // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    form,

    choices = ["Ja", "Nein", "Weiß nicht"],
    values = [OPTS.YES, OPTS.NO, OPTS.DONTKNOW],
    infoBoxContent,
    ...props
  }: any,
) {
  const hasValue = field.value !== ""
  const isYes = field.value === values[0]
  const isNo = field.value === values[1]
  const isDontKnow = field.value === values[2]

  return (
    <RadioGroup {...field} {...props}>
      <Flex>
        <FlexButton
          inactive={hasValue && !isYes}
          name={`${field.name}-yes`}
          active={isYes}
          onBlur={field.onBlur}
          onClick={() => {
            form.setFieldValue(field.name, values[0])
          }}
        >
          {choices[0]}
        </FlexButton>
        <FlexButton
          inactive={hasValue && !isNo}
          name={`${field.name}-no`}
          active={isNo}
          onBlur={field.onBlur}
          onClick={() => form.setFieldValue(field.name, values[1])}
        >
          {choices[1]}
        </FlexButton>
        <FlexButton
          inactive={hasValue && !isDontKnow}
          name={`${field.name}-dont-know`}
          active={isDontKnow}
          onBlur={field.onBlur}
          onClick={() => form.setFieldValue(field.name, values[2])}
        >
          {choices[2]}
        </FlexButton>
      </Flex>
      {isYes &&
        infoBoxContent &&
        infoBoxContent[0] &&
        <InfoBox>{infoBoxContent[0]}</InfoBox>
      }
      {isNo &&
        infoBoxContent &&
        infoBoxContent[1] &&
        <InfoBox>{infoBoxContent[1]}</InfoBox>
      }
      {isDontKnow &&
        infoBoxContent &&
        infoBoxContent[2] &&
        <InfoBox>{infoBoxContent[2]}</InfoBox>
      }
    </RadioGroup>
  )
}
