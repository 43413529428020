import React from "react"
import { oneLine } from "common-tags"

export default function Checkmark (props: any) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
      <g fill='none'>
        <path
          fill='#000'
          d={oneLine`
            M8 0a8 8 0 100 16A8 8 0 008 0zm0 15.111A7.111 7.111 0 118 .89
            a7.111 7.111 0 010 14.22z
          `}
        />
        <path
          fill='#FFF'
          d={oneLine`
            M11.653 4.834a.713.713 0 00-.998.127l-3.48 4.504-1.829-2.4
            a.713.713 0 00-.996-.135.713.713 0 00-.135.997l2.37 3.11a.71.71 0
            00.667.27.707.707 0 00.507-.27L11.78 5.83a.713.713 0 00-.128-.997z
          `}
        />
        <path
          fill='#2460D8'
          d={oneLine`
            M8 0a8 8 0 100 16A8 8 0 008 0zm3.78 5.831l-4.02 5.205
            a.707.707 0 01-.507.27.71.71 0 01-.666-.268l-2.37-3.11
            a.713.713 0 01.134-.998.713.713 0 01.996.135l1.83 2.4 3.48-4.503
            a.713.713 0 01.997-.128c.31.24.367.688.128.997z
          `}
        />
      </g>
    </svg>
  )
}
