import { findIndex, get } from "lodash"
import React, { useContext, useState } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { useTransition } from "@react-spring/web"
import Wait from "../../components/Wait"
import { ClaimContext } from "../../contexts"
import AuthUserContext from "../../contexts/authUser.context"
import usePrevious from "../../hooks/usePrevious"
import routes from "../../routes"
import PageLayout from "./PageLayout"
import {
  registrationRoutes,
  kapHasInsuranceRoute,
  kapInsuranceSelectRoute,
} from "./registrationRoutes"
import NotEligibleModal from "./NotEligilibleModal"

const slideRightFrom = { opacity: 0, transform: "translate3d(-50%,0,0)" }
const slideRightLeave = { opacity: 0, transform: "translate3d(100%,0,0)" }
const slideLeftFrom = { opacity: 0, transform: "translate3d(100%,0,0)" }
const slideLeftLeave = { opacity: 0, transform: "translate3d(-50%,0,0)" }


function BeginningRedirect (
  {
    location,
    claim,
  }: any,
) {
  const route = `${registrationRoutes[0].route}${location.search || ""}`
  return <Redirect to={route} />
}


export default function RegistrationPage (
  {
    skipWait,
    dummyLocation,
  }: any,
) {
  let location = useLocation()
  // if executed in Storybook
  if (location.pathname === "/iframe.html") {
    location = dummyLocation
  }

  const authUser = useContext(AuthUserContext) || {}
  const claim = useContext(ClaimContext) as Claim
  const [showNotEligibleModal, setShowNotEligibleModal] = useState(false)
  const [modalProps, setModalProps] = useState({})

  const previousPath = usePrevious(location.pathname)
  const prevIndex = findIndex(
    registrationRoutes,
    (p: any) => p.route === previousPath,
  )
  const currentIndex = findIndex(
    registrationRoutes,
    (p: any) => p.route === location.pathname,
  )
  const transitions = useTransition(
    location,
    {
      from: () => prevIndex > currentIndex ? slideRightFrom : slideLeftFrom,
      enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
      leave: () => prevIndex > currentIndex ? slideRightLeave : slideLeftLeave,
      config: { duration: 400 },
    },
  )

  const currentRoute = registrationRoutes[currentIndex] || {}

  return transitions((props, item) => (
    <Wait on={skipWait || [authUser, (claim && claim.id)]}>
      <PageLayout
        animationProps={props}
        Header={currentRoute.Header}
        progress={(currentIndex + 1) / registrationRoutes.length * 100}
      >
        <Switch location={item}>
          <Route
            exact
            path={routes.REGISTRATION}
            render={() =>
              <BeginningRedirect location={location} claim={claim} />
            }
          />
          <Route
            exact
            path={routes.REGISTRATION_KAP_HAS_INSURANCE}
            render={() => (
              <Route
                key={kapHasInsuranceRoute.route}
                exact
                path={kapHasInsuranceRoute.route}
                render={() => (
                  <kapHasInsuranceRoute.component
                    openNotEligibleModal={(props: any) => {
                      setModalProps(props)
                      setShowNotEligibleModal(true)
                    }}
                    nextPage={routes.REGISTRATION_KAP_INSURANCE_SELECT}
                    prevPage={routes.REGISTRATION_BRAND_MODEL_YEAR}
                  />)}
              />
            )}
          />
          <Route
            exact
            path={routes.REGISTRATION_KAP_INSURANCE_SELECT}
            render={() => (
              <Route
                key={kapInsuranceSelectRoute.route}
                exact
                path={kapInsuranceSelectRoute.route}
                render={() => (
                  <kapInsuranceSelectRoute.component
                    openNotEligibleModal={(props: any) => {
                      setModalProps(props)
                      setShowNotEligibleModal(true)
                    }}
                    nextPage={routes.REGISTRATION_PURCHASE_YEAR}
                    prevPage={routes.REGISTRATION_KAP_HAS_INSURANCE}
                  />)}
              />
            )}
          />
          {registrationRoutes.map((page, index) => {
            const nextPage = get(
              registrationRoutes,
              `[${index + 1}].route`,
              routes.APPLICATION_CAR_INFO,
            ) + (location.search || "")

            const prevPage = get(
              registrationRoutes,
              `[${index - 1}].route`,
              null,
            )

            return (
              <Route
                key={page.route}
                exact
                path={page.route}
                render={() => (
                  <page.component
                    openNotEligibleModal={(props: any) => {
                      setModalProps(props)
                      setShowNotEligibleModal(true)
                    }}
                    nextPage={nextPage}
                    prevPage={prevPage}
                  />)}
              />
            )
          })}
        </Switch>
      </PageLayout>
      <NotEligibleModal
        open={showNotEligibleModal}
        close={() => {
          setShowNotEligibleModal(false) 
        }}
        {...modalProps}
      />
    </Wait>
  ))
}
