import styled from "styled-components"
import media from "../../utils/media"
export default styled.h3 `
  font-family: grad, serif;
  font-style: normal;
  font-weight: bold;
  color: ${(props: any) => props.light ? "#e2e9fb" : "#161466"};
  text-align: ${(props: any) => props.center ? "center" : "inherit"}
  ${(media as any).only.xs `
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  `}
  ${(media as any).only.sm `
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  `}
  ${(media as any).only.md `
    font-size: 40px;
    line-height: normal;
    letter-spacing: 0.8px;
  `}
  ${(media as any).only.lg `
    font-size: 40px;
    line-height: normal;
    letter-spacing: 0.8px;
  `}
  ${(media as any).only.xl `
    font-size: 48px;
    line-height: normal;
    letter-spacing: 0.8px;
  `}
`

