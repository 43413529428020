import React from "react"
import Appbar from "../Appbar"
import { FlexContainer } from "../Container"
import MailIcon from "../MailIcon"
import PageContainer from "../PageContainer"
import Show from "../Show"
import SupportButton from "../SupportButton"


export default function FormPageLayout (
  {
    children,
    claimId,
  }: any,
) {
  return (
    <>
      <PageContainer>
        <Appbar>
          <SupportButton claimId={claimId}>
            <MailIcon />
            {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
            <Show above='sm'>&nbsp;&nbsp;Support</Show>
          </SupportButton>
        </Appbar>
        <FlexContainer center>
          {children}
        </FlexContainer>
      </PageContainer>
    </>
  )
}
