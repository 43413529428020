import styled from "styled-components"
import media from "../../utils/media"
export default styled.h2 `
  font-family: Grad;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: ${(props: any) => props.light ? "#e2e9fb" : "#161466"};
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: ${(props: any) => props.center ? "center" : "inherit"}
  ${(media as any).only.xs `
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.2px;
  `}
  ${(media as any).only.sm `
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.2px;
  `}
  ${(media as any).only.md `
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.2px;
  `}
  ${(media as any).only.lg `
    font-size: 48px;
    line-height: 56px
    letter-spacing: 0.5px;
  `}
  ${(media as any).only.xl `
    font-size: 56px;
    line-height: 64px;
    letter-spacing: 0.5px;
  `}
`

