import React from "react"
import styled from "styled-components"
import Container from "../Container"
import { TextField as MUITextField, makeStyles } from "@material-ui/core"


const Field = styled(MUITextField)`
`

const useStyles = makeStyles(theme => ({
  textField: {
    background: "#fff",
    borderRadius: 4,
  },
}))


// TODO: Verify that colors are correct
export default function TextField ({ ...props }) {
  const classes = useStyles()
  return (
    // @ts-expect-error
    <Container p={0} mt={0.5}>
      <Field
        className={classes.textField}
        variant='outlined'
        {...props}
      />
    </Container>
  )
}
