import Button from "@material-ui/core/Button"
import { get } from "lodash"
import React, { memo, useContext } from "react"
import styled from "styled-components"
import { ClaimContext } from "../contexts"
import media from "../utils/media"
import MailIcon from "./MailIcon"
import Show from "./Show"


const StyledButton = styled(Button) `
  && {
    font-family: Heebo;
    text-transform: initial;
    border-radius: 8px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    padding: 0 30px;
    box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
    color: white;
    background: ${(props: any) => props.disabled ? "#b9b8d1" : "#2460d8"};
    &:hover {
      background: ${(props: any) => props.disabled ? "#b9b8d1" : "#161466"};
    }
    text-decoration: none;

    ${(media as any).only.xs `
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    `}
  }
`


function SupportButton ({ ...props }) {
  const claim = useContext(ClaimContext) as Claim
  const claimId = get(claim, "id")
  const href = claimId
    ? `mailto:support@claimback.org?subject=${claimId}`
    : "mailto:support@claimback.org"
  return (
    <a href={href} target='__blank'>
      <StyledButton
        variant='contained'
        color='primary'
        {...props}
      >
        <MailIcon />
        {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
        <Show above='sm'>&nbsp;&nbsp;Support</Show>
      </StyledButton>
    </a>
  )
}


export default memo(SupportButton)
