import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import firebase from "../../../firebase"
import track from "./track"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import FacebookLogo from "../../../assets/facebook-logo.svg"
import GoogleLogo from "../../../assets/google-logo.svg"
import { PALE_GREY, VERY_LIGHT_BLUE } from "../../../colours"
import OAuthButton from "../../../components/Button/OAuthButton"
import Container, { FlexContainer } from "../../../components/Container"
import FieldError from "../../../components/FieldError"
import PhoneInput from "../../../components/PhoneInput/PhoneInput"
import TextField from "../../../components/TextField"
import { Body, FormHelper } from "../../../lib/src/components/Typography"
import Wait from "../../../components/Wait"
import { ClaimContext } from "../../../contexts"
import AuthUserContext from "../../../contexts/authUser.context"
import UserContext from "../../../contexts/user.context"
import sendMagicLinkEmail from "../../../helpers/sendMagicLinkEmail"
import updateUserDoc from "../../../helpers/updateUserDoc"
import routes from "../../../routes"
import PaperPlaneIcon from "../../../components/PaperPlaneIcon"
import MissingEmailDialog from "./MissingEmailDialog"
import { oneLine } from "common-tags"


const Divider = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  height: 1px;
  width: 100%;
  background-color: ${VERY_LIGHT_BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
`

const DividerLabel = styled(Body.Small)`
  font-style: italic;
  font-size: 12px !important;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${PALE_GREY};
`

const StyledButton = styled(Button)`
  && {
    font-family: Heebo;
    text-transform: initial;
    border-radius: 8px;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    padding: 0 15px;
    box-shadow: 0 1px 5px 0 rgba(22, 20, 102, 0.15);
    color: white;
    background: ${(props: any) => props.disabled ? "#b9b8d1" : "#2460d8"};
    &:hover {
      background: ${(props: any) => props.disabled ? "#b9b8d1" : "#161466"};
    }
  }
`


function SubmitButton (
  {
    icon = <><PaperPlaneIcon />&nbsp;&nbsp;</>,
    children,
    ...props
  }: any,
) {
  return (
    <StyledButton
      variant='contained'
      color='primary'
      style={{ marginTop: 20 }}
      {...props}
    >
      {icon}
      {children}
    </StyledButton>
  )
}


function getEmailInUseErrorMessage (
  {
    user,
    claim,
    email,
  }: any,
) {
  return (
    <span>
      Diese E-Mail wird bereits verwendet.
      Um fortzufahren, überprüfen Sie bitte Ihre E-Mails
      und klicken Sie auf den angegebenen Link, um fortzufahren.
      Wenn Sie die E-Mail nicht erhalten haben,
      klicken Sie bitte &nbsp;
      <button
        style={{ cursor: "pointer" }}
        onClick={() =>
          sendMagicLinkEmail({
            email,
            userId: user && user.id,
            claimId: claim && claim.id,
          })}
      >hier</button>
    </span>
  )
}


export default function SignUpPage (
  {
    nextPage,
  }: any,
) {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [fbEmailErr, setFbEmailErr] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const authUser = useContext(AuthUserContext) as Record<string, any>
  const user = useContext(UserContext) as User
  const claim = useContext(ClaimContext) as Claim
  const history = useHistory()

  // eslint-disable-next-line
  firebase.auth().getRedirectResult()
    .then(async (cred) => {
      console.info("Signing in via redirect result")
      if (cred.user) {
        if (cred.user.email) {
          sendMagicLinkEmail({
            email: cred.user.email,
            template: "registration",
            userId: cred.user.uid,
            claimId: claim && claim.id,
          })
          await updateUserDoc(cred.user, { hasCompletedRegistration: true })
          await claim.update({
            phoneNumber: window.localStorage
              .getItem("SignInPhoneNumber") || null,
          })
          track(user, claim)
          history.push(nextPage)
        }
        else {
          await updateUserDoc({ ...cred.user, email: null })
          return setDialogOpen(true)
        }
      }
    })
    .catch(async (err) => {
      if (
        (err.code === "auth/credential-already-in-use") ||
        (err.code === "auth/email-already-in-use")
      ) {
        sendMagicLinkEmail({
          email: err.email,
          userId: user && user.id,
          claimId: claim && claim.id,
        })
        // @ts-expect-error ts-migrate(2345)
        // FIXME: Argument of type 'Element' is not assignable
        setFbEmailErr(getEmailInUseErrorMessage({
          email: err.email,
          user, claim,
        }))
      }
      else {
        throw err
      }
    })

  function handeEmailChange (e: any) {
    setEmail(e.target.value)
    if (emailError) {
      setEmailError("")
    }
  }

  function handlePhoneChange (value: any) {
    setPhoneNumber(value)
    if (value) {
      setPhoneNumberError("")
    }
  }

  async function handleSubmitEmail () {
    if (!phoneNumber) {
      return setPhoneNumberError(
        "Diese Angabe wird benötigt.",
      )
    }
    if (phoneNumber.length < 11) {
      return setPhoneNumberError(
        "Diese Telefonnummer scheint nicht gültig zu sein.",
      )
    }
    if (email.indexOf("ı") > -1) {
      return setEmailError(
        oneLine`
          Die von Ihnen angegebene E-Mail-Adresse enthält ein Symbol
          oder Zeichen, das nicht mit unserem E-Mail-System kompatibel ist.
          Bitte benutzen Sie eine andere E-Mail-Adresse.
        `,
      )
    }

    const emailRegex = /\S+@\S+\.\S+/

    if (emailRegex.test(email)) {
      try {
        setSubmitting(true)
        await authUser.updateEmail(email)
        sendMagicLinkEmail({
          email,
          template: "registration",
          userId: user && user.id,
          claimId: claim && claim.id,
        })
        await user.update({ hasCompletedRegistration: true })
        await claim.update({
          phoneNumber,
          lead: false,
        })

        track(user, claim)
        history.push(nextPage)
      }
      catch (error) {
        if (typeof error === "object" && error && "code" in error) {
          if (error.code === "auth/email-already-in-use") {
            // @ts-expect-error ts-migrate(2345)
            // FIXME: Argument of type 'Element' is not assignable
            setEmailError(getEmailInUseErrorMessage({ email, user, claim }))
            sendMagicLinkEmail({
              email,
              userId: user && user.id,
              claimId: claim && claim.id,
            })
            setSubmitting(false)
          }
          else if (error.code === "auth/invalid-email") {
            setEmailError("Diese E-Mail-Adresse scheint nicht gültig zu sein.")
            setSubmitting(false)
          }
        }
        else {
          history?.push(routes.ERROR)
          setSubmitting(false)
          throw error
        }
      }
    }
    else {
      setEmailError("Diese E-Mail-Adresse scheint nicht gültig zu sein.")
    }
  }

  async function handleFacebookSignIn () {
    if (!phoneNumber) {
      return setPhoneNumberError("Diese Angabe wird benötigt.")
    }
    if (phoneNumber.length < 11) {
      return setPhoneNumberError(
        "Diese Telefonnummer scheint nicht gültig zu sein.",
      )
    }

    window.localStorage.setItem("SignInPhoneNumber", phoneNumber)

    // eslint-disable-next-line
    const provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope("email")
    console.info("Signing in with facebook")
    // eslint-disable-next-line
    firebase.auth()
      ?.currentUser
      ?.linkWithRedirect(provider)
  }

  async function handleGoogleSignIn () {
    if (!phoneNumber) {
      return setPhoneNumberError("Diese Angabe wird benötigt.")
    }
    if (phoneNumber.length < 11) {
      return setPhoneNumberError(
        "Diese Telefonnummer scheint nicht gültig zu sein.",
      )
    }

    window.localStorage.setItem("SignInPhoneNumber", phoneNumber)

    // eslint-disable-next-line
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope("email")
    console.info("Signing in with google")

    // eslint-disable-next-line
    firebase.auth()
      ?.currentUser
      ?.linkWithRedirect(provider)
  }

  function onKeyDownEmailField (e: any) {
    if (e.keyCode === 13) {
      handleSubmitEmail()
    }
  }

  return (
    <>
      <Wait on={!submitting}>
        <Container style={{ maxWidth: 600 }}>

          <MissingEmailDialog nextPage={nextPage} open={dialogOpen} />
          <FlexContainer direction='ttb' style={{ backgroundColor: PALE_GREY }}>
            {/* @ts-expect-error */}
            <Body.Bold>Telefonnummer</Body.Bold>
            <PhoneInput
              style={{marginTop: 16}}
              value={phoneNumber} onChange={handlePhoneChange} />
            { phoneNumberError &&
              // @ts-expect-error
              <FieldError>{phoneNumberError}</FieldError>
            }
            <Divider />
            {/* @ts-expect-error */}
            <Body.Bold>Ihre E-Mail-Adresse</Body.Bold>
            <TextField
              placeholder='E-Mail-Adresse'
              value={email}
              fullWidth
              onChange={handeEmailChange}
              onKeyDown={onKeyDownEmailField}
            />
            { emailError &&
              // @ts-expect-error
              <FieldError>{emailError}</FieldError>
            }
            <SubmitButton onClick={handleSubmitEmail}>
              Jetzt Anspruch einfordern!
            </SubmitButton>
            <Divider>
              {/* @ts-expect-error */}
              <DividerLabel>oder Login mit</DividerLabel>
            </Divider>
            <Grid container>
              <Grid item xs={12} md={6}>
                {/* @ts-expect-error */}
                <Container p={0.25}>
                  <OAuthButton fullWidth onClick={handleFacebookSignIn}>
                    <img
                      height='100%'
                      alt='facebook-logo'
                      src={FacebookLogo}
                    />&nbsp;&nbsp;
                    Facebook
                  </OAuthButton>
                </Container>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* @ts-expect-error */}
                <Container p={0.25}>
                  <OAuthButton fullWidth onClick={handleGoogleSignIn}>
                    <img
                      width='25px'
                      height='25px'
                      alt='google-logo'
                      src={GoogleLogo}
                    />&nbsp;&nbsp;
                    Google
                  </OAuthButton>
                </Container>
              </Grid>
            </Grid>
            <FormHelper style={{ marginTop: 12, fontSize: 8 }}>
              { claim.lawFirm === "BRC" &&
                <>
                  Ich habe die{" "}
                  <a href='/datenschutz'>Datenschutzerklärung</a>{" "}
                  und die{" "}
                  <a href='/assets/nutzungsbedingungen_brc'>
                    Nutzungsbedingungen
                  </a>{" "}
                  von Claimback zur Kenntnis genommen
                  und bin damit einverstanden,
                  dass Sie mich zwecks Durchsetzung
                  meiner Schadensersatzansprüche kontaktieren
                </>
              }
              { claim.lawFirm !== "BRC" &&
                <>
                  Ich habe die{" "}
                  <a href='/datenschutz'>Datenschutzbestimmungen</a>{" "}
                  zur Kenntnis genommen und bin damit einverstanden,
                  dass Sie mich zwecks Durchsetzung
                  meiner Schadenersatzansprüche kontaktieren.
                </>
              }
            </FormHelper>
            { fbEmailErr &&
              // @ts-expect-error
              <FieldError>{fbEmailErr}</FieldError>
            }
          </FlexContainer>
        </Container>
      </Wait>
    </>
  )
}
