import React from "react"
import { oneLine } from "common-tags"

export default function LastMileage (props: any) {
  return <svg width='1em' height='1em' viewBox='0 0 83 51' {...props}>
    <g fill='none'>
      <path
        fill='#161466'
        d={oneLine`
          M75.3 26.1
          c-6-3.9-13.1-6.2-20.2-7-7.2-.8-14.5.1-21.4 2.1-6.9
          2-13.5 5.4-19.1 9.9-2.8 2.3-5.4 4.8-7.7 7.6
          s-4.2 5.9-5.8 9.2
          c-.4.9-.1 1.9.8 2.3.9.4 1.9.1 2.4-.8 1.5-3
          3.4-5.8 5.5-8.4 2.1-2.6 4.6-5 7.2-7.1 5.3-4.2 11.4-7.4 17.8-9.5
          6.5-2.1 13.3-3 20.1-2.5 6.8.5 13.5 2.3 19.4 5.7.5.3
          1 .1 1.3-.3.3-.3.2-.9-.3-1.2z
        `}
      />
      <path
        fill='#2460D8'
        d={oneLine`
          M82.6 7.6c0-3.747-3.053-6.8-6.8-6.8S69 3.853 69 7.6
          s6.8 12.629 6.8 12.629 6.8-8.813 6.8-12.629z
          m-10.755 0
          A3.953 3.953 0 0175.8 3.645
          c2.151 0 3.955 1.804 3.955 3.955
          a3.953 3.953 0 01-3.955 3.955A3.953 3.953 0 0171.845 7.6z
        `}
      />
    </g>
  </svg>
}
