import React from "react"
import FormContainer from "../../containers/FormContainer"
import PesonalDataForm from "../../components/PersonalDataForm"


export default function FormPersonalDataPage (props: any) {
  return (
    <FormContainer isTest={props.isTest} >
      {(props: any) => <PesonalDataForm {...props} />}
    </FormContainer>
  )
}
