import media from "../../utils/media"
import styled from "styled-components"

const Subline = styled.p `
  font-family: Heebo;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props: any) => props.light ? "#e2e9fb" : "#161466"};
  text-align: ${(props: any) => props.center ? "center" : "inherit"};
  ${(media as any).from.sm `
    font-size: 20px;
    line-height: 1.3;
  `}
  ${(media as any).only.lg `
    font-size: 24px;
    line-height: 1.3;
  `}
  ${(media as any).only.xl `
    font-size: 32px;
    line-height: 1.3;
  `}
`

// @ts-expect-error
Subline.Small = styled(Subline) `
  ${(media as any).only.xl `
    font-size: 24px;
    line-height: 32px;
  `}
  ${(media as any).only.lg `
    font-size: 20px;
  `}
  ${(media as any).only.md `
    font-size: 20px;
    line-height: 24px;
  `}
  ${(media as any).only.sm `
    font-size: 16px;
  `}
  ${(media as any).only.xs `
    font-size: 16px;
  `}
`

export default Subline
