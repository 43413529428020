import React from "react"
import { useLocation } from "react-router-dom"

import FormContainer from "../../containers/FormContainer"
import CarInfoForm from "../../components/CarInfoForm"


export default function FormCarInfoPage (props: any) {
  const location = useLocation()
  return (
    <FormContainer isTest={props.isTest}>
      {({
        user,
        ...props
      }: any) => {
        return (
          <CarInfoForm
            user={user}
            leadSource={
              location.search.includes("src=brr")
                ? "brr"
                : undefined
            }
            {...props}
          />
        )
      }}
    </FormContainer>
  )
}
