import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { Collapse } from "@material-ui/core"
import { oneLine } from "common-tags"

import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import PageContentText from "../PageContentText"
import { ClaimContext } from "../../../contexts"
import { Flex } from "../../../lib"
import SelectButton from "../../../components/YesNoUnsureInput/SelectButton"


function YesNoUnsureButtons (
  {
    value,
    onChange,
  }: any,
) {
  function handleSelected (...args: any[]) {
    if (typeof onChange === "function") {
      onChange(...args)
    }
  }

  return (
    <Flex>
      <SelectButton
        style={{ width: "100%" }}
        active={value === "yes"}
        onClick={(e: any) => handleSelected(e, "yes")}
      >
        Ja
      </SelectButton>
      <SelectButton
        style={{ width: "100%" }}
        active={value === "no"}
        onClick={(e: any) => handleSelected(e, "no")}
      >
        Nein
      </SelectButton>
    </Flex>
  )
}


export default function KapHasInsurancePage (
  {
    openNotEligibleModal,
    nextPage,
  }: any,
) {
  const claim = useContext(ClaimContext) as Claim
  const [
    legalExpensesInsurance,
    setLegalExpensesInsurance,
  ] = useState(claim.legalExpensesInsurance)
  const [
    legalExpensesInsuranceAtPurchase,
    setLegalExpensesInsuranceAtPurchase,
  ] = useState(claim.legalExpensesInsuranceAtPurchase)
  const history = useHistory()

  return (
    <FlexContainer direction='ttb' center>
      <PageContentText>
        Verfügen Sie über eine Rechtsschutzversicherung?
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        <YesNoUnsureButtons
          value={legalExpensesInsurance}
          onChange={(e: any, value: any) => {
            if (value === "no") {
              openNotEligibleModal({ message: "kapInsurance" })
            }
            setLegalExpensesInsurance(value)
          }}
        />
      </FormSection>

      <Collapse in={legalExpensesInsurance === "yes"}>
        <FormSection
          style={{ width: "100%" }}
          heading={oneLine`
            War eine Rechtsschutzversicherung bereits
            bei Anschaffung des Fahrzeugs vorhanden?
          `}
        >
          <YesNoUnsureButtons
            value={legalExpensesInsuranceAtPurchase}
            onChange={(e: any, value: any) => {
              if (value === "no") {
                openNotEligibleModal({ message: "kapInsurance" })
              }
              claim.update({
                legalExpensesInsurance,
                legalExpensesInsuranceAtPurchase: value,
              })
              setLegalExpensesInsuranceAtPurchase(value)
              if (value === "yes") {
                history.push(nextPage)
              }
            }}
          />
        </FormSection>
      </Collapse>
    </FlexContainer>
  )
}
