import React, { memo, useState } from "react"
import { Button, Slider as MuiSlider } from "@material-ui/core"
import styled from "styled-components"
import Container from "../Container"
import { Flex } from "../../lib"
import { Body } from "../../lib/src/components/Typography"
import { get } from "lodash"


const StyledSlider = styled(MuiSlider)`
  && {
    color: #2460d8;
    .MuiSlider-rail {
      height: 3px;
      border-radius: 1.5px;
    }
    .MuiSlider-track {
      height: 3px;
      border-radius: 1.5px;
    }
  }
`

const SliderValue = styled(Body.Bold)`
  min-height: 24px;
  color: #2460d8;
`

const SliderHelpText = styled(Body.Small)`
  font-weight: 500;
`

const SliderButton = styled(Button)`
  && {
    height: 24px;
    border-radius: 4px;
    min-width: 25px;
    width: 25px;
    padding: 0;
    margin-top: -15px;
    margin-left: -10px;
    position: relative;
    background: white;
    box-shadow: 0 2px 5px 0 rgba(22, 20, 102, 0.15);
  }
`


// @ts-expect-error ts-migrate(2339) Remove to see error
const CustomThumb = memo(({ className, ...props }) => {
  return (
    <SliderButton id='custom-thumb' variant='contained' {...props}>
      <svg width='3' height='15' viewBox='0 0 3 15'>
        <g fill='#E2E9FB' fillRule='evenodd'>
          <rect width='3' height='3' y='12' rx='1.5' />
          <rect width='3' height='3' y='6' rx='1.5' />
          <rect width='3' height='3' rx='1.5' />
        </g>
      </svg>
    </SliderButton>
  )
})


// TODO: move the formik stuff to separate export
function Slider (
  {
    formatter = (v: any) => v,

    // { name, value, onChange, onBlur }
    field,

    label,
    tooltip,

    // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    form,

    min,
    max,
    style,
    onChangeCommitted,
    ...props
  }: any,
) {
  const hasValue = get(field, "value") !== ""
  const value = hasValue ? get(field, "value") : min
  const [internalValue, setInternalValue] = useState(value)
  return (
    <div style={{ maxWidth: 600, minWidth: 200, ...style }}>
      {/* @ts-expect-error */}
      <SliderValue>
        {hasValue ? formatter(internalValue) : "-"}
      </SliderValue>
      {/* @ts-expect-error */}
      <Container pt={1} pb={0.5} px={0.5}>
        <StyledSlider
          ThumbComponent={CustomThumb}
          {...field}
          {...props}
          min={min}
          max={max}
          value={internalValue}
          onChange={(e: any, v: any) => {
            setInternalValue(v)
          }}
          onChangeCommitted={(e: any, v: any) => {
            if (onChangeCommitted) {
              return onChangeCommitted(e, v)
            }
            form && form.setFieldValue(field.name, v)
          }}
        />
      </Container>
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Flex justify='between'>
        {/* @ts-expect-error */}
        <SliderHelpText>{formatter(min)}</SliderHelpText>
        {/* @ts-expect-error */}
        <SliderHelpText>{formatter(max)}</SliderHelpText>
      </Flex>
    </div>
  )
}


export default memo(Slider)
