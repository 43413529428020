/* eslint-disable */

import React, { memo } from 'react'
export default memo(() => (
  <svg width='13' height='13'>
    <g fill='none' fillRule='nonzero'>
      <path fill='#000' d='M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 12.278A5.778 5.778 0 1 1 6.5.722a5.778 5.778 0 0 1 0 11.556z' />
      <path fill='#FFF' d='M9.468 3.927a.58.58 0 0 0-.81.104L5.83 7.691 4.344 5.74a.58.58 0 0 0-.81-.11.58.58 0 0 0-.11.81l1.927 2.527c.13.172.34.247.54.219a.574.574 0 0 0 .413-.22l3.268-4.23a.58.58 0 0 0-.104-.81z' />
      <path fill='#2460D8' d='M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm3.072 4.738L6.304 8.967a.574.574 0 0 1-.412.22.577.577 0 0 1-.54-.219L3.424 6.441a.58.58 0 0 1 .11-.81.58.58 0 0 1 .809.11L5.83 7.69l2.828-3.66a.58.58 0 0 1 .81-.103.58.58 0 0 1 .104.81z' />
    </g>
  </svg>
))
