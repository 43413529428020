import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core"
import { oneLine } from "common-tags"

import { ClaimContext, UserContext } from "../../../contexts"
import TextField from "../../../components/TextField"
import FieldError from "../../../components/FieldError"
import AuthUserContext from "../../../contexts/authUser.context"
import sendMagicLinkEmail from "../../../helpers/sendMagicLinkEmail"
import Wait from "../../../components/Wait"
import track from "./track"


export default function MissingEmailDialog (
  {
    open,
    nextPage,
  }: any,
) {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const user = useContext(UserContext) as User
  const claim = useContext(ClaimContext) as Claim
  const authUser = useContext(AuthUserContext) as Record<string, any>
  const history = useHistory()


  function handeEmailChange (e: any) {
    setEmail(e.target.value)
    if (emailError) {
      setEmailError("")
    }
  }


  async function onEmailSubmitted () {
    setSubmitting(true)
    if (email.indexOf("ı") > -1) {
      return setEmailError(
        oneLine`
          Die von Ihnen angegebene E-Mail-Adresse enthält ein Symbol
          oder Zeichen, das nicht mit unserem E-Mail-System kompatibel ist.
          Bitte benutzen Sie eine andere E-Mail-Adresse.
        `,
      )
    }

    const emailRegex = /\S+@\S+\.\S+/

    if (!emailRegex.test(email)) {
      return setEmailError(
        "Diese E-Mail-Adresse scheint nicht gültig zu sein.",
      )
    }

    await authUser.updateEmail(email)
    sendMagicLinkEmail({
      email,
      template: "registration",
      userId: user && user.id,
      claimId: claim && claim.id,
    })
    await user.update({ hasCompletedRegistration: true })
    track(user, claim)

    // Email is good
    console.info("Saved user data")

    history.push(nextPage)
    setSubmitting(false)
  }


  return (
    <Wait on={!submitting}>
      <Dialog open={open}>
        <DialogTitle>Fehlende E-Mail-Adresse</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Es wurde keine E-Mail-Adresse angegeben -
            geben Sie diese hier ein um mit Claimback fortzufahren
          </DialogContentText>
          <TextField
            placeholder='Email-Adresse'
            value={email}
            fullWidth
            onChange={handeEmailChange}
          />
          { emailError &&
            // @ts-expect-error
            <FieldError>{emailError}</FieldError>
          }
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => onEmailSubmitted()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Wait>
  )
}
