import React from "react"
import styled from "styled-components"
import media from "../../utils/media"
import { Flex } from "../../lib"
import GlobalStyle from "../GlobalStyle"

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0;
  width: 100%;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0,0,0);
`

const ResponsiveWrapper = styled(Flex) `
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${(media as any).to(992) `
    margin-top: 64px;
  `}
  ${(media as any).from(993) `
    margin-top: 80px;
  `}

  ${(media as any).from.md `
    max-width: 960px;
  `}
`


export default function PageContainer (
  {
    children,
    signOut,
  }: any,
) {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <ResponsiveWrapper direction='ttb'>
          {children}
        </ResponsiveWrapper>
      </Wrapper>
    </>
  )
}
