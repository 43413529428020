import React, { memo } from "react"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import PaperPlaneIcon from "../PaperPlaneIcon"


const StyledButton = styled(Button)`
  && {
    font-family: Heebo;
    text-transform: initial;
    border-radius: 8px;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    padding: 0 15px;
    box-shadow: 0 1px 5px 0 rgba(22, 20, 102, 0.15);
    color: white;
    background: ${(props: any) => props.disabled ? "#b9b8d1" : "#2460d8"};
    &:hover {
      background: ${(props: any) => props.disabled ? "#b9b8d1" : "#161466"};
    }
  }
`


function SubmitButton (
  {
    icon = <><PaperPlaneIcon />&nbsp;&nbsp;</>,
    children,
    ...props
  }: any,
) {
  return (
    <StyledButton
      variant='contained'
      color='primary'
      type='submit'
      style={{ marginTop: 20 }}
      {...props}
    >
      {icon}
      {children}
    </StyledButton>
  )
}


export default memo(SubmitButton)
