/* eslint-disable max-len */

import React, { memo } from "react"

export default memo(() => (
  <svg width='16' height='16'>
    <g fill='none' fillRule='nonzero'>
      <path
        fill='#000'
        d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 15.111A7.111 7.111 0 1 1 8 .89 7.111 7.111 0 0 1 8 15.11z'
      />
      <path
        fill='#2460D8'
        d='M11.653 4.834a.713.713 0 0 0-.998.127l-3.48 4.504-1.829-2.4a.713.713 0 0 0-.996-.135.713.713 0 0 0-.135.997l2.37 3.11a.71.71 0 0 0 .667.27.707.707 0 0 0 .507-.27L11.78 5.83a.713.713 0 0 0-.128-.997z'
      />
      <path
        fill='#FFF'
        d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.78 5.831L7.76 11.036a.707.707 0 0 1-.507.27.71.71 0 0 1-.666-.268l-2.37-3.11a.713.713 0 0 1 .134-.998.713.713 0 0 1 .996.135l1.83 2.4 3.48-4.503a.713.713 0 0 1 .997-.128c.31.24.367.688.128.997z'
      />
    </g>
  </svg>
))
