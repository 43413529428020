import styled from "styled-components"
import media from "../../utils/media"
export default styled.h1 `
  font-family: grad, serif;
  font-style: normal;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 15px;
  color: ${(props: any) => props.light ? "#e2e9fb" : "#161466"};
  text-align: ${(props: any) => props.center ? "center" : "inherit"};
  ${(media as any).only.xs `
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.2px;
  `}
  ${(media as any).only.sm `
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.2px;
  `}
  ${(media as any).only.md `
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.2px;
  `}
  ${(media as any).only.lg `
    font-size: 60px;
    line-height: 88px;
    letter-spacing: 0.8px;
  `}
  ${(media as any).only.xl `
    font-size: 68px;
    line-height: 96px;
    letter-spacing: 0.8px;
  `}
`

