import styled from "styled-components"
import Button from "./Button"

const LightButton = styled(Button)`
  box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
  background-color: #fff;
  color: #2460d8;

  
  ${(props: any) => !props.disabled && `
    &:hover{
      cursor: pointer;
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.5);
    }

    &:active {  
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
    }
  `}

  ${(props: any) => props.disabled && `
    border: solid 1px #b9b8d1;
    color: #b9b8d1;
  `}
`

export default LightButton
