import React from "react"
import { oneLine } from "common-tags"


export default function Trip (props: any) {
  return (
    <svg width={89} height={47} {...props}>
      <g fill='none'>
        <path
          fill='#161466'
          d={oneLine`
            M88.3 22.1c-6-3.9-13.1-6.2-20.2-7-7.2-.8-14.5.1-21.4 2.1-6.9 2-13.5
            5.4-19.1 9.9-2.8 2.3-5.4 4.8-7.7 7.6s-4.2 5.9-5.8 9.2c-.4.9-.1 1.9.8
            2.3.9.4 1.9.1 2.4-.8 1.5-3 3.4-5.8 5.5-8.4 2.1-2.6 4.6-5 7.2-7.1
            5.3-4.2 11.4-7.4 17.8-9.5 6.5-2.1 13.3-3 20.1-2.5 6.8.5 13.5 2.3
            19.4 5.7.5.3 1 .1 1.3-.3.3-.3.2-.9-.3-1.2z
          `}
        />
        <path
          fill='#2460D8'
          d={oneLine`
            M13.1.5C6 .5.3 6.2.3 13.3c0 7.1 12.8 23.8 12.8 23.8S26 20.4 26
            13.3C26 6.3 20.2.5 13.1.5z
            m0 20.4c-4.1 0-7.5-3.4-7.5-7.5
            S9 5.9 13.1 5.9s7.5 3.4 7.5 7.5-3.3 7.5-7.5 7.5z
          `}
        />
      </g>
    </svg>
  )
}
