import { RadioGroup } from "@material-ui/core"
import React from "react"
import { Flex } from "../../lib"
import SelectButton from "./SelectButton"
import InfoBox from "../InfoBox"

const OPTS = {
  YES: "yes",
  NO: "no",
}


export default function YesNoButtons (
  {
    label,
    tooltip,

    // { name, value, onChange, onBlur }
    field,

    // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    form,

    choices = ["Ja", "Nein"],
    values = [OPTS.YES, OPTS.NO],
    infoBoxContent,
    ...props
  }: any,
) {
  // const { touched, errors } = form
  const hasValue = field.value !== ""
  const isYes = field.value === values[0]
  const isNo = field.value === values[1]

  return (
    <RadioGroup {...field} {...props}>
      <Flex>
        <SelectButton
          inactive={hasValue && !isYes}
          name={`${field.name}-yes`}
          active={isYes}
          onBlur={field.onBlur}
          onClick={() => {
            form.setFieldValue(field.name, values[0])
          }}
        >
          {choices[0]}
        </SelectButton>
        <SelectButton
          inactive={hasValue && !isNo}
          name={`${field.name}-no`}
          active={isNo}
          onBlur={field.onBlur}
          onClick={() => form.setFieldValue(field.name, values[1])}
        >
          {choices[1]}
        </SelectButton>
      </Flex>
      {isYes &&
        infoBoxContent &&
        infoBoxContent[0] &&
        <InfoBox>{infoBoxContent[0]}</InfoBox>
      }
      {isNo &&
        infoBoxContent &&
        infoBoxContent[1] &&
        <InfoBox>{infoBoxContent[1]}</InfoBox>
      }
    </RadioGroup>
  )
}
