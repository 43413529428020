import React, { useContext, useState } from "react"
import {Redirect, useHistory} from "react-router-dom"
import { get } from "lodash"
import { oneLine } from "common-tags"
import { Collapse } from "@material-ui/core"

import Flex from "../../../lib/src/components/Flex"
import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import SelectButton from "../../../components/YesNoUnsureInput/SelectButton"
import ClaimContext from "../../../contexts/claim.context"
import PageContentText from "../PageContentText"
import { calculateAllRewards } from "../../../lib/src/helpers/calculateAward"
import { FeatureFlagContext } from "../../../contexts"
import routes from "../../../routes"


function YesNoUnsureButtons (
  {
    value,
    onChange,
  }: any,
) {
  function handleSelected (...args: any[]) {
    if (typeof onChange === "function") {
      onChange(...args)
    }
  }

  return (
    <Flex>
      <SelectButton
        style={{ width: "100%" }}
        active={value === "yes"}
        onClick={(e: any) => handleSelected(e, "yes")}
      >
        Ja
      </SelectButton>
      <SelectButton
        style={{ width: "100%" }}
        active={value === "no"}
        onClick={(e: any) => handleSelected(e, "no")}
      >
        Nein
      </SelectButton>
    </Flex>
  )
}


export default function InsuranceCheckPage (
  {
    nextPage,
    openNotEligibleModal,
  }: any,
) {
  const claim = useContext(ClaimContext) as Claim
  const [
    legalExpensesInsurance,
    setLegalExpensesInsurance,
  ] = useState(null)
  const [
    legalExpensesInsuranceAtPurchase,
    setLegalExpensesInsuranceAtPurchase,
  ] = useState(null)
  const flags = useContext(FeatureFlagContext)
  const rewards = calculateAllRewards(claim)
  const history = useHistory()

  if (!get(flags, "show_insurance_check_in_funnel.value")) {
    // return <Redirect to={nextPage} />
  }

  if (rewards.maxReward && rewards.maxReward >= 6000) {
    // return <Redirect to={nextPage} push />
  }

  if (
    legalExpensesInsurance === "yes" &&
    legalExpensesInsuranceAtPurchase === "yes"
  ) {
    return <Redirect to={routes.REGISTRATION_KAP_INSURANCE_SELECT} />
  }

  return (
    <FlexContainer direction='ttb' center>
      <PageContentText>
        Verfügen Sie über eine Rechtsschutzversicherung?
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        <YesNoUnsureButtons
          value={legalExpensesInsurance}
          onChange={(e: any, value: any) => {
            setLegalExpensesInsurance(value)
            claim.update({
              legalExpensesInsurance,
            })

            if (
              legalExpensesInsurance === "yes" &&
              legalExpensesInsuranceAtPurchase === "yes"
            ) {
              history.push(routes.REGISTRATION_KAP_INSURANCE_SELECT)
            }
          }}
        />
      </FormSection>

      <Collapse in={legalExpensesInsurance === "yes"}>
        <FormSection
          style={{ width: "100%" }}
          heading={oneLine`
            War eine Rechtsschutzversicherung bereits bei
            Anschaffung des Fahrzeugs vorhanden?
          `}
        >
          <YesNoUnsureButtons
            value={legalExpensesInsuranceAtPurchase}
            onChange={(e: any, value: any) => {
              setLegalExpensesInsuranceAtPurchase(value)
              claim.update({
                legalExpensesInsurance,
                legalExpensesInsuranceAtPurchase,
              })
            }}
          />
        </FormSection>
      </Collapse>

      <Collapse in={legalExpensesInsurance === "no" ||
        legalExpensesInsuranceAtPurchase === "no"}>
        <FormSection
          style={{ width: "100%" }}
          heading={oneLine`
            Kein Problem. Wir vermitteln Ihnen einen Prozesskostenfinanzierer, 
            der die Kosten übernimmt.
          `}
        >
          <SelectButton
            variant='contained'
            color='primary'
            type='submit'
            style={{ marginTop: 20, width: "100%" }}
            onClick={() => {
              history.push(routes.REGISTRATION_CALCULATING_AWARD)
            }}
          >
            Weiter mit Prozesskostenfinanzierung
          </SelectButton>
        </FormSection>
      </Collapse>
    </FlexContainer>
  )
}
