import { useState, useEffect } from "react"
import { pick } from "lodash"
import firebase from "../../../firebase"

const saveKeys = [
  "providerData",
]


function saveUserData (user: any) {
  const saveData = pick(user.toJSON(), saveKeys)
  firebase
    .firestore()
    .doc(`users/${user.uid}`)
    .set(saveData, { merge: true })
}


export default function useAuthUser () {
  const [authUser, setAuthUser] = useState(
    // eslint-disable-next-line
    firebase.auth().currentUser
  )
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // eslint-disable-next-line
    const unlisten = firebase.auth()
      .onAuthStateChanged(authUser => {
        setAuthUser(authUser)
        if (authUser) {
          saveUserData(authUser)
        }
        setLoading(false)
      })
    return unlisten
  })

  return [authUser, loading]
}
