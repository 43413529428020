import React, { useState } from "react"
import PropTypes from "prop-types"
import { oneLineTrim } from "common-tags"
import styled from "styled-components"

import Container from "../Container"
import Show from "../Show"
import Flex from "../Flex"
import Body from "../Typography/Body"
import ProgressBar from "../ProgressBar"
import CheckIconBlue from "../../icons/CheckIconBlue"
import firebase from "../../../../firebase"


class FileUploader extends React.Component {
  constructor (...args: any[]) {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 1-2 arguments
    super(...args)

    this.state = {
      progress: 0,
      completed: false,
    }
  }

  componentDidMount () {
    // @ts-expect-error
    const { fileRef, file } = this.props
    const uploader = fileRef.put(file)
    uploader.on("state_changed",
      (snap: any) => {
        // Get task progress, including the number of bytes uploaded
        // and the total number of bytes to be uploaded
        const progress = (snap.bytesTransferred / snap.totalBytes) * 100
        this.setState({ progress })
      }, (error: any) => {
        // TODO: handle errors here
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors

        // TODO: must handle an error here
        console.warn("An exception was thrown in UploadFileInput")
        console.error(error)
      }, async () => {
        // Upload completed successfully, now we can get the download URL
        // let downloadURL = await uploader.snapshot.ref.getDownloadURL()
        this.setState({ completed: true })
        // @ts-expect-error
        this.props.onComplete()
      })
  }

  render () {
    return (
      <FileProgress
        // @ts-expect-error
        name={this.props.file.name}
        // @ts-expect-error
        progress={this.state.progress}
        // @ts-expect-error
        completed={this.state.completed}
      />
    )
  }
}

const FileLabel = styled(Body)`
  margin: 0%;
`
const CompleteLabel = styled(Body.Small)`
  display: flex;
  align-items: center;
  margin: 0%;
`


export function FileProgress (
  {
    name,
    progress,
    completed,
  }: any,
) {
  return (
    // @ts-expect-error ts-migrate(2322) Remove to see error
    <Flex alignItems='center'>
      {/* @ts-expect-error */}
      <Container px={0} py={0.3} mr={1} style={{ width: "35%" }}>
        <FileLabel>{name}</FileLabel>
      </Container>
      {/* @ts-expect-error */}
      <Container p={0} style={{ width: "65%" }}>
        {completed
          // @ts-expect-error
          ? <CompleteLabel>
            <CheckIconBlue />
              &nbsp;&nbsp;
              Sicherer Upload erfolgreich
          </CompleteLabel>
          : <ProgressBar progress={progress} />}
      </Container>

    </Flex>
  )
}


function DocumentIcon () {
  return (
    <svg width='12' height='14'>
      <path
        fill='#2460D8'
        fillRule='nonzero'
        d={oneLineTrim`
          M11.884 3.666L8.604.106a.35.35 0 0 0-.297-.103H1.303
          C.585.003 0 .535 0 1.19v11.623C0 13.467.584 14 1.303 14
          h9.39c.719 0 1.302-.533 1.302-1.187V3.912c.001-.007.005-.013.005-.02
          a.283.283 0 0 0-.116-.226z
          M8.367 5.95a.638.638 0 0 1-.793-.03l-.962-.889v4.834
          c0 .261-.25.473-.561.473-.311 0-.562-.212-.562-.473V5.032l-.962.888
          a.637.637 0 0 1-.793.031c-.23-.176-.246-.474-.037-.667
          l1.939-1.791.01-.006.02-.017.007-.008c.02-.015.044-.02.066-.033
          l.013-.008
          c.025-.013.046-.031.072-.04.008-.004.017-.005.025-.008.031-.01.064
          -.012.096-.017.032-.006.062-.015.093-.015
          l.013-.002.012.002c.032 0 .062.01.093.015.033.005.065.007.096.017
          l.025.007c.026.01.047.028.071.04
          l.014.008c.021.013.046.019.066.034.004.002.005.006.008.008l.02.017
          c.003.002.007.003.01.006
          l1.938 1.79c.21.194.192.492-.037.668z
        `}
      />
    </svg>
  )
}


function CameraIcon () {
  return (
    <svg width='16' height='13' viewBox='0 0 16 13'>
      <path
        fill='#2460D8'
        fillRule='nonzero'
        d={oneLineTrim`
          M14.423 2.252h-2.448l-.58-1.637A.914.914 0 0 0 10.536 0H5.464
          a.914.914 0 0 0-.859.615l-.58 1.637H1.577C.707 2.252 0 2.973 0 3.862
          v6.972c0 .89.706 1.61 1.577 1.61h12.846c.87 0 1.577-.72 1.577-1.61
          V3.862c0-.889-.706-1.61-1.577-1.61z
          M8 10.667a3.556 3.556 0 1 1 0-7.111 3.556 3.556 0 0 1 0 7.11z
        `}
      />
    </svg>
  )
}


function LockIcon () {
  return (
    <svg style={{ marginRight: 5 }} width='10' height='11' viewBox='0 0 10 11'>
      <path
        fill='#B9B8D1'
        fillRule='nonzero'
        d={oneLineTrim`
          M9.643 11H.357A.35.35 0 0 1 0 10.656v-5.5c0-.19.16-.343.357-.343H1.43
          V3.438C1.429 1.538 3.027 0 5 0s3.571 1.539 3.571 3.438v1.374h1.072
          a.35.35 0 0 1 .357.344v5.5a.35.35 0 0 1-.357.344z
          m-2.5-7.734
          c0-1.045-.96-1.891-2.143-1.891-1.183 0-2.143.846-2.143 1.89
          v1.547h4.286V3.266z
        `}
      />
    </svg>

  )
}


const UploadInput = styled.label`
  padding: 0 30px;
  display: inline-block;
  border: none;
  outline: none;
  border-radius: 8px;
  height: 45px;
  line-height: 45px;
  box-sizing: border-box;
  font-family: Heebo;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
  transition: box-shadow 0.2s;
  background-color: #fff;
  color: #2460d8;

  ${(props: any) => !props.disabled && `
    &:hover{
      cursor: pointer;
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.25);
    }

    &:active {
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
    }
  `}

  ${(props: any) => props.disabled && `
    border: solid 1px #b9b8d1;
    color: #b9b8d1;
  `}
`

// eslint-disable-next-line import/no-named-as-default-member
const storageRef = firebase.storage().ref()


export default function UploadFileInput (
  {
    buttonLabel,
    userId,
    subdir,
    claimId,
    onChange,
    initialUploadedFiles,
  }: any,
) {
  const [files, setFiles] = useState([])

  function fileListToArray (list: any) {
    const array = []
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i)
      const fileRef = storageRef
        .child(userId)
        .child("claimFiles")
        .child(claimId)
        .child(subdir)
        .child(file.name)
      array.push({
        file,
        fileRef,
      })
    }
    return array
  }

  function onFilesAdded (newFileList: any) {
    const newFiles = fileListToArray(newFileList)
    // @ts-expect-error ts-migrate(2345) Remove to see error
    setFiles([...files, ...newFiles])
  }

  if (!userId) {
    return null
  }
  return <>
    {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
    <Flex direction='ttb' fullWidth>
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Flex direction='ttb' fullWidth>
        { initialUploadedFiles.map((fileRef: any) =>
          <FileProgress
            key={fileRef.name}
            name={fileRef.name}
            progress={100}
            completed
          />)
        }
      </Flex>
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Flex direction='ttb' fullWidth>
        {files.map(({ fileRef, file }) => (
          <FileUploader
            key={(file as any).name}
            // @ts-expect-error
            file={file}
            fileRef={fileRef}
            onComplete={() => {
              onChange && onChange(file)
            }}
          />))}
      </Flex>

      <input
        style={{ display: "none" }}
        id={`${subdir}-raised-button-file`}
        multiple
        type='file'
        // this is fired when files are added to the list for upload
        // we do not call props.onChange until after the upload completes
        onChange={(e) => onFilesAdded(e.target.files)}
      />
      {/* @ts-expect-error */}
      <Container p={0} pt={0.5}>
        <UploadInput
          htmlFor={`${subdir}-raised-button-file`}
          // @ts-expect-error
          py={0.5}
          px={1}
        >
          {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
          <Show below='md'>
            {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
            <Flex alignItems='center'>
              <CameraIcon />&nbsp;&nbsp;{buttonLabel || "Fotos hochladen"}
            </Flex>

          </Show>
          {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
          <Show from='md'>
            {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
            <Flex alignItems='center'>
              <DocumentIcon />
              &nbsp;&nbsp;
              {buttonLabel || "Dokumente hochladen"}
            </Flex>
          </Show>
        </UploadInput>
      </Container>
      {/* @ts-expect-error */}
      <Container p={0} pl={0.5} mt={1}>
        {
          (files.length + initialUploadedFiles.length > 0) && (
            // @ts-expect-error
            <Body.Small>
              Wenn Sie die Durchsetzung des Schadensersatzes
              weiterer Fahrzeuge wünschen,
              kontaktieren Sie uns bitte unter
              <a
                style={{ textDecoration: "underline" }}
                href={`malito:support@claimback.org?subject=${claimId}`}
              >
                support@claimback.org
              </a>
              <br />
              <br />
            </Body.Small>)
        }
        {/* @ts-expect-error */}
        <Body.Small>
          <LockIcon /> Verschlüsselte Verbindung
        </Body.Small>
      </Container>
    </Flex>
  </>
}

UploadFileInput.propTypes = {
  userId: PropTypes.string.isRequired,
  claimId: PropTypes.string.isRequired,
  initialUploadedFiles: PropTypes.array.isRequired,
  subdir: PropTypes.string.isRequired,
}
