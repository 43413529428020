import React, { useState, useContext } from "react"
import { Redirect, withRouter } from "react-router-dom"
import Container from "../../components/Container"
import FormPageLayout from "../../components/FormPageLayout/FormPageLayout"
import FullScreenLoader from "../../components/FullScreenLoader"
import Wait from "../../components/Wait"
import useFiles from "../../hooks/useFiles"
import routes from "../../routes"
import { ClaimContext, AuthUserContext } from "../../contexts"


function FormContainer (
  {
    children,
    match,
    isTest,
    ...props
  }: any,
) {
  const authUser = useContext(AuthUserContext)
  const [files, filesLoading] = useFiles()
  const [calcOpen, setCalcOpen] = useState(false)
  const [calculatedAward, setCalculatedAward] = useState(null)
  const claim = useContext(ClaimContext) as Claim

  if (!isTest && filesLoading) {
    // give it time
    return (
      <FullScreenLoader />
    )
  }

  const isUploadPage = match &&
    (match.path === routes.APPLITCATION_UPLOAD_KV_PAGE)
  if (!isTest && !isUploadPage) {
    const isSuccessPage = match && (match.path === routes.APPLICATION_SUCCESS)
    if (!isSuccessPage && claim.applicationApproved) {
      return <Redirect to={routes.APPLICATION_SUCCESS} />
    }
  }

  // console.log(
  //  `Using claim ${claim} for user ${authUser?.uid} ${authUser?.email}`
  // )

  return (
    <Wait on={claim && claim.id}>
      <FormPageLayout
        calcOpen={calcOpen}
        setCalcOpen={setCalcOpen}
        calculatedAward={calculatedAward}
        setCalculatedAward={setCalculatedAward}
        claimId={claim.id}
      >
        {/* @ts-expect-error */}
        <Container pb={3}>
          {children && children({
            user: authUser,
            claim,
            files,
            claimId: claim.id,
            calcOpen,
            setCalcOpen,
            calculatedAward,
            setCalculatedAward,
            ...props,
          })}
        </Container>
      </FormPageLayout>
    </Wait>
  )
}


export default withRouter(FormContainer)
