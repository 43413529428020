import React from "react"
import { Route, Switch, BrowserRouter } from "react-router-dom"
// eslint-disable-next-line
import qs from 'query-string'

import { useAuthUser, useFeatureFlags,
  useUser, useClaim } from "./lib"
import firebase from "./firebase"
import AppErrorBoundary from "./AppErrorBoundary"
import Wait from "./components/Wait"
import Auth from "./containers/Auth"
import { AuthUserContext, FeatureFlagContext,
  ClaimContext, UserContext } from "./contexts"
import ApplicationReviewPage from "./pages/ApplicationReviewPage"
import ApplicationSuccessPage from "./pages/ApplicationSuccessPage"
import ErrorPage from "./pages/ErrorPage"
import FormCarInfoPage from "./pages/FormCarInfoPage"
import FormPersonalDataPage
  from "./pages/FormPersonalDataPage/FormPersonalDataPage"
import FormRepresentationPage from "./pages/FormRepresentationPage"
import NotFoundPage from "./pages/NotFoundPage"
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage"
import PromptForEmailPage from "./pages/PromptForEmailPage"
import routes from "./routes"
import UploadKvPage from "./pages/UploadKvPage/UploadKvPage"
import LinkHasExpiredPage from "./pages/LinkHasExpiredPage"
import updateUserDoc from "./helpers/updateUserDoc"


let logged = false

function logUser (user: any) {
  if (!logged) {
    console.info(`uid is "${user.uid}"`)
    logged = true
  }
}


export default function App () {
  const [flags, flagsLoading] = useFeatureFlags()
  const [authUser, authUserLoading] = useAuthUser()
  const [user, userLoading] = useUser()
  const [claim, claimLoading] = useClaim()

  // set some user data
  if (authUser && !user) {
    // eslint-disable-next-line
    const altTrack = !!qs.parse(window.location.search).alt_track
    if (altTrack) {
      updateUserDoc(authUser, { alt_track: altTrack })
    }
    else {
      updateUserDoc(authUser)
    }
  }

  if (authUser) {
    logUser(authUser)
  }


  const { origin, pathname, search, href } = window.location
  if (
    // eslint-disable-next-line
    firebase.auth().isSignInWithEmailLink(href) &&
    pathname !== routes.SIGN_IN
  ) {
    console.info("Sign in with the link")
    window.location.href = `${origin}/sign-in${search}&redirect=${pathname}`
  }

  return (
    <AppErrorBoundary>
      <Wait on={[!flagsLoading, !authUserLoading, !userLoading, !claimLoading]}>
        <FeatureFlagContext.Provider value={flags}>
          <AuthUserContext.Provider value={authUser}>
            <UserContext.Provider value={user}>
              <ClaimContext.Provider value={claim}>
                <BrowserRouter>
                  <Auth>
                    <Switch>
                      {/* registered user required routes */}
                      <Route exact
                        path={routes.APPLICATION_CAR_INFO}
                        component={FormCarInfoPage}
                      />
                      <Route exact
                        path={routes.APPLICATION_REPRESENTATION}
                        component={FormRepresentationPage}
                      />
                      <Route exact
                        path={routes.APPLICATION_PERSONAL_DATA}
                        component={FormPersonalDataPage}
                      />
                      <Route exact
                        path={routes.APPLICATION_REVIEW}
                        component={ApplicationReviewPage}
                      />
                      <Route exact
                        path={routes.APPLICATION_SUCCESS}
                        component={ApplicationSuccessPage}
                      />
                      <Route exact
                        path={routes.APPLITCATION_UPLOAD_KV_PAGE}
                        component={UploadKvPage}
                      />

                      {/* authUser required routes */}
                      <Route
                        path={routes.REGISTRATION}
                        component={RegistrationPage}
                      />

                      {/* no auth required routes */}
                      <Route exact
                        path={routes.SIGN_IN}
                        component={PromptForEmailPage}
                      />
                      <Route exact
                        path={routes.SIGN_IN_LINK_HAS_EXPIRED}
                        component={LinkHasExpiredPage}
                      />
                      <Route
                        path={routes.ERROR}
                        component={ErrorPage}
                      />
                      <Route path='*' component={NotFoundPage} />
                    </Switch>
                  </Auth>
                </BrowserRouter>
              </ClaimContext.Provider>
            </UserContext.Provider>
          </AuthUserContext.Provider>
        </FeatureFlagContext.Provider>
      </Wait>
    </AppErrorBoundary>
  )
}
