import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import SubmitButton from "../Button/SubmitButton"
import CheckIconBlue from "../CheckIconBlue"
import Container from "../Container"
import FieldError from "../FieldError"
import FormSection, { FormSectionWithError } from "../FormSection/FormSection"
import TextField from "../TextField"
import { Body, Jumbo } from "../../lib/src/components/Typography"
import UploadFileInput from "../../lib/src/components/UploadFileInput"
import { YesNoButtons, YesNoUnsureButtons } from "../YesNoUnsureInput"
import InsuranceSelectField from "./InsuranceSelectField"
import routes from "../../routes"
import { ClaimContext } from "../../contexts"


function initialValues (
  {
    legalExpensesInsurance,
    legalExpensesInsuranceAtPurchase,
    insuranceProvider,
    legalExpensesInsurancePolicyAsCompany,
    legalExpensesInsurancePolicyholderCompanyName,
    legalExpensesInsurancePolicyholderFirstName,
    legalExpensesInsurancePolicyholderLastName,
    legalExpensesInsurancePolicyNumber,
    isFinancingPaidOff,
    returnCarInCaseOfSuccess,
  }: any,
) {
  return {
    legalExpensesInsurance,
    legalExpensesInsuranceAtPurchase: legalExpensesInsuranceAtPurchase || "",
    insuranceProvider: insuranceProvider || "",
    legalExpensesInsurancePolicyAsCompany:
      legalExpensesInsurancePolicyAsCompany || "",
    legalExpensesInsurancePolicyholderCompanyName:
      legalExpensesInsurancePolicyholderCompanyName || "",
    legalExpensesInsurancePolicyholderFirstName:
      legalExpensesInsurancePolicyholderFirstName || "",
    legalExpensesInsurancePolicyholderLastName:
      legalExpensesInsurancePolicyholderLastName || "",
    legalExpensesInsurancePolicyNumber:
      legalExpensesInsurancePolicyNumber || "",
    isFinancingPaidOff: isFinancingPaidOff || "",
    returnCarInCaseOfSuccess: returnCarInCaseOfSuccess || "",
  }
}


const REQUIRED: any = [
  // 'legalExpensesInsurance',
  // 'hasCarBeenFinanced'
]

const customRequiredErrors = {
  legalExpensesInsurance:
    "Bitte geben Sie an ob Sie eine Rechtschutzversicherung haben.",
}


function validator (values: Record<string, any>, claim: Claim) {
  const errors = {}
  let required = [...REQUIRED]

  // some fields have conditional requirements
  if (values.legalExpensesInsurance === "yes" && claim.lawFirm === "BRC") {
    required = [...required, "legalExpensesInsuranceAtPurchase"]
  }
  if (
    values.legalExpensesInsurance === "yes" &&
    values.legalExpensesInsuranceAtPurchase === "yes" &&
    claim.lawFirm === "BRC"
  ) {
    required = [
      ...required,
      "insuranceProvider",
      "legalExpensesInsurancePolicyAsCompany",
      "legalExpensesInsurancePolicyholderFirstName",
      "legalExpensesInsurancePolicyholderLastName",
    ]
    if (values.legalExpensesInsurancePolicyAsCompany === "yes") {
      required = [...required, "legalExpensesInsurancePolicyholderCompanyName"]
    }
  }
  if (values.hasCarBeenFinanced === "yes") {
    required = [...required, "isFinancingPaidOff"]
  }

  // TODO: validate regex of company name
  // TODO: validate regex of first name
  // TODO: validate regex of last name
  // TODO: validate regex of policy number name

  required.forEach(key => {
    if (!values[key]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
      errors[key] = customRequiredErrors[key] || "Diese Angabe wird benötigt."
    }
  })

  return errors
}


function FormikTextField (
  {
    field,
    form,
    ...props
  }: any,
) {
  return (
    <TextField
      onBlur={field.onBlur}
      onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
      {...field}
      {...props}
    />
  )
}


function RepresentationForm (
  {
    history,
    user,
    files,
  }: any,
) {
  const claim = useContext(ClaimContext) as Claim
  console.info("-> : RepresentationForm -> claim", claim)

  return (
    <>
      {/* @ts-expect-error */}
      <Body.Bold>
        Ihre Claim-ID:{" "}
        <span style={{ color: "#2460d8" }}>{claim.id}</span>
      </Body.Bold>
      <Jumbo>Herzlichen Glückwunsch!</Jumbo>
      <Body>
        Für die in Ihrem Fall beste Verfahrensstrategie,
        bitten wir Sie um folgende Informationen:
      </Body>
      <Formik
        initialValues={initialValues(claim)}
        validate={(values) => validator(values, claim)}
        onSubmit={async (values) => {
          await claim.update(values)
          history.push(routes.APPLICATION_PERSONAL_DATA)
        }}
      >
        {(
          // @ts-expect-error ts-migrate(2322) Remove to see error
          {
            handleSubmit,
            isSubmitting,
            values,
            errors,
            status,
              ...more // eslint-disable-line
          } = {},
        ) => {
          return (
            <Form>
              {/* legalExpensesInsurance
              <FormSectionWithError
                label='Verfügen Sie über eine Rechtsschutzversicherung?'
                tooltip='Ist das Fahrzeug über seinen
                  Inhaber rechtschutzversichert?'
                name='legalExpensesInsurance'
                component={YesNoUnsureButtons}
                infoBoxContent={[
                  'Im Falle einer Deckungszusage
                  übernimmt Ihre Rechtsschutzversicherung
                  sämtliche Prozesskosten und Claimback die Kosten
                  einer etwaigen Selbstbeteiligung.
                  Andernfalls übernehmen unsere Prozesskostenfinanzierer
                  die für Sie risiko- und kostenfreie Durchsetzung
                  Ihrer Ansprüche.',
                  null,
                  'Kein Problem, bitte teilen Sie uns - falls vorhanden -
                  die Daten Ihrer Rechtsschutzversicherung,
                  binnen 7 Tagen nach Übermittlung des Formulars mit.
                  Andernfalls übernehmen unsere Prozesskostenfinanzierer
                  gerne die für Sie risiko- und kostenfreie Durchsetzung
                  Ihrer Ansprüche.'
                ]}
              /> */}

              {/* legalExpensesInsuranceAtPurchase */}
              {claim.lawFirm === "BRC" &&
                values.legalExpensesInsurance === "yes" &&
                <FormSectionWithError
                  label={
                    "War eine Rechtsschutzversicherung " +
                    "bereits bei Anschaffung des Fahrzeugs vorhanden?"
                  }
                  tooltip={
                    "Im Falle einer Deckungszusage " +
                    "übernimmt Ihre Rechtsschutzversicherung."
                  }
                  name='legalExpensesInsuranceAtPurchase'
                  component={YesNoButtons}
                  infoBoxContent={[
                    "Im Falle einer Deckungszusage übernimmt " +
                    "Ihre Rechtsschutzversicherung sämtliche Prozesskosten " +
                    "und Claimback die Kosten " +
                    "einer etwaigen Selbstbeteiligung. " +
                    "Andernfalls übernehmen unsere Prozesskostenfinanzierer " +
                    "die für Sie risiko- und kostenfreie Durchsetzung " +
                    "Ihrer Ansprüche.",
                    "In diesem Fall " +
                    "greift Ihre Rechtsschutzversicherung nicht, " +
                    "jedoch bieten wir Ihnen " +
                    "mit unserer Prozesskostenfinanzierern " +
                    "eine für Sie ebenfalls risikofreie Lösung an.",
                  ]}
                />
              }

              {/* insuranceProvider */}
              {claim.lawFirm === "BRC" &&
                values.legalExpensesInsurance === "yes" &&
                values.legalExpensesInsuranceAtPurchase === "yes" &&
                <FormSection
                  heading='Versichert über/bei'
                >
                  <Field
                    label='Name des Versicherers'
                    name='insuranceProvider'
                    component={InsuranceSelectField}
                  />
                  <ErrorMessage
                    name='insuranceProvider'
                    component={FieldError}
                  />
                </FormSection>
              }

              {/* legalExpensesInsurancePolicyAsCompany */}
              {values.legalExpensesInsurance === "yes" &&
                values.legalExpensesInsuranceAtPurchase === "yes" && (
                <FormSectionWithError
                  label='Versicherungsnehmer'
                  name='legalExpensesInsurancePolicyAsCompany'
                  component={YesNoButtons}
                  choices={["Firma", "Privat"]}
                />
              )}
              {
                values.legalExpensesInsurance === "yes" &&
                values.legalExpensesInsuranceAtPurchase === "yes" && (
                  <>
                    {/* legalExpensesInsurancePolicyholderCompanyName */}
                    {values.legalExpensesInsurancePolicyAsCompany === "yes" && (
                      <>
                        <Field
                          fullWidth
                          label='Firmenname'
                          name='legalExpensesInsurancePolicyholderCompanyName'
                          component={FormikTextField}
                        />
                        <ErrorMessage
                          name='legalExpensesInsurancePolicyholderFirstName'
                          component={FieldError}
                        />
                      </>
                    )}
                    {/* legalExpensesInsurancePolicyholderFirstName */}
                    <Field
                      fullWidth
                      name='legalExpensesInsurancePolicyholderFirstName'
                      label='Vorname'
                      component={FormikTextField}
                    />
                    <ErrorMessage
                      name='legalExpensesInsurancePolicyholderFirstName'
                      component={FieldError}
                    />
                    {/* legalExpensesInsurancePolicyholderLastName */}
                    <Field
                      fullWidth
                      name='legalExpensesInsurancePolicyholderLastName'
                      label='Nachname'
                      component={FormikTextField}
                    />
                    <ErrorMessage
                      name='legalExpensesInsurancePolicyholderLastName'
                      component={FieldError}
                    />
                    {/* legalExpensesInsurancePolicyNumber */}
                    <Field
                      fullWidth
                      label='Versicherungsnummer'
                      name='legalExpensesInsurancePolicyNumber'
                      component={FormikTextField}
                    />
                    <ErrorMessage
                      name='legalExpensesInsurancePolicyNumber'
                      component={FieldError}
                    />
                  </>
                )
              }

              {/* isFinancingPaidOff */}
              {claim.hasCarBeenFinanced === "yes" && (
                <FormSectionWithError
                  label='Ist die Finanzierung bereits zurückbezahlt?'
                  name='isFinancingPaidOff'
                  component={YesNoButtons}
                />
              )}

              {/* financeContract */}
              {values.isFinancingPaidOff === "no" &&
                (
                  <FormSection
                    heading='Laden Sie hier Ihren Finanzierungsvertrag hoch'
                    tooltip={
                      "Sollten Sie Ihren Vertrag " +
                      "gerade nicht zur Hand haben, " +
                      "so fahren Sie bitte einfach fort " +
                      "und wir werden diesbezüglich " +
                      "zu einem späteren Zeitpunkt " +
                      "noch einmal auf Sie zukommen."
                    }
                  >
                    <UploadFileInput
                      claimId={claim.id}
                      userId={user && user.uid}
                      // @ts-expect-error ts-migrate(2322) Remove to see error
                      buttonLabel='Finanzierungsvertrag hochladen'
                      subdir='financeContract'
                      initialUploadedFiles={files.financeContracts}
                    />
                  </FormSection>
                )}

              {/* returnCarInCaseOfSuccess */}
              {claim && claim.hasCarBeenSold === "no" && (
                <FormSectionWithError
                  // label='Wie möchten Sie im Erfolgsfall verfahren?'
                  label='Möchten Sie Ihr Auto im Erfolgsfall zurückgeben?'
                  tooltip={
                    "Ihre Möglichkeit " +
                    "um die maximale Entschädigung herauszuholen. " +
                    "Ihre Entscheidung ist noch nicht final, " +
                    "wir kommen hierzu noch einmal auf Sie zu."
                  }
                  name='returnCarInCaseOfSuccess'
                  component={YesNoUnsureButtons}
                  // choices={[
                  //   'Auto zurückgeben',
                  //   'Auto behalten',
                  //   'Weiß noch nicht',
                  // ]}
                  infoBoxContent={[
                    null,
                    null,
                    "Kein Problem. " +
                    "In diesem Fall wählt die Rechtsanwaltskanzlei " +
                    "das für Sie wirtschaftlich günstigste Anspruchsziel aus " +
                    "und setzt dieses für Sie durch.",
                  ]}
                />
              )}

              {/* salesContract */}
              {claim && claim.hasCarBeenSold === "yes" && (
                <FormSection
                  heading={
                    "Laden Sie hier den Verkaufsvertrag Ihres Fahrzeugs hoch"
                  }
                  tooltip={
                    "Sollten Sie Ihren Vertrag gerade nicht zur Hand haben, " +
                    "so fahren Sie bitte einfach fort " +
                    "und wir werden diesbezügluch " +
                    "zu einem späteren Zeitpunkt noch einmal auf Sie zukommen."
                  }
                >
                  <UploadFileInput
                    claimId={claim.id}
                    userId={user && user.uid}
                    // @ts-expect-error ts-migrate(2322) Remove to see error
                    buttonLabel='Verkaufsvertrag hochladen'
                    subdir='salesContract'
                    initialUploadedFiles={files.salesContracts}
                  />
                </FormSection>
              )}

              <SubmitButton disabled={isSubmitting} onClick={handleSubmit}>
                Claim Informationen übermitteln
              </SubmitButton>

              {/* @ts-expect-error */}
              <Container p={0} pl={0.5} mt={0.75}>
                {/* @ts-expect-error */}
                <Body.Small>
                  <CheckIconBlue />
                  &nbsp;
                  &nbsp;
                  Aktueller Fortschritt ist gespeichert
                </Body.Small>
              </Container>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}


export default withRouter(RepresentationForm)
