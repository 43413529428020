import React from "react"
import { withRouter } from "react-router-dom"
import editIcon from "../assets/edit-icon.svg"
import routes from "../routes"
import LightButton from "./Button/LightButton"
import Container from "./Container"
import { Body, H4, Jumbo } from "../lib/src/components/Typography"
import SubmitButton from "./Button/SubmitButton"
import CheckIcon from "./CheckIcon"
import { Part1, Part2, Part3, Section } from "./ApplicationStatus"


function ApplicationReview (
  {
    files,
    claim,
    claimId,
    history,
    onApprove,
  }: any,
) {
  console.info(claim)

  return (
    <>
      <Section pt={0}>
        {/* @ts-expect-error */}
        <Body.Bold>
          Ihre Claim-ID:{" "}
          <span style={{ color: "#2460d8" }}>{claimId}</span>
        </Body.Bold>
        <Jumbo>
          Vielen Dank!
        </Jumbo>
        <Body>
          Zur schnellstmöglichen Bearbeitung Ihres Falls
          bitten wir um Eingabe der folgenden Daten aus dem Kaufvertrag.
        </Body>
      </Section>

      <Section>
        <H4>
          Teil 1:
        </H4>
        <Part1 claim={claim} files={files} />
        <LightButton onClick={() => history.push(routes.APPLICATION_CAR_INFO)}>
          <img src={editIcon} height={12} alt='edit' />
          &nbsp;
          &nbsp;
          Informationen bearbeiten
        </LightButton>
      </Section>
      <Section>
        <H4>
          Teil 2:
        </H4>
        <Part2 claim={claim} files={files} />
        <LightButton
          onClick={() => history.push(routes.APPLICATION_REPRESENTATION)}
        >
          <img src={editIcon} height={12} alt='edit' />
          &nbsp;
          &nbsp;
          Informationen bearbeiten
        </LightButton>
      </Section>
      <Section>
        <H4>
          Teil 3:
        </H4>
        <Part3 claim={claim} files={files} />
        <LightButton
          onClick={() => history.push(routes.APPLICATION_PERSONAL_DATA)}
        >
          <img src={editIcon} height={12} alt='edit' />
          &nbsp;
          &nbsp;
          Informationen bearbeiten
        </LightButton>

      </Section>

      {/* @ts-expect-error */}
      <Container p={0} py={1}>
        <SubmitButton
          icon={<><CheckIcon />&nbsp;&nbsp;</>}
          onClick={onApprove}
        >
          Alle Angaben sind korrekt
        </SubmitButton>
      </Container>

      {/* <pre>{JSON.stringify(claim, null, 2)}</pre> */}
    </>
  )
}


export default withRouter(ApplicationReview)
