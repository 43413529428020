/* global fbq */
export default function track (user: any, claim: any) {
  if (user.alt_track) {
    console.info("Secondary tracking")
    if (claim.alt_track) {
      console.info("Logging top model")
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fbq'.
      fbq("trackSingle", "3206760712738554", "Lead", {
        value: 1,
        currency: "EUR",
      })
    }
  }
  else {
    console.info("Primary tracking")
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fbq'.
    fbq("trackSingle", "586685552250372", "Lead", {
      value: 1,
      currency: "EUR",
    })
  }
}
