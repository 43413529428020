import React, { useState } from "react"
import Backdrop from "@material-ui/core/Backdrop"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import { CardHeader, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { animated, useSpring } from "@react-spring/web"
import { get } from "lodash"
import { oneLine } from "common-tags"

import firebase from "../../firebase"
import Flex from "../../lib/src/components/Flex"
import FieldError from "../../components/FieldError"
import TextField from "../../components/TextField"
import routes from "../../routes"


const Fade = React.forwardRef((props, ref) => {
  // @ts-expect-error ts-migrate(2339) Remove to see error
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    // @ts-expect-error
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

const messages = {
  default: {
    header: oneLine`
      Das von Ihnen ausgewählte Modell konnte zum aktuellen Zeitpunkt
      bzw. entsprechend der aktuellen Rechtslage
      nicht als betroffen eingestuft werden.
    `,
    body: oneLine`
      Melden Sie sich für den Claimback-Newsletter an
      und wir benachrichtigen Sie,
      wenn neue Modelle Anspruch auf Schadensersatz haben.
    `,
  },
  secondary: {
    header: oneLine`
      Entsprechend Ihrer Angaben kann zum aktuellen Zeitpunkt
      die Durchsetzung Ihrer Ansprüche auf Grund der vorliegenden Rechtslage
      und/oder mangelnder Durchsetzbarkeit nicht gewährleistet werden.
    `,
    body: oneLine`
      Melden Sie sich für den Claimback-Newsletter an
      und wir benachrichtigen Sie, sobald sich dieser Umstand ändert.
    `,
  },
  kapInsurance: {
    header: oneLine`
      Für das von Ihnen ausgewählte Modell
      kann ohne bzw. ohne eine der aufgeführten Rechtsschutzversicherungen
      zum aktuellen Zeitpunkt keine Durchsetzung
      möglicher Schadensersatzansprüche gewährleistet werden.
    `,
    body: oneLine`
      Gerne benachrichtigen wir Sie,
      sobald für BMW-Schadensersatzforderungen
      eine Prozesskostenfinanzierung zur Verfügung steht!
      Hinterlassen Sie uns dazu gerne Ihre E-Mail Adresse.
    `,
  },
}


export default function NotEligibleModal (
  {
    open,
    close,
    message = "default",
  }: any,
) {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)


  function handleGoHome () {
    window.location.href = routes.HOME
  }


  async function handleSaveEmail () {
    const emailRegex = /\S+@\S+\.\S+/
    if (emailRegex.test(email)) {
      await firebase.firestore().collection("newsletter-emails").add({
        email,
        createdTime: firebase.firestore.FieldValue.serverTimestamp(),
        updatedTime: firebase.firestore.FieldValue.serverTimestamp(),
      })
      window.location.href = routes.HOME
    }
    else {
      // @ts-expect-error ts-migrate(2345) Remove to see error
      setEmailError("Diese E-Mail-Adresse scheint nicht gültig zu sein.")
    }
  }


  function handleEmailChange (event: any) {
    setEmailError(null)
    setEmail(event.target.value)
  }


  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        outline: 0,
        padding: 20,
      }}
      aria-labelledby='spring-modal-title'
      aria-describedby='spring-modal-description'
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Fade in={open}>
        <Card style={{
          maxWidth: 600,
        }}
        >
          <CardHeader
            action={
              <IconButton aria-label='close' onClick={close}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              {get(messages, `${message}.header`, messages.default.header)}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {get(messages, `${message}.body`, messages.default.body)}
            </Typography>
            <TextField
              placeholder='Ihre Email Adresse'
              onChange={handleEmailChange}
              fullWidth
            />
            {emailError &&
              // @ts-expect-error
              <FieldError>{emailError}</FieldError>
            }
          </CardContent>
          <CardActions>
            {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
            <Flex justify='between' fullWidth>
              <Button size='small' color='primary' onClick={handleGoHome}>
                Zurück
              </Button>
              <Button size='small' color='primary' onClick={handleSaveEmail}>
                Newsletter erhalten
              </Button>
            </Flex>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  )
}
