import React from "react"
import Container, { FlexContainer } from "../../components/Container"
import { FormHelper } from "../../lib/src/components/Typography"
import { COLOURS } from "../../lib/src/constants"

export default function NoOptionListedButton (
  {
    children,
    onClick,
  }: any,
) {
  return (
    // @ts-expect-error
    <Container mt={0.5} p={0}>
      <FlexContainer
        center p={0.4}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <FormHelper style={{ color: COLOURS.NORMAL_BLUE, fontWeight: "bold" }}>
          {children}
        </FormHelper>
      </FlexContainer>
    </Container>
  )
}
