/* global fbq */
import { ErrorMessage, FastField, Field, Form, Formik } from "formik"
import { Flex } from "../../lib"
import { get } from "lodash"
import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import ConfettiIcon from "../../components/icons/Confetti"
import { NORMAL_BLUE } from "../../colours"
import { ClaimContext } from "../../contexts"
import FeatureFlagContext from "../../contexts/featureFlags.context"
import routes from "../../routes"
import SubmitButton from "../Button/SubmitButton"
import Checkbox from "../Checkbox"
import Container, { FlexContainer } from "../Container"
import FieldError from "../FieldError/FieldError"
import FormSection, { FormSectionWithError } from "../FormSection/FormSection"
import InfoBox from "../InfoBox"
import Show from "../Show"
import Slider from "../Slider"
import { Body, FormHelper, Jumbo } from "../../lib/src/components/Typography"
import UploadFileInput from "../../lib/src/components/UploadFileInput"
import { YesNoButtons, YesNoUnsureButtons } from "../YesNoUnsureInput"
import { COLOURS } from "../../lib/src/constants"


function initialValues (claim: any, files: any) {
  const {
    hasCarBeenSold,
    legalExpensesInsurance,
    vehicleRegistrationDocument,
    financeContract,
    currentMileage,
    carSalePrice,
    mileageAtPurchase,
    carPurchasePrice,
    currentMileageDocument,
    mileageAtSale,
    wouldHavePurchasedVehicle,
    acceptedTermsAndConditions,
    purchaseContract,
    salesContract,
    hasCarBeenFinanced,
  } = claim

  return {
    hasCarBeenSold: hasCarBeenSold || "",
    vehicleRegistrationDocument: vehicleRegistrationDocument || "",
    vehicleRegistrationDocumentOnHand:
      files.vehicleRegistrationDocuments.length > 0 ? "yes" : "",
    financeContract: financeContract || "",
    financeContractOnHand: files.financeContracts.length > 0 ? "yes" : "",
    salesContract: salesContract || "",
    salesContractOnHand: files.salesContracts.length > 0 ? "yes" : "",
    legalExpensesInsurance: legalExpensesInsurance || "",
    carSalePrice: carSalePrice || "",
    mileageAtPurchase: mileageAtPurchase || "",
    carPurchasePrice: carPurchasePrice || "",
    currentMileage: currentMileage || "",
    mileageAtSale: mileageAtSale || "",
    wouldHavePurchasedVehicle: wouldHavePurchasedVehicle || false,
    acceptedTermsAndConditions: acceptedTermsAndConditions || false,
    purchaseContract: purchaseContract || "",
    purchaseContractOnHand: files.purchaseContracts.length > 0 ? "yes" : "",
    currentMileageDocument: currentMileageDocument || "",
    currentMileageDocumentOnHand:
      files.currentMileageDocuments.length > 0 ? "yes" : "",
    hasCarBeenFinanced: hasCarBeenFinanced || "",
  }
}


const customRequiredErrors = {
  acceptedTermsAndConditions:
    "Bitte aktzeptieren Sie die Teilnahmebedingungen.",
}


function validator (values: any, files: any) {
  const errors = {}

  let required = [
    // 'hasCarBeenSold',
    "wouldHavePurchasedVehicle",
    "purchaseContractOnHand",
  ]

  if (
    values.purchaseContractOnHand === "yes" &&
    files.purchaseContracts.length === 0
  ) {
    required = [...required, "purchaseContract"]
  }

  if (!values.hasCarBeenSold) {
    required.push("vehicleRegistrationDocumentOnHand")
  }

  if (
    !values.hasCarBeenSold &&
    values.vehicleRegistrationDocumentOnHand === "yes" &&
    files.vehicleRegistrationDocuments.length === 0
  ) {
    required = [...required, "vehicleRegistrationDocument"]
  }

  if (!values.hasCarBeenSold) {
    required.push("currentMileageDocumentOnHand")
  }

  if (
    !values.hasCarBeenSold &&
    values.currentMileageDocumentOnHand === "yes" &&
    files.currentMileageDocuments.length === 0
  ) {
    required = [...required, "currentMileageDocument"]
  }

  if (values.hasCarBeenSold) {
    required.push("salesContractOnHand")
  }

  if (
    values.hasCarBeenSold &&
    values.salesContractOnHand === "yes" &&
    files.salesContracts.length === 0
  ) {
    required = [...required, "salesContract"]
  }

  if (values.hasCarBeenFinanced === "yes") {
    required.push("financeContractOnHand")
  }

  if (
    values.hasCarBeenFinanced === "yes" &&
    values.financeContractOnHand === "yes" &&
    files.financeContracts.length === 0
  ) {
    required = [...required, "financeContract"]
  }

  if (values.lawFirm !== "BRC") {
    required = [...required, "acceptedTermsAndConditions"]
  }

  required.forEach(key => {
    if (!values[key]) {
      // FIXME: Element implicitly has an 'any'
      // @ts-expect-error ts-migrate(7053)
      errors[key] = customRequiredErrors[key] || "Diese Angabe wird benötigt."
    }
  })

  return errors
}


function GeneralLabel () {
  /* eslint-disable */
  return (
    <>
      Mit den{' '}
      <a href='/assets/nutzungsbedingungen'
         target='_blank' style={{ textDecoration: 'underline' }}
      >
        Nutzungsbedingungen
      </a>{' '}
      und{' '}
      <a href='/datenschutz.html'
         target='_blank' style={{ textDecoration: 'underline' }}
      >
        Datenschutzinformationen
      </a>{' '}
      der Plattform claimback.de bin ich einverstanden.
    </>
  )
  /* eslint-enable */
}


function KAPTCLabel () {
  /* eslint-disable */
  return (
    <>
      Mit den{' '}
      <a href='/documents/nutzungsbedingungen.pdf'
        target='_blank' style={{ textDecoration: 'underline' }}
      >
        Nutzungsbedingungen
      </a>{' '}
      und{' '}
      <a href='/datenschutz.html'
        target='_blank' style={{ textDecoration: 'underline' }}
      >
        Datenschutzinformationen
      </a>{' '}
      der Plattform claimback.de bin ich einverstanden und&nbsp;
      <a href='/documents/Auftrag_und_Vollmacht_KAP.pdf'
        target='_blank' style={{ textDecoration: 'underline' }}
      >
        bevollmächtige
      </a>{' '}
      unter Kenntnisnahme der Widerrufsbelehrung
      die Kanzlei KAP Rechtsanwaltsgesellschaft mbH,
      meine Schadenersatzansprüche im Abgasskandal durchzusetzen.
    </>
  )
  /* eslint-enable */
}


function BRRTCLabel () {
  return (
    <>
      Mit den{" "}
      <a href='/documents/nutzungsbedingungen.pdf'
        target='_blank' style={{ textDecoration: "underline" }}
      >
        Nutzungsbedingungen
      </a>{" "}
      und{" "}
      <a href='/datenschutz.html'
        target='_blank' style={{ textDecoration: "underline" }}
      >
        Datenschutzinformationen
      </a>{" "}
      der Plattform claimback.de bin ich einverstanden und{" "}
      <a href='/documents/BRR_Vollmacht_Vorlage.pdf'
        target='_blank' style={{ textDecoration: "underline" }}
      >
        bevollmächtige
      </a>{" "}
      unter Kenntnisnahme der Widerrufsbelehrung
      die Kanzlei Baumeister Rosing Rechtsanwaltsgesellschaft mbH,
      meine Schadenersatzansprüche im Abgasskandal durchzusetzen.
    </>
  )
}

function removeFilesFromArrays (obj: {[key: string]: any}) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item: any) => !isFile(item))
    }
  }
  return obj
}

function isFile (item: any): boolean {
  return item instanceof File
}


export function CarInfoForm (
  { user, files, history, leadSource }:
  {
    user?: Record<string, any>
    files: {
      purchaseContracts?: any[]
      financeContracts?: any[]
      salesContracts?: any[]
      vehicleRegistrationDocuments?: any[]
      currentMileageDocuments?: any[]
    }
    history: any
    leadSource?: string
  },
) {
  const flags = useContext(FeatureFlagContext)
  const claim = useContext(ClaimContext) as Claim
  console.info("-> : CarInfoForm -> claim", claim)

  const { id: claimId } = claim as Claim
  return <>
    {/* @ts-expect-error */}
    <Body.Bold>
      Ihre Claim-ID:{" "}
      <span style={{ color: "#2460d8" }}>{claimId}</span>
    </Body.Bold>
    <Jumbo>Fast geschafft!</Jumbo>
    {get(flags, "form_one_subline_text.value") && (
      <Body>
        {get(flags, "form_one_subline_text.data")}
      </Body>
    )}

    <Formik
      initialValues={initialValues(claim, files)}
      validate={
        (values) => validator(
          {
            ...values,
            carIsTop: claim.carIsTop,
            lawFirm: claim.lawFirm,
            hasCarBeenSold: claim.hasCarBeenSold === "yes",
          },
          files,
        )
      }
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        delete values.purchaseContract // do not save File against claim.
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fbq'.
        fbq("track", "Purchase")

        values.acceptedTermsAndConditions = true
        const updatedObject = removeFilesFromArrays(values)

        console.info(updatedObject)

        await claim.update(updatedObject)
        history.push(routes.APPLICATION_PERSONAL_DATA)
        setSubmitting(false)
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        return (<div style={{ maxWidth: 600 }}>
          <Form>
            {/* carPurchasePrice */}
            {get(flags, "show_purchase_price_in_form_one.value") &&
            (<FormSection
              heading='Ungefährer Kaufpreis des Fahrzeugs'
              tooltip={
                "Der Kaufpreis des Fahrzeugs inkl. Mehrwertsteuer " +
                "in Euro. Bei Inzahlungnahme eines anderen Fahrzeugs " +
                "gehört dieser Betrag mit zum Kaufpreis"}
            >
              <Field
                name='carPurchasePrice'
                component={Slider}
                formatter={
                  (v: any) => `${Number(v).toLocaleString("de-DE")} €`
                }
                min={5000}
                step={5000}
                max={150000}
              />
              <ErrorMessage
                name='carPurchasePrice'
                component={FieldError}
              />
            </FormSection>)}

            {/* mileageAtPurchase */}
            {get(flags, "show_purchase_mileage_in_form_one.value") &&
            (<FormSection heading='km-Stand beim Kauf'>
              <Field
                name='mileageAtPurchase'
                component={Slider}
                formatter={
                  (v: any) => `${Number(v).toLocaleString("de-DE")} km`
                }
                min={0}
                max={300000}
                step={10000}
              />
              <ErrorMessage
                name='mileageAtPurchase'
                component={FieldError}
              />
            </FormSection>)}

            {/* hasCarBeenSold */}
            {get(flags, "show_has_car_been_sold_in_form_one.value") &&
            (<FormSection
              heading='Fahrzeug bereits weiterverkauft?'
              tooltip={
                "In beiden Fällen haben Sie Anspruch auf Entschädigung."
              }>
              <Field name='hasCarBeenSold' component={YesNoButtons}/>
              <ErrorMessage name='hasCarBeenSold' component={FieldError}/>
            </FormSection>)}

            {/* carSalePrice */}
            {get(flags, "show_car_sale_price_in_form_one.value") &&
        values.hasCarBeenSold === "yes" &&
        <FormSection
          heading='Ungefährer Verkaufspreis'
          tooltip={
            "Zu welchem Preis " +
            "haben Sie das Fahrzeug ungefähr veräußert?"
          }
        >
          <Field
            name='carSalePrice'
            component={Slider}
            min={5000}
            step={5000}
            max={150000}
            formatter={(v: any) => `${Number(v).toLocaleString("de-DE")} €`}
          />
          <ErrorMessage name='carSalePrice' component={FieldError}/>
        </FormSection>}

            {/* currentMileage */}
            {get(flags, "show_current_mileage_form_one.value") &&
        values.hasCarBeenSold === "no" &&
        <FormSection
          heading='ungefährer Kilometerstand aktuell'
          tooltip={"Wie viele km ist Ihr Fahrzeug aktuell ungefähr gefahren?"}
        >
          <Field
            name='currentMileage'
            component={Slider}
            formatter={(v: any) => `${Number(v).toLocaleString("de-DE")} km`}
            min={10000}
            max={300000}
            step={10000}
          />
          <ErrorMessage name='currentMileage' component={FieldError}/>
        </FormSection>}

            {/* mileageAtSale */}
            {get(flags, "show_mileage_at_sale_in_form_one.value") &&
        values.hasCarBeenSold === "yes" &&
        <FormSection
          heading='ungefährer Kilometerstand bei Verkauf'
          tooltip='Wie hoch war der km Stand ungefähr beim Verkauf?'
        >
          <Field
            name='mileageAtSale'
            component={Slider}
            formatter={(v: any) => `${Number(v).toLocaleString("de-DE")} km`}
            min={10000}
            max={300000}
            step={10000}
          />
          <ErrorMessage name='mileageAtSale' component={FieldError}/>
        </FormSection>}
            {/* legalExpensesInsurance */}

            {get(flags, "show_legal_expenses_insurance_in_form_one.value") &&
              claim.lawFirm === "BRC" &&
              <FormSectionWithError
                label='Verfügen Sie über eine Rechtsschutzversicherung?'
                tooltip={
                  "Ist das Fahrzeug über seinen Inhaber " +
                  "rechtschutzversichert?"
                }
                name='legalExpensesInsurance'
                component={YesNoUnsureButtons}
                infoBoxContent={[
                  "Im Falle einer Deckungszusage " +
                  "übernimmt Ihre Rechtsschutzversicherung " +
                  "sämtliche Prozesskosten und Claimback die Kosten " +
                  "einer etwaigen Selbstbeteiligung. " +
                  "Andernfalls werden sämtliche zur " +
                  "Durchsetzung Ihrer Ansprüche anfallenden Kosten " +
                  "sowie auch das Kostenrisiko vom " +
                  "Prozesskostenfinanzierer übernommen.",

                  "Kein Problem, nach erfolgreicher Prüfung Ihres Falls " +
                  "werden sämtliche zur Durchsetzung Ihrer Ansprüche " +
                  "anfallenden Kosten sowie auch das Kostenrisiko " +
                  "vom Prozesskostenfinanzierer übernommen.",

                  "Kein Problem, bitte teilen Sie uns " +
                  "- falls vorhanden - " +
                  "die Daten Ihrer Rechtsschutzversicherung, " +
                  "binnen 7 Tagen " +
                  "nach Übermittlung des Formulars, mit. " +
                  "Andernfalls werden sämtliche zur Durchsetzung " +
                  "Ihrer Ansprüche anfallenden Kosten " +
                  "sowie auch das Kostenrisiko " +
                  "vom Prozesskostenfinanzierer übernommen.",
                ]}
              />
            }

            {/* purchaseContractOnHand */}
            <FormSection heading='Kaufvertrag zur Hand? ' tooltip=''>
              <Field name='purchaseContractOnHand' component={YesNoButtons}/>
              <ErrorMessage
                name='purchaseContractOnHand'
                component={FieldError}
              />
            </FormSection>

            {/* purchaseContract */}
            {values.purchaseContractOnHand === "yes" &&
              (<FormSection heading={(<>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show above='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                  Laden Sie Ihren Kaufvertrag hoch
                  </Body.Bold>
                </Show>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show to='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                  Fotografieren Sie Ihren Kaufvertrag
                  </Body.Bold>
                </Show>
              </>)} tooltip={"Zur Bearbeitung Ihres Falls " +
            "wird Ihr Kaufvertrag benötigt. " +
            "Bitte laden Sie diesen hoch " +
            "oder fotografieren Sie diesen " +
            "mit Ihrem Smartphone direkt ab."}>
                <Field
                  name='purchaseContract'
                  subdir='purchaseContract'
                  claimId={claimId}
                  userId={user && user.uid}
                  value={values.purchaseContract}
                  initialUploadedFiles={files.purchaseContracts}
                  buttonLabel='Kaufvertrag hochladen'
                  onChange={(file: any) => {
                    if (file && values.purchaseContract) {
                      setFieldValue(
                        "purchaseContract",
                        [...values.purchaseContract, file],
                      )
                    }
                    else {
                      setFieldValue(
                        "purchaseContract",
                        file ? [file] : [],
                      )
                    }
                  }}
                  component={UploadFileInput}
                />
                <ErrorMessage name='purchaseContract' component={FieldError}/>
              </FormSection>)}
            { (values.purchaseContractOnHand === "no") &&
                <InfoBox>
                  Sie können den Kaufvertrag
                  auch zu einem späteren Zeitpunkt nachreichen -
                  wir kommen hierzu noch einmal auf Sie zu.
                </InfoBox>
            }

            {/* currentMileageDocumentOnHand */}
            {values.hasCarBeenSold === "no" &&
              <FormSection
                heading='Foto des aktuellen Kilometerstands zur Hand?'
                tooltip=''
              >
                <Field
                  name='currentMileageDocumentOnHand'
                  component={YesNoButtons}
                />
                <ErrorMessage
                  name='currentMileageDocumentOnHand'
                  component={FieldError}
                />
              </FormSection>
            }

            {/* currentMileageDocument */}
            {values.hasCarBeenSold === "no" &&
              values.currentMileageDocumentOnHand === "yes" &&
              (<FormSection heading={(<>
                {/* @ts-expect-error */}
                <Body.Bold>
                  Bitte schicken Sie uns ein Foto von Ihrem aktuellen
                  Kilometerstand. Dieser darf nicht länger als 90 Tage
                  zurückliegen.
                </Body.Bold>
              </>)} tooltip={"Zur Bearbeitung Ihres Falls " +
                "wird Ihr aktueller Kilometerstand benötigt. " +
                "Bitte laden Sie diesen hoch " +
                "oder fotografieren Sie diesen " +
                "mit Ihrem Smartphone direkt ab."}>
                <Field
                  name='currentMileageDocument'
                  subdir='currentMileageDocument'
                  claimId={claimId}
                  userId={user && user.uid}
                  value={values.currentMileageDocument}
                  initialUploadedFiles={files.currentMileageDocuments}
                  buttonLabel='Aktuellen Kilometerstand hochladen'
                  onChange={(file: any) => {
                    if (file && values.currentMileageDocument) {
                      setFieldValue(
                        "currentMileageDocument",
                        [...values.currentMileageDocument, file],
                      )
                    }
                    else {
                      setFieldValue(
                        "currentMileageDocument",
                        file ? [file] : [],
                      )
                    }
                  }}
                  component={UploadFileInput}
                />
                <ErrorMessage
                  name='currentMileageDocument'
                  component={FieldError}
                />
              </FormSection>)}
            { (values.currentMileageDocumentOnHand === "no") &&
                <InfoBox>
                    Sie können den aktuellen Kilometerstand
                    auch zu einem späteren Zeitpunkt nachreichen -
                    wir kommen hierzu noch einmal auf Sie zu.
                </InfoBox>
            }

            {/* salesContractOnHand */}
            {claim.hasCarBeenSold === "yes" &&
              <FormSection heading='Verkaufsvertrag zur Hand? ' tooltip=''>
                <Field
                  name='salesContractOnHand'
                  component={YesNoButtons}
                />
                <ErrorMessage
                  name='salesContractOnHand'
                  component={FieldError}
                />
              </FormSection>
            }

            {/* salesContract */}
            {claim.hasCarBeenSold === "yes" &&
              values.salesContractOnHand === "yes" &&
              (<FormSection heading={(<>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show above='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                    Laden Sie Ihren Verkaufsvertrag hoch
                  </Body.Bold>
                </Show>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show to='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                    Fotografieren Sie Ihren Verkaufsvertrag
                  </Body.Bold>
                </Show>
              </>)} tooltip={"Zur Bearbeitung Ihres Falls " +
                "wird Ihr Verkaufsvertrag benötigt. " +
                "Bitte laden Sie diesen hoch " +
                "oder fotografieren Sie diesen " +
                "mit Ihrem Smartphone direkt ab."}>
                <Field
                  name='salesContract'
                  subdir='salesContract'
                  claimId={claimId}
                  userId={user && user.uid}
                  value={values.salesContract}
                  initialUploadedFiles={files.salesContracts}
                  buttonLabel='Verkaufsvertrag hochladen'
                  onChange={(file: any) => {
                    if (file && values.salesContract) {
                      setFieldValue(
                        "salesContract",
                        [...values.salesContract, file],
                      )
                    }
                    else {
                      setFieldValue(
                        "salesContract",
                        file ? [file] : [],
                      )
                    }
                  }}
                  component={UploadFileInput}
                />
                <ErrorMessage
                  name='salesContract'
                  component={FieldError}
                />
              </FormSection>)}
            { (values.salesContractOnHand === "no") &&
                <InfoBox>
                    Sie können den Verkaufsvertrag
                    auch zu einem späteren Zeitpunkt nachreichen -
                    wir kommen hierzu noch einmal auf Sie zu.
                </InfoBox>
            }

            {/* vehicleRegistrationDocumentOnHand */}
            {claim.hasCarBeenSold !== "yes" &&
              <FormSection heading='Fahrzeugschein zur Hand? ' tooltip=''>
                <Field
                  name='vehicleRegistrationDocumentOnHand'
                  component={YesNoButtons}
                />
                <ErrorMessage
                  name='vehicleRegistrationDocumentOnHand'
                  component={FieldError}
                />
              </FormSection>
            }

            {/* vehicleRegistrationDocument */}
            {claim.hasCarBeenSold !== "yes" &&
              values.vehicleRegistrationDocumentOnHand === "yes" &&
              (<FormSection heading={(<>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show above='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                    Laden Sie Ihren Fahrzeugschein hoch
                  </Body.Bold>
                </Show>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show to='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                    Fotografieren Sie Ihren Fahrzeugschein
                  </Body.Bold>
                </Show>
              </>)} tooltip={"Zur Bearbeitung Ihres Falls " +
                "wird Ihr Fahrzeugschein benötigt. " +
                "Bitte laden Sie diesen hoch " +
                "oder fotografieren Sie diesen " +
                "mit Ihrem Smartphone direkt ab."}>
                <Field
                  name='vehicleRegistrationDocument'
                  subdir='vehicleRegistrationDocument'
                  claimId={claimId}
                  userId={user && user.uid}
                  value={values.vehicleRegistrationDocument}
                  initialUploadedFiles={files.vehicleRegistrationDocuments}
                  buttonLabel='Fahrzeugschein hochladen'
                  onChange={(file: any) => {
                    if (file && values.vehicleRegistrationDocument) {
                      setFieldValue(
                        "vehicleRegistrationDocument",
                        [...values.vehicleRegistrationDocument, file],
                      )
                    }
                    else {
                      setFieldValue(
                        "vehicleRegistrationDocument",
                        file ? [file] : [],
                      )
                    }
                  }}
                  component={UploadFileInput}
                />
                <ErrorMessage
                  name='vehicleRegistrationDocument'
                  component={FieldError}
                />
              </FormSection>)}
            { (values.vehicleRegistrationDocumentOnHand === "no") &&
                <InfoBox>
                    Sie können den Fahrzeugschein
                    auch zu einem späteren Zeitpunkt nachreichen -
                    wir kommen hierzu noch einmal auf Sie zu.
                </InfoBox>
            }

            {/* hasCarBeenFinanced */}
            <FormSectionWithError
              label='Wurde das Auto finanziert?'
              tooltip='In beiden Fällen haben Sie Anspruch!'
              name='hasCarBeenFinanced'
              component={YesNoButtons}
            />

            {/* financeContractOnHand */}
            {values.hasCarBeenFinanced === "yes" &&
              <FormSection heading='Finanzierungsvertrag zur Hand? ' tooltip=''>
                <Field
                  name='financeContractOnHand'
                  component={YesNoButtons}
                />
                <ErrorMessage
                  name='financeContractOnHand'
                  component={FieldError}
                />
              </FormSection>
            }

            {/* financeContract */}
            {values.hasCarBeenFinanced === "yes" &&
              values.financeContractOnHand === "yes" &&
              (<FormSection heading={(<>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show above='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                    Laden Sie Ihren Finanzierungsvertrag hoch
                  </Body.Bold>
                </Show>
                {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
                <Show to='sm'>
                  {/* @ts-expect-error */}
                  <Body.Bold>
                    Fotografieren Sie Ihren Finanzierungsvertrag
                  </Body.Bold>
                </Show>
              </>)} tooltip={"Zur Bearbeitung Ihres Falls " +
                "wird Ihr Finanzierungsvertrag benötigt. " +
                "Bitte laden Sie diesen hoch " +
                "oder fotografieren Sie diesen " +
                "mit Ihrem Smartphone direkt ab."}>
                <Field
                  name='financeContract'
                  subdir='financeContract'
                  claimId={claimId}
                  userId={user && user.uid}
                  value={values.financeContract}
                  initialUploadedFiles={files.financeContracts}
                  buttonLabel='Finanzierungsvertrag hochladen'
                  onChange={(file: any) => {
                    if (file && values.financeContract) {
                      setFieldValue(
                        "financeContract",
                        [...values.financeContract, file],
                      )
                    }
                    else {
                      setFieldValue(
                        "financeContract",
                        file ? [file] : [],
                      )
                    }
                  }}
                  component={UploadFileInput}
                />
                <ErrorMessage
                  name='financeContract'
                  component={FieldError}
                />
              </FormSection>)}
            { (values.financeContractOnHand === "no") &&
                <InfoBox>
                    Sie können den Finanzierungsvertrag
                    auch zu einem späteren Zeitpunkt nachreichen -
                    wir kommen hierzu noch einmal auf Sie zu.
                </InfoBox>
            }

            {/* Legal checkboxes */}
            <FormSection>
              <FastField
                name='wouldHavePurchasedVehicle'
                label={
                  "Hätte ich gewusst, " +
                  "dass das Fahrzeug vom Abgasskandal betroffen ist " +
                  "(und damit die Straßenzulassung des Fahrzeugs " +
                  "gefährdet ist), " +
                  "hätte ich das Fahrzeug nicht gekauft."
                }
                component={Checkbox}
              />
              <ErrorMessage
                name='wouldHavePurchasedVehicle'
                component={FieldError}
              />

              {claim.lawFirm !== "BRC" &&
                <>
                  <FastField
                    name='acceptedTermsAndConditions'
                    label={
                      <GeneralLabel />
                    }
                    component={Checkbox}
                  />
                  <ErrorMessage
                    name='acceptedTermsAndConditions'
                    component={FieldError}
                  />
                </>
              }
            </FormSection>

            {(values as any).maxReward && (values as any).maxReward > 0 && (<>
              <Container />
              {/* @ts-expect-error */}
              <Body.Bold>
                    Anspruch auf bis zu
              </Body.Bold>
              {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
              <Flex alignItems='center' justify='start'>
                <Jumbo style={{ color: NORMAL_BLUE }}>
                  {Number((values as any).maxReward)
                    .toLocaleString("de-DE")}&nbsp;€
                </Jumbo>
                <ConfettiIcon style={{ marginBottom: 15, marginLeft: 15 }}/>
              </Flex>
              <FormHelper>
                    Großer Schadensersatz;
                    basierend auf Ihren Angaben im Claim Calculator.
              </FormHelper>
            </>)}
            <br />
            <SubmitButton disabled={isSubmitting} onClick={handleSubmit}>
                Anspruch jetzt sichern
            </SubmitButton>
            {/* @ts-expect-error */}
            <Container p={0.5}/>
            <FlexContainer
              center
              style={{ backgroundColor: COLOURS.VERY_LIGHT_BLUE }} p={0.5}
            >
              <FormHelper style={{
                color: NORMAL_BLUE,
                fontWeight: "bold",
                fontSize: 12,
              }}>
                  100% Kostenübernahme & 0% Prozesskostenrisiko
              </FormHelper>
            </FlexContainer>

          </Form>
        </div>)
      }}
    </Formik>
  </>
}


export default withRouter(CarInfoForm as any)
