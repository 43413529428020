import useAuthUser from "./useAuthUser"
import { useState, useEffect } from "react"
import { db } from "../../../firebase"
import useDocument from "./useDocument"


function getClaim (
  {
    data = {},
    snapshot,
    authUser,
  }: any,
) {
  return {
    ...data,
    update: (data: any) => {
      if (snapshot) {
        // Strips the 'update' method during an update
        const { update, ...others } = data
        return snapshot.ref.set({
          ...others,
          updatedById: authUser.uid,
          updatedBy: "user",
        }, { merge: true })
      }
      else {
        throw new Error("No snapshot found for claim, cannot update")
      }
    },
  }
}


/**
 * This hook will return the first claim from the claims collection
 * for the current user.
 * if no claim exists for a user - it will create one and then return it
 */

export default function useClaim () {
  const [authUser, authUserLoading] = useAuthUser()
  const [loadingClaimPath, setLoadingClaimPath] = useState(true)
  const [error, setError] = useState<any>(null)
  const [claimPath, setClaimPath] = useState("")
  const [
    claimDocData,
    claimDocSnap,
    claimDocLoading,
    claimDocError,
  ] = useDocument(claimPath)

  const userId = authUser ? (authUser as any).uid : ""

  if (authUser && userId !== (authUser as any).uid) {
    setLoadingClaimPath(true)
  }

  /**
   * This effect will create or find the first claim
   * related to a specific user id and set it in claimPath
   * If no claims exist for a user a claim will be created
   * and the claimPath value will be set to the new claim path
   */
  function findClaimPathEffect () {
    setLoadingClaimPath(true)

    async function findClaimPath () {
      // we are gauranteed to have a real user id at this point.
      try {
        const claimsRef = db.collection(`users/${userId}/claims`)
        const claimsSnap = await claimsRef.get()
        if (claimsSnap.empty) {
          // we will create a new claim and set the path variable.
          const claimRef = await claimsRef.add({
            type: "diesel", // for now all claims are diesel scandal
          })
          // do this to speed up the app loading time,
          // it will often execute before the 'onClaimCreate' function
          claimRef.update({ id: claimRef.id })
          setClaimPath(claimRef.path)
        }
        else {
          // we return the path for the first claim
          setClaimPath(claimsSnap.docs[0].ref.path)
        }
      }
      catch (error) {
        setError(error)
      }
      setLoadingClaimPath(false)
    }

    if (!authUser) {
      setLoadingClaimPath(false)
      return
    }

    findClaimPath()
  }

  useEffect(findClaimPathEffect, [userId])

  return [
    getClaim({ data: claimDocData, snapshot: claimDocSnap, authUser }),
    loadingClaimPath || claimDocLoading || authUserLoading,
    error || claimDocError,
  ]
}
