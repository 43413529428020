import styled from "styled-components"
import media from "../../utils/media"

const Body = styled.p `
  font-family: Heebo;
  font-weight: normal;
  font-style: normal;
  color: ${(props: any) => props.light ? "#e2e9fb" : "#161466"};
  text-align: ${(props: any) => props.center ? "center" : "inherit"};
  ${(media as any).from.xs `
    font-size: 16px;
    line-height: normal;
  `};
  ${(media as any).only.xl `
    font-size: 24px;
    line-height:1.33;
  `};

  a {
    color: ${(props: any) => props.light ? "#e2e9fb" : "#161466"};
    transition: color 0.2s;
    &:hover {
      color: #2460d8
    }
  }
`

// TODO: Convert to `export BodyBold`
// @ts-expect-error
Body.Bold = styled(Body)`
  font-weight: 500;
`

// @ts-expect-error
Body.Small = styled(Body) `
  ${(media as any).from.xs ` font-size: 14px; `};
`

export default Body
