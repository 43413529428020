import { COLOURS } from "../../lib/src/constants"
import React, { useContext } from "react"
import Container, { FlexContainer } from "../../components/Container"
import Checkmark from "../../components/icons/Checkmark"
import { Body } from "../../lib/src/components/Typography"
import ClaimContext from "../../contexts/claim.context"
const { VERY_LIGHT_BLUE, DARK_BLUE } = COLOURS


function BulletPointText (
  {
    children,
  }: any,
) {
  return (
    <FlexContainer alignItems='center' wrap={false} px={0} py={0.25}>
      <div>
        <Checkmark style={{ marginRight: 16 }} />
      </div>
      {/* @ts-expect-error */}
      <Body.Small style={{ color: DARK_BLUE }}>{children}</Body.Small>
    </FlexContainer>
  )
}


export default function BulletPointHeader () {
  const claim = useContext(ClaimContext) as Claim
  return (
    <Container style={{ backgroundColor: VERY_LIGHT_BLUE }}>
      <BulletPointText>Geld bekommen & weiterfahren</BulletPointText>
      <BulletPointText>
        auch ohne Rechtsschutzversicherung
      </BulletPointText>
    </Container>
  )
}
