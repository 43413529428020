import React, { useContext, useState } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { get } from "lodash"

import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import SelectField from "../../../components/SelectField"
import { ClaimContext, FeatureFlagContext } from "../../../contexts"
import PageContentText from "../PageContentText"
import Money from "../../../components/icons/Money"
import routes from "../../../routes"


export default function PurchasePricePage (
  {
    nextPage,
    openNotEligibleModal,
  }: any,
) {
  const history = useHistory()
  const flags = useContext(FeatureFlagContext)
  const claim = useContext(ClaimContext) as Claim
  const [purchasePrice, setPurchasePrice] =
    useState(null)

  if (!get(flags, "show_purchase_price_in_funnel.value")) {
    // return <Redirect to={nextPage} />
  }

  return (
    <FlexContainer direction='ttb' center>
      <Money />

      <PageContentText>
        Zu welchem ungefähren Kaufpreis haben Sie das Fahrzeug erworben?
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        <SelectField
          label='Kaufpreis Ihres PKWs'
          value={purchasePrice}
          onChange={async (e: any) => {
            const value = e.target.value
            setPurchasePrice(value)
            await claim.update({ carPurchasePrice: value })

            history.push(routes.REGISTRATION_PURCHASE_MILEAGE)
          }}
          options={[
            { value: 10000, label: "0 € – 10\u202F000 €" },
            { value: 20000, label: "10\u202F000 € – 20\u202F000 €" },
            { value: 30000, label: "20\u202F000 € – 30\u202F000 €" },
            { value: 40000, label: "30\u202F000 € – 40\u202F000 €" },
            { value: 50000, label: "40\u202F000 € – 50\u202F000 €" },
            { value: 60000, label: "50\u202F000 € – 60\u202F000 €" },
            { value: 70000, label: "60\u202F000 € – 70\u202F000 €" },
            { value: 80000, label: "70\u202F000 € – 80\u202F000 €" },
            { value: 90000, label: "80\u202F000 € – 90\u202F000 €" },
            { value: 100000, label: "90\u202F000 € – 100\u202F000 €" },
            { value: 100001, label: "100\u202F000 € +" },
          ]}
        />
      </FormSection>
    </FlexContainer>
  )
}
