import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import "./index.css"


try {
  // try to fix auth/network-error firebase errors during anon auth here
  // this should mess with cordova apps running our code within their own webkit
  Object.defineProperty(navigator, "onLine", {
    get: function () {
      return true
    },
    set: function () { },
  })
}
catch (error) {
  console.warn("Ignoring error caught during navigator property definition")
}

console.info(
  `Firebase project ID: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
)

ReactDOM.render(<App />, document.getElementById("root"))
