import React from "react"
import { oneLine } from "common-tags"


export default function Confetti (props: any) {
  return (
    <svg width={57} height={56} {...props}>
      <g fill='none' transform='matrix(-1 0 0 1 57 0)'>
        <path
          fill='#161466'
          d={oneLine`
            M36.448 42.663a3.651 3.651 0 001.99-.986
            c.93-.922 1.73-2.824.256-6.638
            a.94.94 0 00-1.614-.266.926.926 0 00-.136.933
            c.855 2.215.917 3.91.169
            4.654-.908.899-3.168.618-6.048-.75a40.08 40.08 0 01-10.377-7.69
            c-7.344-7.292-10.374-14.447-8.5-16.308a2.575 2.575 0
            012.121-.491.936.936 0 001.002-.838.932.932 0 00-.82-1.015 4.339
            4.339 0 00-3.628 1.024c-3.435 3.41 1.816 12.304 8.5 18.941a41.91
            41.91 0 0010.893 8.051c.733.349 1.488.649 2.261.898l-6.66
            2.508c-8.895-3.357-15.298-11.187-16.782-20.52a.937.937 0
            00-1.072-.775.932.932 0 00-.78 1.064 27.9 27.9 0 00.585 2.772c2.158
            8.122 7.844 14.869 15.515 18.41l-10.963 4.12a.915.915 0 00-.188-.222
            12.943 12.943 0 01-4.837-9.013l-.435-5.23a.917.917 0
            00-.326-.641.93.93 0 00-.69-.214.94.94 0 00-.64.327.926.926 0
            00-.219.682l.442 5.228a14.792 14.792 0 004.96 9.807l-7.82 2.94
            1.406-5.731a.927.927 0 00-.263-.894.943.943 0 00-1.56.453L.363
            54.7a.927.927 0 00.29.918c.26.23.627.296.953.173L36.293
            42.76a.872.872 0 00.155-.097z
            `}
        />
        <path
          fill='#2460D8'
          d={oneLine`
            M31.657 31.107l-3.35.559a.931.931 0 00-.747 1.067.937.937 0
            001.057.768l3.349-.554a29.17 29.17 0 0114.917 1.44.972.972 0
            00.33.055.93.93 0 10.33-1.803 31.08 31.08 0 00-15.886-1.532z
            m-2.163-2.781a.944.944 0 001.25.44l19.687-9.308a.929.929 0
            10.128-1.607.943.943 0 00-.934-.075l-19.688 9.309a.925.925 0
            00-.443 1.24z
            m-10.48-1.403a.938.938 0 001.225-.5l1.365-3.245a27.704 27.704 0
            001.5-16.86.933.933 0 00-.632-.701.942.942 0 00-.925.204.927.927 0
            00-.273.9 25.867 25.867 0 01-1.4 15.74l-1.363 3.248a.928.928 0
            00.503 1.214z
            m30.073 9.38a2.803 2.803 0 002.813 2.793 2.803 2.803 0 002.813-2.793
            2.803 2.803 0 00-2.813-2.792 2.803 2.803 0 00-2.813 2.792z
            m3.75 0c0 .514-.42.931-.937.931a.934.934 0 01-.938-.93
            c0-.515.42-.932.938-.932s.938.417.938.931z
            M32.212 10.24A2.803
            2.803 0 0029.4 7.446a2.803 2.803 0 00-2.813 2.792 2.803 2.803 0
            002.813 2.793 2.803 2.803 0 002.813-2.793z
            m-3.75 0
            c0-.515.42-.931.938-.931s.938.416.938.93-.42.931-.938.931
            a.934.934 0 01-.938-.93z
            `}
        />
        <ellipse cx={20.962} cy={1.862} fill='#2460D8' rx={1.875} ry={1.862} />
        <path
          fill='#2460D8'
          d={oneLine`
            M46.275 6.516c0 .514.42.93.938.93a.934.934 0 00.937-.93v-.93
            a.935.935 0 00-.938-.932.934.934 0 00-.937.931v.931z
            M47.212 0
            a.934.934 0 00-.937.93v.932c0 .514.42.93.938.93
            a.934.934 0 00.937-.93
            V.93a.935.935 0 00-.938-.931z
            m2.813 2.793h-.938a.934.934 0 00-.937.93
            c0 .514.42.931.938.931h.937a.934.934 0 00.938-.93.934.934 0
            00-.938-.931z
            m-4.688 0H44.4a.934.934 0 00-.938.93c0 .514.42.931.938.931h.938
            a.934.934 0 00.937-.93.934.934 0 00-.938-.931z
            M40.65 16.755a.934.934 0 00.938-.93V11.15a.935.935 0 00-.938-.931
            h-4.707a.934.934 0 00-.938.93v4.674c0 .515.42.931.938.931h4.707z
            m-3.77-4.673h2.832v2.812H36.88v-2.812z
            M23.078 27.617a.942.942 0 001.324.07l9.375-8.378a.926.926 0
            00.056-1.303.942.942 0 00-1.31-.08l-9.375 8.377
            a.926.926 0 00-.07 1.314z
            M51.9 23.271v-.93a.934.934 0 00-.938-.931.934.934 0 00-.937.93v.931
            c0 .514.42.931.938.931a.934.934 0 00.937-.93z
            m1.052-1.363l.663.658a.942.942 0 001.314-.012.926.926 0 00.011-1.305
            l-.662-.658a.942.942 0 00-1.315.012.926.926 0 00-.011 1.305z
            m2.698-4.222h-.938a.934.934 0 00-.937.931c0 .514.42.93.938.93h.937
            a.934.934 0 00.938-.93c0-.514-.42-.93-.938-.93z
            m-2.035-.771a.94.94 0 00.663-.272l.662-.658a.926.926 0
            00-.011-1.305.942.942 0 00-1.314-.012l-.663.658a.926.926 0 00-.203
            1.015.938.938 0 00.866.574z
            m-2.653-3.883a.934.934 0 00-.937.93v.932c0 .514.42.93.938.93
            a.934.934 0 00.937-.93v-.931a.935.935 0 00-.938-.931z
            m-2.652 1.636a.942.942 0 00-1.314.012.926.926 0 00-.011 1.305
            l.663.658a.942.942 0 001.314-.012.926.926 0 00.011-1.305l-.663-.658z
            M11.542 4.025a.936.936 0 00-.757-.633l-2.752-.397L6.806.519a.977.977
            0 00-1.682 0L3.892 2.995l-2.752.397a.936.936 0 00-.757.633.926.926 0
            00.236.954l1.99 1.927-.469 2.72
            a.928.928 0 00.371.915.94.94 0 00.99.07
            l2.462-1.288 2.46 1.285a.943.943 0 00.988-.07.928.928 0 00.373-.911
            l-.469-2.721 1.991-1.927a.926.926 0 00.236-.954z
            M7.65 5.915a.927.927 0 00-.27.822l.232 1.346-1.214-.636a.943.943 0
            00-.871 0l-1.22.635.233-1.345a.927.927 0 00-.265-.822l-.985-.953
            1.36-.196a.937.937 0 00.706-.51l.607-1.221.607 1.222
            c.136.274.4.465.705.509l1.36.196-.985.953z
            m45.767 40.744l-.386-.575a14.156 14.156 0 00-9.414-6.044.937.937 0
            00-1.057.768.931.931 0 00.748 1.067 12.29 12.29 0 017.154 3.96 5.532
            5.532 0 00-3.75 2.557 3.71 3.71 0 001.446 5.066 3.764 3.764 0
            005.102-1.436 5.369 5.369 0 00.609-2.389 4.521 4.521 0 01.205
            4.834.925.925 0 00-.003.945.939.939 0 001.64-.038 6.474 6.474 0
            00-2.29-8.715h-.004z
            m-1.792 4.455a1.876 1.876 0 01-1.61.962 1.878 1.878 0 01-1.643-.91
            1.85 1.85 0 01-.021-1.866 3.515 3.515 0 012.538-1.64.959.959 0
            01.47.108c.79.438.833 2.339.266 3.346z
          `}
        />
      </g>
    </svg>
  )
}
