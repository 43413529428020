import { round, max, get } from "lodash"


export function returnCarReward (
  purchasePrice: any,
  lastKnownMileage: any,
  purchaseMileage: any,
) {
  if (lastKnownMileage < purchaseMileage) {
    return null
  }
  const nutzungserzatz = purchasePrice < 50000 ? 250000 : 300000

  const exact = purchasePrice - (
    (lastKnownMileage - purchaseMileage) / nutzungserzatz * purchasePrice
  )
  return round(exact)
}


export function keepCarReward (purchasePrice: any) {
  return round(purchasePrice * 0.2)
}


export function maxReward (
  purchasePrice: any,
  lastKnownMileage: any,
  purchaseMileage: any,
) {
  return max(
    [
      returnCarReward(purchasePrice, lastKnownMileage, purchaseMileage),
      keepCarReward(purchasePrice),
    ],
  )
}


export function calculateAllRewards (claim: any) {
  const carPurchasePrice = get(claim, "carPurchasePrice")
  const currentMileage = get(claim, "currentMileage")
  const hasCarBeenSold = get(claim, "hasCarBeenSold")
  const mileageAtPurchase = get(claim, "mileageAtPurchase")
  const mileageAtSale = get(claim, "mileageAtSale")

  let lastKnownMileage = null
  if (hasCarBeenSold === "yes") {
    lastKnownMileage = mileageAtSale
  }

  if (hasCarBeenSold === "no") {
    lastKnownMileage = currentMileage
  }

  return {
    returnCarReward: returnCarReward(
      carPurchasePrice,
      lastKnownMileage,
      mileageAtPurchase,
    ) || null,
    keepCarReward: keepCarReward(carPurchasePrice) || null,
    maxReward: max([carPurchasePrice * 0.15]) || null,
    // maxReward: max([
    //  returnCarReward(carPurchasePrice, lastKnownMileage, mileageAtPurchase),
    //  keepCarReward(carPurchasePrice),
    // ]) || null,
  }
}
