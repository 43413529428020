import React from "react"
import { Redirect, useHistory } from "react-router-dom"
import routes from "../../routes"
import FormContainer from "../../containers/FormContainer"
import ApplicationSuccess from "../../components/ApplicationSuccess"


export default function ApplicationSuccessPage (
  {
    isTest,
  }: any,
) {
  const history = useHistory()
  return (
    <FormContainer isTest={isTest} >
      {({
        user,
        claim,
        ...props
      }: any) => {
        if (!claim || !claim.applicationApproved) {
          console.info("FormContainer redirect to car info")
          history.push(routes.APPLICATION_CAR_INFO)
          return <Redirect to={routes.APPLICATION_CAR_INFO} />
        }

        return (
          <ApplicationSuccess user={user} claim={claim} {...props} />
        )
      }}
    </FormContainer>
  )
}
