import React from "react"
import PhoneNumberInput from "react-phone-input-2"
import de from "react-phone-input-2/lang/de.json"
import "./material.css"


// Wrap onChange function to add a "+" to value
// TODO: Remove after https://github.com/bl00mber/react-phone-input-2/issues/432
function getNewOnChangeFunc (onChange: any) {
  if (onChange) {
    return (value: any, country: any, event: any, fmtVal: any) =>
      onChange(
        ("+" + value).replace("++", "+"),
        country,
        event,
        fmtVal,
      )
  }
  else {
    return onChange
  }
}


function PhoneInput (props: any) {
  const {onChange, ...propsNoOnChange} = props
  return (
    <PhoneNumberInput
      country='de'
      localization={de}
      preferredCountries={["de"]}
      masks={{de: "... ........."}}  // Most entries are mobile phones
      inputStyle={{ width: "100%" }}
      onChange={getNewOnChangeFunc(onChange)}
      specialLabel={null}
      {...propsNoOnChange}
    />
  )
}


export default PhoneInput
