import audiImg from "./assets/carbrands/logo/audi.svg"
import bmwImg from "./assets/carbrands/logo/bmw.svg"
import mercedesImg from "./assets/carbrands/logo/mercedes.svg"
import porscheImg from "./assets/carbrands/logo/porsche.svg"
import seatImg from "./assets/carbrands/logo/seat.svg"
import skodaImg from "./assets/carbrands/logo/skoda.svg"
import vwImg from "./assets/carbrands/logo/volkswagen.svg"
import alphaRomeoImg from "./assets/carbrands/logo/alfa_romeo.svg"
import citroenImg from "./assets/carbrands/logo/citroen.svg"
import daciaImg from "./assets/carbrands/logo/dacia.svg"
import fiatImg from "./assets/carbrands/logo/fiat.svg"
import fordImg from "./assets/carbrands/logo/ford.svg"
import hondaImg from "./assets/carbrands/logo/honda.svg"
import hyundaiImg from "./assets/carbrands/logo/hyundai.svg"
import jaguarImg from "./assets/carbrands/logo/jaguar.svg"
import jeepImg from "./assets/carbrands/logo/jeep.svg"
import kiaImg from "./assets/carbrands/logo/kia.svg"
import landRoverImg from "./assets/carbrands/logo/land_rover.svg"
import mazdaImg from "./assets/carbrands/logo/mazda.svg"
import miniImg from "./assets/carbrands/logo/mini.svg"
import mitsubishiImg from "./assets/carbrands/logo/mitsubishi.svg"
import nissanImg from "./assets/carbrands/logo/nissan.svg"
import opelImg from "./assets/carbrands/logo/opel.svg"
import peugeotImg from "./assets/carbrands/logo/peugeot.svg"
import renaultImg from "./assets/carbrands/logo/renault.svg"
import subaruImg from "./assets/carbrands/logo/subaru.svg"
import suzukiImg from "./assets/carbrands/logo/suzuki.svg"
import toyotaImg from "./assets/carbrands/logo/toyota.svg"
import volvoImg from "./assets/carbrands/logo/volvo.svg"

const brands = [
  { label: "Alpha Romeo", value: "alfa_romeo", img: alphaRomeoImg,
    disallowReg: true },
  { label: "Audi", value: "audi", img: audiImg },
  { label: "BMW", value: "bmw", img: bmwImg },
  { label: "Citroen", value: "citroen", img: citroenImg, disallowReg: true },
  { label: "Dacia", value: "dacia", img: daciaImg, disallowReg: true },
  { label: "Fiat", value: "fiat", img: fiatImg },
  { label: "Ford", value: "ford", img: fordImg, disallowReg: true },
  { label: "Honda", value: "honda", img: hondaImg, disallowReg: true },
  { label: "Hyundai", value: "hyundai", img: hyundaiImg, disallowReg: true },
  { label: "Jaguar", value: "jaguar", img: jaguarImg, disallowReg: true },
  { label: "Jeep", value: "jeep", img: jeepImg, disallowReg: true },
  { label: "Kia", value: "kia", img: kiaImg, disallowReg: true },
  { label: "Land Rover", value: "land_rover", img: landRoverImg,
    disallowReg: true },
  { label: "Mazda", value: "mazda", img: mazdaImg, disallowReg: true },
  { label: "Mercedes", value: "mercedes", img: mercedesImg },
  { label: "Mini", value: "mini", img: miniImg },
  { label: "Mitsubishi", value: "mitsubishi", img: mitsubishiImg,
    disallowReg: true },
  { label: "Nissan", value: "nissan", img: nissanImg, disallowReg: true },
  { label: "Opel", value: "opel", img: opelImg, disallowReg: true },
  { label: "Peugeot", value: "peugeot", img: peugeotImg, disallowReg: true },
  { label: "Porsche", value: "porsche", img: porscheImg },
  { label: "Renault", value: "renault", img: renaultImg, disallowReg: true },
  { label: "Seat", value: "seat", img: seatImg },
  { label: "Skoda", value: "skoda", img: skodaImg },
  { label: "Subaru", value: "subaru", img: subaruImg, disallowReg: true },
  { label: "Suzuki", value: "suzuki", img: suzukiImg, disallowReg: true },
  { label: "Toyota", value: "toyota", img: toyotaImg, disallowReg: true },
  { label: "Volkswagen", value: "volkswagon", img: vwImg },
  { label: "Volvo", value: "volvo", img: volvoImg, disallowReg: true },
]

export default brands
