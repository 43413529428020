import React from "react"
import styled from "styled-components"

import { COLOURS } from "../../lib/src/constants"
import Container from "../../components/Container"
import { H2, Subline } from "../../lib/src/components/Typography"
import routes from "../../routes"
import BrandModelYearPage from "./BrandModelYearPage"
import CalculatingAwardPage from "./CalculatingAwardPage"
import CurrentMileagePage from "./CurrentMileagePage"
import HasCarBeenSoldPage from "./HasCarBeenSoldPage"
import InsuranceCheckPage from "./InsuranceCheckPage"
import PurchaseMileagePage from "./PurchaseMileagePage"
import PurchasePricePage from "./PurchasePricePage"
import PurchaseYearPage from "./PurchaseYearPage"
import SignUpPage from "./SignUpPage"
import KapHasInsurancePage from "./KapHasInsurancePage"
import KapInsuranceSelectPage from "./KapInsuranceSelectPage"
import CommonHeader from "./CommonHeader"
import ValidClaimHeader from "./ValidClaimHeader"

const { NORMAL_BLUE } = COLOURS

const Heading = styled(H2)`
  text-align: center;
`
const Subtitle = styled(Subline)`
  text-align: center;
  font-weight: bold;
  color: ${NORMAL_BLUE};
`

export const registrationRoutes = [
  {
    route: routes.REGISTRATION_BRAND_MODEL_YEAR,
    component: BrandModelYearPage,
    Header: () => (
      <Container>
        <Heading>Fahrzeug betroffen?</Heading>
        <Subtitle>Wie viel Geld steht Ihnen zu?</Subtitle>
      </Container>
    ),
  },
  {
    route: routes.REGISTRATION_PURCHASE_YEAR,
    component: PurchaseYearPage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_PURCHASE_PRICE,
    component: PurchasePricePage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_PURCHASE_MILEAGE,
    component: PurchaseMileagePage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_HAS_CAR_BEEN_SOLD,
    component: HasCarBeenSoldPage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_CURRENT_MILEAGE,
    component: CurrentMileagePage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_INSURANCE_CHECK,
    component: InsuranceCheckPage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_KAP_INSURANCE_SELECT,
    component: CalculatingAwardPage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_CALCULATING_AWARD,
    component: CalculatingAwardPage,
    Header: CommonHeader,
  },
  {
    route: routes.REGISTRATION_SIGN_UP,
    component: SignUpPage,
    Header: ValidClaimHeader,
  },
]

export const kapHasInsuranceRoute = {
  route: routes.REGISTRATION_KAP_HAS_INSURANCE,
  component: KapHasInsurancePage,
  Header: CommonHeader,
}
export const kapInsuranceSelectRoute = {
  route: routes.REGISTRATION_KAP_INSURANCE_SELECT,
  component: KapInsuranceSelectPage,
  Header: CommonHeader,
}
