import React, { useContext, useState } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { get, range, toNumber, toLower,
  isNumber, find, first, last } from "lodash"
import Container, { FlexContainer } from "../../../components/Container"
import PageContentText from "../PageContentText"
import FormSection from "../../../components/FormSection"
import SelectField from "../../../components/SelectField"
import Calendar from "../../../components/icons/Calendar"
import { ClaimContext, FeatureFlagContext } from "../../../contexts"
import { getModelsByBrand } from "../../../constants/models"
import NoOptionListedButton from "../NoOptionListedButton"
import routes from "../../../routes"

const models = getModelsByBrand()


export default function PurchaseYearPage (
  {
    nextPage,
    openNotEligibleModal,
  }: any,
) {
  const flags = useContext(FeatureFlagContext)
  const claim = useContext(ClaimContext) as Claim
  const history = useHistory()
  const [carPurchaseYear, setCarPurchaseYear] =
    useState(null)
  const carBrand = get(claim, "carBrand")
  const carModel = get(claim, "carModel")
  const carYear = get(claim, "carYear")

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  const modelEntry = find(models[carBrand], (datum: any) => {
    return toNumber(datum.year) === toNumber(carYear) &&
      toLower(datum.model) === toLower(carModel)
  })

  const purchaseStartYear = get(modelEntry, "purchaseStart")
  let purchaseEndYear = get(modelEntry, "purchaseEnd")

  if (purchaseStartYear === null) {
    // skip this question when data is explicitly null=
    // return <Redirect to={nextPage} />
  }

  if (!get(flags, "show_purchase_year_in_funnel.value")) {
    // return <Redirect to={nextPage} />
  }

  let options = []
  if (isNumber(purchaseStartYear)) {
    // if we get a start number make a range of years
    purchaseEndYear = purchaseEndYear || new Date().getFullYear()
    options = range(purchaseStartYear, purchaseEndYear + 1)
      .map((y: any) => ({
        label: `${y}`,
        value: y,
      }))
  }
  else {
    // create a range based on the current date
    if (carYear) {
      options = range(toNumber(carYear), new Date().getFullYear() + 1, 1)
        .map((y: any) => ({
          label: `${y}`,
          value: y,
        }))
    }
    else {
      options = range(2003, new Date().getFullYear() + 1, 1)
        .map((y: any) => ({
          label: `${y}`,
          value: y,
        }))
    }
  }

  const showNoOptionListedButton = !(
    toNumber(carYear) === first(options)?.value &&
    new Date().getFullYear() === last(options)?.value
  )

  return (
    <FlexContainer direction='ttb' center>
      {/* @ts-expect-error */}
      <Container p={0.5}>
        <Calendar width={58} />
      </Container>
      <PageContentText>
        In welchem Jahr haben Sie das Fahrzeug gekauft?
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        <SelectField
          label='Jahr des Fahrzeugkaufs'
          value={carPurchaseYear}
          onChange={async (e: any) => {
            if (e.target.value < 2014) {
              openNotEligibleModal({message: "secondary"})
              return
            }

            setCarPurchaseYear(e.target.value)
            claim.update({ carPurchaseYear: e.target.value })
            history.push(routes.REGISTRATION_PURCHASE_PRICE)
          }}
          options={options}
        />
        {showNoOptionListedButton && (
          <NoOptionListedButton onClick={() => {
            openNotEligibleModal()
          }}
          >Ich habe das Fahrzeug in einem anderen Jahr gekauft.
          </NoOptionListedButton>
        )}

      </FormSection>
    </FlexContainer>
  )
}
