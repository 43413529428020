import React from "react"
import styled from "styled-components"
import Container, { FlexContainer } from "./Container"
import { INSURANCE_OPTIONS } from "../lib/src/constants"
import { H4, Body } from "../lib/src/components/Typography"
import LightButton from "./Button/LightButton"
import { get, find } from "lodash"
import InfoBox from "./InfoBox"


const StyledContainer = styled(Container)`
  border-bottom: 1px solid #e2e9fb;
  ${LightButton} {
    margin-top: 20px;
  }

  & + & {
    margin-top: 20px;
  }

  ${H4} {
    margin-bottom: 10px;
  }
`


export function Section (props: any) {
  return <StyledContainer p={0} py={1} {...props} />
}


const OPTS = {
  YES: "yes",
  NO: "no",
  DONTKNOW: "dont-know",
}

const valuesMap = {
  [OPTS.YES]: "Ja",
  [OPTS.NO]: "Nein",
  [OPTS.DONTKNOW]: "Weiß nicht",
  // FIXME: A computed property name must be of type 'string'
  // @ts-expect-error ts-migrate(2464)
  [true]: "Ja",
  // FIXME: A computed property name must be of type 'string'
  // @ts-expect-error ts-migrate(2464)
  [false]: "Nein",
}

const formatters = {
  km: (v: any) => `${Number(v).toLocaleString("de-DE")} km`,
  eur: (v: any) => `${Number(v).toLocaleString("de-DE")} €`,
}


function QuestionAnswer (
  {
    question,
    answer,
    ...props
  }: any,
) {
  return (
    <FlexContainer p={0} {...props}>
      <Body>
        {question}
      </Body>
      &nbsp;
      &nbsp;
      {/* @ts-expect-error */}
      <Body.Bold style={{ color: "#2460d8" }}>{answer}</Body.Bold>
    </FlexContainer>
  )
}


export function VerifyQuestionBlock (
  {
    questionText,
    format,
    answerText,
  }: any,
) {
  const hasMap = Boolean(valuesMap[answerText])
  // FIXME: Element implicitly has an 'any'
  // @ts-expect-error ts-migrate(7053)
  const formatter = formatters[format]
  const answer = hasMap
    ? valuesMap[answerText]
    : formatter
      ? formatter(answerText)
      : answerText

  return (
    // @ts-expect-error
    <Container px={0} py={0.75} style={{ borderBottom: "1px solid #e2e9fb" }}>
      <QuestionAnswer question={questionText} answer={answer} />
    </Container>

  )
}


export function Part1 (
  {
    claim,
  }: any,
) {
  const {
    hasCarBeenSold,
    carSalePrice,
    currentMileage,
    mileageAtSale,
    // wouldHavePurchasedVehicle,
    // acceptedTermsAndConditions,
    // grantedPowerOfAttorney
  } = claim
  const wasCarSold = hasCarBeenSold === OPTS.YES
  return (
    <>
      <VerifyQuestionBlock
        questionText='Fahrzeug bereits weiterverkauft?'
        answerText={hasCarBeenSold}
      />
      {wasCarSold &&
        <VerifyQuestionBlock
          questionText='Ungefährer Verkaufspreis:'
          format='eur'
          answerText={carSalePrice}
        />}
      {wasCarSold &&
        <VerifyQuestionBlock
          questionText='ungefährer Kilometerstand bei Verkauf:'
          format='km'
          answerText={mileageAtSale}
        />}
      {!wasCarSold &&
        <VerifyQuestionBlock
          questionText='ungefährer Kilometerstand aktuell:'
          format='km'
          answerText={currentMileage}
        />}
      {/* <VerifyQuestionBlock
        questionText={
          'Hätte ich gewusst, dass das Fahrzeug vom Abgasskandal betroffen ist
          (und damit die Straßenzulassung des Fahrzeugs gefährdet ist),
          hätte ich das Fahrzeug nicht gekauft?'
        }
        answerText={wouldHavePurchasedVehicle}
      />
      <VerifyQuestionBlock
        questionText='Teilnahmebedingungen aktzeptiert?'
        answerText={acceptedTermsAndConditions}
      />
      <VerifyQuestionBlock
        questionText='Vollmacht erteilt?'
        answerText={grantedPowerOfAttorney}
      /> */}
    </>
  )
}


export function Part2 (
  {
    claim,
  }: any,
) {
  const {
    hasCarBeenSold,
    legalExpensesInsurance,
    legalExpensesInsuranceAtPurchase,
    insuranceProvider,
    legalExpensesInsurancePolicyAsCompany,
    legalExpensesInsurancePolicyholderCompanyName,
    legalExpensesInsurancePolicyholderFirstName,
    legalExpensesInsurancePolicyholderLastName,
    legalExpensesInsurancePolicyNumber,
    isFinancingPaidOff,
    hasCarBeenFinanced,
    returnCarInCaseOfSuccess,
  } = claim
  const insuranceProviderString = get(
    find(INSURANCE_OPTIONS, (e: any) => e.value === insuranceProvider),
    "label",
    "",
  )
  return (
    <>
      {/* legalExpensesInsurance */}
      <VerifyQuestionBlock
        questionText='Rechtsschutzversicherung vorhanden?'
        answerText={legalExpensesInsurance}
      />
      {legalExpensesInsurance === "yes" && (
        <InfoBox>
          Im Falle einer Deckungszusage
          übernimmt Ihre Rechtsschutzversicherung sämtliche Prozesskosten
          und Claimback die Kosten einer etwaigen Selbstbeteiligung.
          Andernfalls werden sämtliche zur Durchsetzung Ihrer Ansprüche
          anfallenden Kosten sowie auch das Kostenrisiko
          vom Prozesskostenfinanzierer übernommen.
        </InfoBox>
      )}
      {legalExpensesInsurance === "no" && (
        <InfoBox>
          Kein Problem, nach erfolgreicher Prüfung Ihres Falls
          werden sämtliche zur Durchsetzung Ihrer Ansprüche anfallenden Kosten
          sowie auch das Kostenrisiko vom Prozesskostenfinanzierer übernommen.
        </InfoBox>
      )}
      {legalExpensesInsurance === "dont-know" && (
        <InfoBox>
          Kein Problem, bitte teilen Sie uns - falls vorhanden -
          die Daten Ihrer Rechtsschutzversicherung,
          binnen 7 Tagen nach Übermittlung des Formulars, mit.
          Andernfalls werden sämtliche zur Durchsetzung Ihrer Ansprüche
          anfallenden Kosten sowie auch das Kostenrisiko
          vom Prozesskostenfinanzierer übernommen.
        </InfoBox>
      )}
      {/* legalExpensesInsuranceAtPurchase */}
      {legalExpensesInsurance === "yes" && (
        <VerifyQuestionBlock
          questionText={
            "Rechtsschutzversicherung bereits " +
            "bei Anschaffung des Fahrzeugs vorhanden?"
          }
          answerText={legalExpensesInsuranceAtPurchase}
        />
      )}
      {/* insuranceProvider */}
      {legalExpensesInsurance === "yes" &&
        legalExpensesInsuranceAtPurchase === "yes" && (
        <VerifyQuestionBlock
          questionText='Versichert über/bei:'
          answerText={insuranceProviderString}
        />
      )}

      {legalExpensesInsurance === "yes" &&
        legalExpensesInsuranceAtPurchase === "yes" && (
        // @ts-expect-error
        <Container p={0} py={0.5} style={{ borderBottom: "1px solid #e2e9fb" }}>
          {/* legalExpensesInsurancePolicyAsCompany */}
          <QuestionAnswer
            mb={0.25}
            question='Versicherungsnehmer:'
            answer={
              legalExpensesInsurancePolicyAsCompany === "yes"
                ? "Firma"
                : "Privat"
            }
          />

          {/* legalExpensesInsurancePolicyholderCompanyName */}
          {legalExpensesInsurancePolicyAsCompany === "yes" && (
            <QuestionAnswer
              mb={0.25}
              question='Firmenname'
              answer={legalExpensesInsurancePolicyholderCompanyName}
            />
          )}
          <QuestionAnswer
            mb={0.25}
            question='Vorname'
            answer={legalExpensesInsurancePolicyholderFirstName}
          />
          <QuestionAnswer
            mb={0.25}
            question='Nachname'
            answer={legalExpensesInsurancePolicyholderLastName}
          />
          <QuestionAnswer
            mb={0.25}
            question='Versicherungsnummer'
            answer={legalExpensesInsurancePolicyNumber}
          />
        </Container>
      )}
      {/* hasCarBeenFinanced */}
      {hasCarBeenFinanced && (
        <VerifyQuestionBlock
          questionText='Wurde das Auto finanziert?'
          answerText={hasCarBeenFinanced}
        />
      )}
      {/* isFinancingPaidOff */}
      {hasCarBeenFinanced === "yes" && (
        <VerifyQuestionBlock
          questionText='Finanzierung bereits abgeschlossen?'
          answerText={isFinancingPaidOff}
        />
      )}
      {/* returnCarInCaseOfSuccess */}
      {hasCarBeenSold === "no" && (
        <>
          <VerifyQuestionBlock
            questionText='Wollen Sie im Erfolgsfall Ihr Auto zurückgeben?'
            answerText={returnCarInCaseOfSuccess}
          />
          {returnCarInCaseOfSuccess === "dont-know" && (
            <InfoBox>
              Kein Problem.
              In diesem Fall wählt die Rechtsanwaltskanzlei
              das für Sie wirtschaftlich günstigste Anspruchsziel aus
              und setzt dieses für Sie durch.
            </InfoBox>
          )}
        </>
      )}
    </>
  )
}


export function Part3 (
  {
    claim,
  }: any,
) {
  const {
    carModel,
    carPurchasePrice,
    carYear,
    vinNumber,
    mileageAtPurchase,
    // carPurchaseEntity,
    companyName,
    firstName,
    lastName,
    purchaseContractCompanyName,
    purchaseContractFirstName,
    purchaseContractLastName,
    address,
    postcode,
    city,
  } = claim

  return (
    <>
      {/* carModel */}
      <VerifyQuestionBlock
        questionText='Fahrzeugmodell:'
        answerText={carModel}
      />
      {/* carPurchasePrice */}
      <VerifyQuestionBlock
        questionText='Kaufpreis:'
        format='eur'
        answerText={carPurchasePrice}
      />
      {/* carYear */}
      <VerifyQuestionBlock
        questionText='Baujahr:'
        answerText={carYear}
      />
      {/* vinNumber */}
      <VerifyQuestionBlock
        questionText='FIN Nummer:'
        answerText={vinNumber}
      />
      {/* mileageAtPurchase */}
      <VerifyQuestionBlock
        questionText='Kilometerstand bei Kauf:'
        format='km'
        answerText={mileageAtPurchase}
      />
      {/* @ts-expect-error */}
      <Container p={0} py={0.5} style={{ borderBottom: "1px solid #e2e9fb" }}>
        {/* carPurchaseEntity */}
        {/* <QuestionAnswer
              mb={0.25}
              question='Käufer:'
              answer={carPurchaseEntity === 'privat' ? 'Privat' : 'Firma'}
            />
        */}
        {/* name */}
        {(
          purchaseContractCompanyName ||
          purchaseContractFirstName ||
          purchaseContractLastName
        )
          ? (
            <>
              {purchaseContractCompanyName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Firmenname'
                  answer={purchaseContractCompanyName}
                />
              }
              {purchaseContractFirstName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Vorname:'
                  answer={purchaseContractFirstName}
                />
              }
              {purchaseContractLastName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Nachname:'
                  answer={purchaseContractLastName}
                />
              }
            </>
          )
          : (
            <>
              {companyName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Firmenname'
                  answer={companyName}
                />
              }
              {firstName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Vorname:'
                  answer={firstName}
                />
              }
              {lastName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Nachname:'
                  answer={lastName}
                />
              }
            </>
          )}
        {/* address */}
        <QuestionAnswer mb={0.25} question='Adresse:' answer={address} />
        {/* postcode */}
        <QuestionAnswer mb={0.25} question='Postleitzahl:' answer={postcode} />
        {/* city */}
        <QuestionAnswer mb={0.25} question='Stadt:' answer={city} />
      </Container>
    </>
  )
}
