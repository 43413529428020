import React, { useContext, useState } from "react"
import { get, takeRightWhile } from "lodash"
import { useHistory, Redirect } from "react-router-dom"

import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import SelectField from "../../../components/SelectField"
import { ClaimContext, FeatureFlagContext } from "../../../contexts"
import PageContentText from "../PageContentText"
import LastMileage from "../../../components/icons/LastMileage"

import routes from "../../../routes"


function getOptions (claim: any) {
  const options =
    [
      { value: 0, label: "0 km – 10\u202F000 km" },
      { value: 10000, label: "10\u202F000 km – 20\u202F000 km" },
      { value: 20000, label: "20\u202F000 km – 30\u202F000 km" },
      { value: 30000, label: "30\u202F000 km – 40\u202F000 km" },
      { value: 40000, label: "40\u202F000 km – 50\u202F000 km" },
      { value: 50000, label: "50\u202F000 km – 60\u202F000 km" },
      { value: 60000, label: "60\u202F000 km – 70\u202F000 km" },
      { value: 70000, label: "70\u202F000 km – 80\u202F000 km" },
      { value: 80000, label: "80\u202F000 km – 90\u202F000 km" },
      { value: 90000, label: "90\u202F000 km – 100\u202F000 km" },
      { value: 100000, label: "100\u202F000 km – 110\u202F000 km" },
      { value: 110000, label: "110\u202F000 km – 120\u202F000 km" },
      { value: 120000, label: "120\u202F000 km – 130\u202F000 km" },
      { value: 130000, label: "130\u202F000 km – 140\u202F000 km" },
      { value: 140000, label: "140\u202F000 km – 150\u202F000 km" },
      { value: 150000, label: "150\u202F000 km – 160\u202F000 km" },
      { value: 160000, label: "160\u202F000 km – 170\u202F000 km" },
      { value: 170000, label: "170\u202F000 km – 180\u202F000 km" },
      { value: 180000, label: "180\u202F000 km – 190\u202F000 km" },
      { value: 190000, label: "190\u202F000 km – 200\u202F000 km" },
      { value: 200000, label: "200\u202F000 km – 210\u202F000 km" },
      { value: 210000, label: "210\u202F000 km – 220\u202F000 km" },
      { value: 220000, label: "220\u202F000 km – 230\u202F000 km" },
      { value: 230000, label: "230\u202F000 km – 240\u202F000 km" },
      { value: 240000, label: "240\u202F000 km – 250\u202F000 km" },
      { value: 250000, label: "250\u202F000 km – 260\u202F000 km" },
      { value: 260000, label: "260\u202F000 km – 270\u202F000 km" },
      { value: 270000, label: "270\u202F000 km – 280\u202F000 km" },
      { value: 280000, label: "280\u202F000 km – 290\u202F000 km" },
      { value: 290000, label: "290\u202F000 km – 300\u202F000 km" },
    ]
  if (claim.mileageAtPurchase > 0) {
    return takeRightWhile(
      options,
      (option: any) => claim.mileageAtPurchase <= option.value,
    )
  }
  return options
}


export default function CurrentMileagePage (
  {
    nextPage,
    openNotEligibleModal,
  }: any,
) {
  const claim = useContext(ClaimContext) as Claim
  const flags = useContext(FeatureFlagContext)
  const history = useHistory()

  const hasCarBeenSold = get(claim, "hasCarBeenSold", "no")
  const currentMileage = get(claim, "currentMileage", null)
  const mileageAtSale = get(claim, "mileageAtSale", null)

  const initalValue = hasCarBeenSold ? mileageAtSale : currentMileage
  const [lastKnownMileage, setCurrentMileage] = useState(initalValue)

  if (!get(flags, "show_current_mileage_page_in_funnel.value")) {
    // return <Redirect to={nextPage} />
  }

  return (
    <FlexContainer direction='ttb' center>
      <LastMileage width={83} height={51} />

      <PageContentText>
        {hasCarBeenSold === "yes"
          ? "Wie hoch war der Kilometerstand " +
            "zum Zeitpunkt des Verkaufs ungefähr?"
          : "Wie hoch ist der aktuelle Kilometerstand ungefähr?"}
      </PageContentText>

      <FormSection
        style={{ width: "100%" }}
      >
        <SelectField
          label={hasCarBeenSold === "yes"
            ? "km-Stand bei Verkauf"
            : "aktueller km-Stand"}
          value={lastKnownMileage}
          onChange={(event: any) => {
            const value = event.target.value

            if (value >= 150000) {
              openNotEligibleModal({ message: "secondary" })
              return
            }

            setCurrentMileage(value)

            if (hasCarBeenSold === "yes") {
              claim.update({mileageAtSale: value})
            }
            else {
              claim.update({currentMileage: value})
            }

            history.push(routes.REGISTRATION_INSURANCE_CHECK)
          }}
          options={getOptions(claim)}
        />
      </FormSection>
    </FlexContainer>
  )
}
