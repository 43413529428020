import { Flex } from "../../../lib"
import { isNumber } from "lodash"
import React, { useContext } from "react"
import CountUp from "react-countup"
import { useHistory, Redirect } from "react-router-dom"
import ConfettiIcon from "../../../components/icons/Confetti"
import { NORMAL_BLUE } from "../../../colours"
import { FlexContainer } from "../../../components/Container"
import Loader from "../../../components/Loader/Loader"
import { ClaimContext } from "../../../contexts"
import PageContentText from "../PageContentText"
import { Body, Jumbo } from "../../../lib/src/components/Typography"
import { calculateAllRewards } from "../../../lib/src/helpers/calculateAward"


function CountUpAward (
  {
    award,
    handleOnCountComplete,
  }: any,
) {
  return (
    <FlexContainer center direction='ttb'>
      {/* @ts-expect-error */}
      <Body.Bold style={{ textAlign: "center" }}>Anspruch auf bis zu</Body.Bold>
      <Flex>
        <Jumbo style={{ color: NORMAL_BLUE }}>
          {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
          <Flex direction='ltr'>
            <CountUp
              separator='.'
              end={award}
              suffix=' €'
              onEnd={handleOnCountComplete}
            />
          </Flex>
        </Jumbo>
        {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
        <Flex alignItems='center' justify='start'>
          <ConfettiIcon style={{ marginBottom: 15, marginLeft: 15 }} />
        </Flex>
      </Flex>
    </FlexContainer>
  )
}


export default function CalculatingAwardPage (
  {
    nextPage,
  }: any,
) {
  const history = useHistory()
  const claim = useContext(ClaimContext) as Claim

  const rewards = calculateAllRewards(claim)

  if (claim.update) {
    claim.update({ ...rewards })
  }

  if (Number.isNaN(rewards.maxReward) || !isNumber(rewards.maxReward)) {
    return <Redirect to={nextPage} push />
  }

  if (rewards.maxReward < 200) {
    return <Redirect to={nextPage} push />
  }

  return (
    <FlexContainer direction='ttb' center>
      <PageContentText>
        Unser System berechnet die Höhe Ihrer Entschädigung
        basierend auf Ihren Angaben.
      </PageContentText>

      <FlexContainer center>
        <Loader />
      </FlexContainer>

      <CountUpAward
        award={rewards.maxReward}
        handleOnCountComplete={(() => {
          setTimeout(() => {
            history.push(nextPage)
          }, 400)
        })}
      />

    </FlexContainer>
  )
}
