import React from "react"
import PropTypes from "prop-types"
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core"
import styled from "styled-components"


function Select (
  {
    label,
    options,
    onChange,
    value,
    ...props
  }: any,
) {
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    // @ts-expect-error  FIXME: Object is possibly 'null'
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  if (!label) {
    console.warn(`"label" must be set for <Select> with value "${value}"`)
  }

  return (
    <FormControl fullWidth>
      <InputLabel
        ref={inputLabel}
        htmlFor={`outlined-${label}-select`}
        variant='outlined'
      >{label}</InputLabel>
      <MuiSelect
        variant='outlined'
        fullWidth
        value={value || ""}
        onChange={onChange}
        {...props}
        input={
          <OutlinedInput
            name={label}
            labelWidth={labelWidth}
            id={`outlined-${label}-select`}
          />
        }
      >
        {options && options.map(
          (m: any, i: any) =>
            <MenuItem key={i} value={m.value}>{m.label}</MenuItem>,
        )}
      </MuiSelect>
    </FormControl>
  )
}


Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
  })).isRequired,
}


const SelectField = styled(Select)`
  &&& {
    background-color: #fff;
  }
`


export default SelectField
