import React from "react"
import { withRouter } from "react-router-dom"
import { FlexContainer } from "./Container"
import { H4, Body, Jumbo } from "../lib/src/components//Typography"
import { Part1, Part2, Part3, Section } from "./ApplicationStatus"
import CarSuccessIcon from "./CarSuccessIcon"
import Show from "./Show"
import { get } from "lodash"


function ApplicationSuccess (
  {
    claim,
    claimId,
  }: any,
) {
  return (
    <>
      <Section pt={0}>
        {/* @ts-expect-error */}
        <Body.Bold>
          Ihre Claim-ID:{" "}
          <span style={{ color: "#2460d8" }}>
            {claimId || get(claim, "id", "")}
          </span>
        </Body.Bold>
        {/* @ts-expect-error ts-migrate(2322) Remove comment to see error */}
        <Show below='md'>
          <Jumbo>
            Geschafft!
          </Jumbo>
          <Body>
            Hier sehen Sie alle Daten übersichtlich aufgelistet;
            Sie sind allerdings nur noch telefonisch oder per E-Mail änderbar.
          </Body>
          <div>
            <div style={{ width: 180, margin: "20px auto 0" }}>
              <CarSuccessIcon />
            </div>
          </div>
        </Show>
        {/* @ts-expect-error ts-migrate(2322) Remove comment to see error */}
        <Show from='md'>
          <FlexContainer p={0}>
            <div>
              <Jumbo>
                Geschafft!
              </Jumbo>
              <Body>
                Hier sehen Sie alle Daten übersichtlich aufgelistet;
                Sie sind allerdings nur noch telefonisch
                oder per E-Mail änderbar.
              </Body>
            </div>
            <div style={{ marginLeft: 20, minWidth: 238 }}>
              <CarSuccessIcon />
            </div>
          </FlexContainer>
        </Show>
      </Section>

      <Section>
        <H4>
          Teil 1:
        </H4>
        <Part1 claim={claim} />
      </Section>
      <Section>
        <H4>
          Teil 2:
        </H4>
        <Part2 claim={claim} />
      </Section>
      <Section style={{ borderBottom: "none" }}>
        <H4>
          Teil 3:
        </H4>
        <Part3 claim={claim} />
      </Section>
      <Section>
        {/* @ts-expect-error */}
        <Body.Small>
          Unsere Partnerkanzlei wird Sie über diese Mailadresse
          auf dem aktuellen Stand halten: {claim.userEmail}
        </Body.Small>
      </Section>
    </>
  )
}


export default withRouter(ApplicationSuccess)
