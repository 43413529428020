import React from "react"
import { oneLine } from "common-tags"


export default function Money (props: any) {
  return (
    <svg width={58} height={64} {...props}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#161466'
          fillRule='nonzero'
          d={oneLine`
            M37.208 50.969c3.712.017 6.782-2.873 6.97-6.563v-.095l6.76-7.558
            a4.102 4.102 0 015.618-.545 3.95 3.95 0 01.464 5.657l-13.497 15.4
            a9.488 9.488 0 01-7.142 3.228H16.093L12.569 64 0 51.804l9.004-8.885
            a13.777 13.777 0 0114.218-3.204l1.976.693h12.17
            c1.038 0 2.032.417 2.754 1.159
            a3.805 3.805 0 011.08 2.774 3.932 3.932 0 01-3.994 3.666
            h-7.302c-.822 0-1.488.663-1.488 1.481 0 .818.666 1.481 1.488 1.481
            h7.302z
          `}
        />
        <path
          fill='#2460D8'
          d={oneLine`
            M29.84 16.632a21.3 21.3 0 003.065 1.422c.453.178.905.361 1.351.557
            a6.273 6.273 0 011.482.888 1.703 1.703 0 01-.41 3.021 4.883 4.883
            0 01-2.56.332 11.945 11.945 0
            01-4.106-1.22 1.953 1.953 0 00-.887-.285
            c-.714 0-.934.717-1.06 1.102-.231.74-.44 1.487-.642 2.233-.333
            1.22-.125 1.676 1.024 2.233 1.262.57 2.601.954
            3.975 1.137.219.024.434.07.643.137.045.228.063.46.054.693
            v1.463a1.331 1.331 0 001.398 1.409h2.202c.371.02.733-.12.991-.387
            a1.27 1.27 0 00.354-.999v-2.02c0-.45.107-.592.595-.728a7.74 7.74
            0 004.018-2.565 7.048 7.048 0 001.511-5.828
            A7.561 7.561 0 0039 14.056
            a32.611 32.611 0 00-3.452-1.564l-.453-.184a7.996 7.996 0
            01-1.928-1.101 1.443 1.443 0 01-.595-1.297 1.49 1.49
            0 01.958-1.126c.356-.14.736-.215 1.119-.219a9.22 9.22 0 014.565.9
            c1.464.705 1.785-.355 1.904-.805.197-.593.37-1.185.542-1.807
            l.202-.71
            a1.32 1.32 0 00-.05-1.035 1.331 1.331 0 00-.783-.683 12.597 12.597
            0 00-3.16-.918
            c-.982-.154-.982-.154-.982-1.138 0-2.067-.16-2.369-2.19-2.369
            h-1.095c-1.19.036-1.512.385-1.542 1.546
            v1.238c0 .87 0 .87-.869 1.185-3.166 1.143-4.868 3.299-5.058
            6.403-.173 2.612 1.047 4.673 3.707 6.26z
          `}
        />
      </g>
    </svg>
  )
}
