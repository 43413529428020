import React from "react"
import styled from "styled-components"
import { Field, ErrorMessage } from "formik"
import Container from "../Container"
import { Body } from "../../lib/src/components/Typography"
import { Flex } from "../../lib"
import Tooltip from "../Tooltip"
import FieldError from "../FieldError"


const Wrapper = styled(Container)`
  border-top: ${(props: any) => props.noBorder
    ? "none"
    : "1px solid #e2e9fb"};
`


export default function FormSection (
  {
    children,
    heading,
    tooltip,
    ...props
  }: any,
) {
  return (
    <Wrapper pt={1.5} pb={0.5} px={0} mt={1} {...props}>
      {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
      <Flex alignItems='center'>
        {heading && (
          // @ts-expect-error
          <Container mb={1} p={0}>
            {/* @ts-expect-error ts-migrate(2322) Remove to see error */}
            <Flex alignItems='center' justify='center'>
              { typeof heading === "string"
                // @ts-expect-error
                ? <Body.Bold>{heading}</Body.Bold>
                : heading
              }
              {tooltip && <Tooltip message={tooltip} />}
            </Flex>
          </Container>)}
      </Flex>
      {children}
    </Wrapper>
  )
}


export function FormSectionWithError (
  {
    name,
    tooltip,
    label,
    component,
    ...props
  }: any,
) {
  return (
    <FormSection heading={label} tooltip={tooltip}>
      <Field
        label={label}
        name={name}
        component={component}
        {...props}
      />
      <ErrorMessage name={name} component={FieldError} />
    </FormSection>
  )
}
