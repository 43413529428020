import React from "react"
import FormPageLayout from "../../components/FormPageLayout/FormPageLayout"
import { Jumbo, Body } from "../../lib/src/components/Typography"
import Container, { FlexContainer } from "../../components/Container"
import ErrorIcon from "../../assets/error.svg"
import SubmitButton from "../../components/Button/SubmitButton"


export default function ErrorPage (
  {
    message,
  }: any,
) {
  return (
    <FormPageLayout>
      {/* @ts-expect-error */}
      <Container p={1} pb={0}>
        <Jumbo>Hoppla!</Jumbo>
        {/* @ts-expect-error */}
        <Body.Bold>
          Leider haben wir ein technisches Problem.
        </Body.Bold>
        <br />
        <Body>
          {message || `
            Wir arbeiten schnellstmöglich an der Fehlerbeseitigung.
            Bitte versuchen Sie es in wenigen Minuten erneut.
          `}

        </Body>
        <a href='/' style={{ textDecoration: "none" }}>
          <SubmitButton icon={null} style={{ marginTop: 14 }}>
            Seite neu laden
          </SubmitButton>
        </a>
        <FlexContainer center>
          <img src={ErrorIcon} alt='error page' />
        </FlexContainer>
      </Container>
    </FormPageLayout>
  )
}
