import { get } from "lodash"
import React, { useContext } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { FlexContainer } from "../../../components/Container"
import FormSection from "../../../components/FormSection"
import YesNoButtons from "../../../components/YesNoButtons"
import ClaimContext from "../../../contexts/claim.context"
import PageContentText from "../PageContentText"
import Car from "../../../components/icons/Car"
import { FeatureFlagContext } from "../../../contexts"
import routes from "../../../routes"


export default function HasCarBeenSoldPage (
  {
    nextPage,
  }: any,
) {
  const history = useHistory()
  const claim = useContext(ClaimContext) as Claim
  const flags = useContext(FeatureFlagContext)

  if (!get(flags, "show_has_car_been_sold_in_funnel.value")) {
    // return <Redirect to={nextPage} />
  }

  return (
    <FlexContainer direction='ttb' center>
      <Car />

      <PageContentText>
        Haben Sie das Fahrzeug bereits weiterverkauft?
      </PageContentText>

      <FormSection style={{ width: "100%" }}>
        <YesNoButtons
          value={claim.hasCarBeenSold || ""}
          onChange={async (value: any) => {
            await claim.update({ hasCarBeenSold: value })

            history.push(routes.REGISTRATION_CURRENT_MILEAGE)
          }}
        />
      </FormSection>
    </FlexContainer>
  )
}
