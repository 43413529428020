import styled from "styled-components"
import media from "../../utils/media"
import { Body } from "../../lib/src/components/Typography"

const FieldError = styled(Body.Small) `
  ${(media as any).from.xs `
    margin-top: 8px;
  `}
  ${(media as any).from.md `
    margin-top: 12px;
  `}
  color: #ff5b5b;
  font-weight: 500;
`
export default FieldError
