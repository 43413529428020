/* eslint-disable */

import React, { memo } from 'react'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Container from '../Container'
import { Body } from '../../lib/src/components/Typography'

const EmptyCheckbox = styled.div`
  height: 24px;
  width: 24px;
  border: solid 2px #161466;
  border-radius: 6px;
  box-sizing: border-box;
`

const CheckIcon = (props: any) => <div {...props}>
  <svg width='9px' height='8px' viewBox='0 0 9 8'>
    <g
      id='_Styles'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='cmbk-interaktionsfelder_msc'
        transform='translate(-168.000000, -289.000000)'
        fill='#FFFFFF'
        fillRule='nonzero'
      >
        <g id='Group-14-Copy-7' transform='translate(160.000000, 281.000000)'>
          <path
            d='M16.5475334,8.16638987 C16.1986344,7.89684981 15.6926205,7.9617799 15.4230804,8.31047857 L11.5002204,13.3872506 L9.43828903,10.6818299 C9.17095338,10.3311272 8.66554072,10.2629907 8.31483803,10.530126 L8.31483803,10.530126 C7.96413535,10.7972612 7.89599883,11.3028743 8.16313407,11.653577 L10.8354885,15.160203 C11.0168519,15.39828 11.3078349,15.5024888 11.5859923,15.4636109 C11.8040291,15.4463764 12.0144507,15.3439712 12.1577378,15.1583994 L16.6916221,9.29084287 C16.9611622,8.9419438 16.8962321,8.43592993 16.5475334,8.16638987 Z'
            id='Path'
          />
        </g>
      </g>
    </g>
  </svg>
</div>

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  display: block;
  top: 6px;
  left: 6px;
  z-index: 100;

  svg {
    display: block;
  }
`

const CheckedIcon = styled(EmptyCheckbox)`
  border-color: #2460d8;
  position: relative;
  align-items: center;
  justify-content: center;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 4px;
    height: 16px;
    width: 16px;
    background: #2460d8;
  }
`

const CheckedCheckbox = () => (
  <CheckedIcon>
    <StyledCheckIcon />
  </CheckedIcon>
)

const CustomCheckbox = ({
  // { name, value, onChange, onBlur }
  field,

  // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  form,

  label,
  ...props
}: any) => {
  // const { touched, errors } = form
  return (
    // @ts-expect-error
    <Container p={0} mb={0.5}>
      <FormControlLabel
        checked={field.value}
        control={
          <Checkbox
            color='primary'
            {...field}
            {...props}
            checkedIcon={<CheckedCheckbox />}
            icon={<EmptyCheckbox />}
          />
        }
        label={
          // @ts-expect-error
          <Body.Small>{label}</Body.Small>
        }
      />
    </Container>
  )
}

// id='salesPrice' */}
// name='salesPrice'
// {...field}
// value={value}
// {...props}
// onChange={(e, v) => {
//   form.setFieldValue(field.name, v)
// }}
// {{touched[field.name] && errors[field.name] && (
// <div className='error'>{errors[field.name]}</div>
// )}

export default memo(CustomCheckbox)
