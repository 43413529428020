import React from "react"
import { withStyles } from "@material-ui/core/styles"
import MuiTooltip from "@material-ui/core/Tooltip"
import { oneLine } from "common-tags"


const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 14,
  },
}))(MuiTooltip)


export default function Tooltip (
  {
    message,
  }: any,
) {
  return (
    <StyledTooltip
      title={message}
      enterTouchDelay={100}
      leaveTouchDelay={5000}
    >
      <svg
        style={{ marginLeft: 10, minWidth: 20, minHeight: 20 }}
        width='20'
        height='20'
        viewBox='0 0 20 20'
      >
        <path
          fill='#2460D8'
          fillRule='nonzero'
          d={oneLine`
            M9.997 0C4.475 0 0 4.48 0 10.003A9.996 9.996 0 0 0 9.997 20
            C15.52 20 20 15.525 20 10.003 20 4.48 15.52 0 9.997 0zm1.143 13.948
            c0 .45-.105.776-.31.97-.21.194-.487.288-.827.288-.341
            0-.619-.094-.828-.288-.215-.194-.32-.52-.32-.97V9.804
            c0-.456.105-.776.32-.97.21-.194.487-.293.828-.293.34 0
            .618.1.833.293.21.194.314.514.304.97v4.144zm-.3-7.508
            c-.198.188-.481.282-.843.282-.361 0-.644-.094-.843-.277
            a.894.894 0 0 1-.299-.681.91.91 0 0 1 .304-.687
            c.205-.189.482-.283.839-.283.356 0 .639.094.838.283
            a.892.892 0 0 1 .304.687c0 .262-.1.487-.3.676z
          `}
        />
      </svg>
    </StyledTooltip>
  )
}
