import React, { memo } from "react"
import { oneLine } from "common-tags"

export default memo(() => (
  <svg width='18px' height='11px' viewBox='0 0 18 11' version='1.1'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-591, -35)'
        fill='#FFFFFF'
        fillRule='nonzero'
      >
        <g transform='translate(591, 35)'>
          <path
            d={oneLine`
              M6.1902439,5.5 L0.043902439,10.4076923
              C0.0219512195,10.3230769 0,10.2384615 0,10.1538462
              L0,0.846153846
              C0,0.761538462 0.0219512195,0.676923077 0.043902439,0.592307692
              L6.1902439,5.5
              Z
              M17.9560976,0.592307692
              L11.8097561,5.5
              L17.9560976,10.4076923
              C17.9780488,10.3230769 18,10.2384615 18,10.1538462
              L18,0.846153846
              C18,0.761538462 17.9780488,0.676923077 17.9560976,0.592307692
              Z
              M9.28536585,7.50961538
              C9.19756098,7.57307692 9.1097561,7.61538462 9,7.61538462
              C8.8902439,7.61538462 8.80243902,7.57307692 8.71463415,7.50961538
              L6.87073171,6.05
              L0.680487805,11
              L17.297561,11
              L11.1292683,6.05
              L9.28536585,7.50961538
              Z
              M17.3195122,0
              L0.680487805,0
              L9,6.64230769
              L17.3195122,0
              Z
            `}
          />
        </g>
      </g>
    </g>
  </svg>
))
