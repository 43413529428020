// eslint-disable-next-line import/no-unresolved
import { css } from "styled-components"
const MULTIPLIER = 4

// Default Scale
const DEFAULT_SCALE = {
  xs: 4,
  sm: 4,
  md: 5,
  lg: 6,
  xl: 8,
}

const DEFAULT_MARGINS = {
  xs: DEFAULT_SCALE.xs * MULTIPLIER,
  sm: DEFAULT_SCALE.sm * MULTIPLIER,
  md: DEFAULT_SCALE.md * MULTIPLIER,
  lg: DEFAULT_SCALE.lg * MULTIPLIER,
  xl: DEFAULT_SCALE.xl * MULTIPLIER,
}


export function responsiveScale (scaleValue: any) {
  const scaled = { ...DEFAULT_MARGINS }
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(scaled)) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
    scaled[key] = scaleValue * value
  }
  return scaled
}


export function scaledCss (cssKey: any, breakpoint: any, scales: any) {
  return css`
    ${`${cssKey}: ${
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
    DEFAULT_MARGINS[breakpoint] * scales.scale}px;`}

    ${scales.yScale !== undefined && `
      ${cssKey}-top: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.yScale}px;
      ${cssKey}-bottom: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.yScale}px;
    `}

    ${scales.xScale !== undefined && `
      ${cssKey}-left: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.xScale}px;
      ${cssKey}-right: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.xScale}px;
    `}

    ${scales.lScale !== undefined && `
      ${cssKey}-left: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.lScale}px;
    `}

    ${scales.rScale !== undefined && `
      ${cssKey}-right: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.rScale}px;
    `}

    ${scales.tScale !== undefined && `
      ${cssKey}-top: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.tScale}px;
    `}

    ${scales.bScale !== undefined && `
      ${cssKey}-bottom: ${
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly is 'any'
  DEFAULT_MARGINS[breakpoint] * scales.bScale}px;
    `}
  `
}
